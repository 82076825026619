import { useState } from "react";
import Comentarios from "./Comentarios";
import IngredientesMarcados from "./IngredientesMarcados";

const ReportsPage = () => {
	const [itemMenuSelected, setItemMenuSelected] = useState("comentarios");
	return (
		<div>
			<header id="buscador" style={{ marginBottom: "40px" }}>
				<div className="container container-2">
					<h3>Reportes</h3>
				</div>
				<figure className="bg-textura bg-verde-rgba-2"></figure>
			</header>
			<div className="container container-2">
				<nav className="nav-herr">
					<a
						href="/#"
						className={itemMenuSelected === "comentarios" ? "activo" : ""}
						onClick={event => {
							event.preventDefault();
							setItemMenuSelected("comentarios");
						}}
					>
						Reseñas y comentarios
					</a>
					<a
						href="/#"
						className={itemMenuSelected === "ingredientes" ? "activo" : ""}
						onClick={event => {
							event.preventDefault();
							setItemMenuSelected("ingredientes");
						}}
					>
						Ingredientes marcados
					</a>
				</nav>
				<section className="sec-herr">
					{
						{
							comentarios: <Comentarios />,
							ingredientes: <IngredientesMarcados />
						}[itemMenuSelected]
					}
				</section>
			</div>
		</div>
	);
};

export default ReportsPage;
