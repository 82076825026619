import { useContext, useMemo } from 'react'

import FilterProviderContext from './FilterProviderContext'

export const useFilterProvider = () => {
	const filterProvider = useContext(FilterProviderContext)

	const filterProviderProxy = useMemo(() => {
		return filterProvider
	}, [filterProvider])

	return filterProviderProxy
}
