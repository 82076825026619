import { useEffect, useState } from 'react'
import { useDataProvider } from '../../providers/data'
import { useAuthProvider } from '../../providers/auth'
import { useServiceProvider } from '../../providers/service'
import { useTranslation } from "react-i18next";

function FolderForm() {
	const { t } = useTranslation();
	const dataProvider = useDataProvider()
	const serviceProvider = useServiceProvider()
	const authProvider = useAuthProvider()
	const folders = authProvider.getFolders()
	const userData = authProvider.getIdentity()

	const [data, setData] = useState({})
	const [isRegisterForm, setIsRegisterForm] = useState(true)
	const [isEditFolder, setIsEditFolder] = useState(true)

	useEffect(() => {
		setData(serviceProvider.getFolderFormLightBoxData())
	}, [serviceProvider])

	useEffect(() => {
		setIsEditFolder(data && data.idCarpeta)
		setIsRegisterForm(data && data.registerCode)
	}, [data])

	async function GetRecipes(idCarpeta, jwt) {
		try {
			const response = await dataProvider.getList(`api/carpetas/${idCarpeta}`, {
				headers: {
					Authorization: jwt,
					'Content-Type': 'application/json',
					'Accept': '*/*',
				}
			});
			return response.data;
		} catch (error) {
			return [];
		}
	}

	const handleForm = async (e) => {
		e.preventDefault()
		const form = e.target
		const formData = new FormData(form)

		try {
			const response = await dataProvider.create('api/carpetas', {
				data: {
					idCarpeta: isEditFolder ? data.idCarpeta : null,
					nombre: formData.get('name'),
					isPublic: data.isPublic
				},
				headers: {
					Authorization: userData.jwt,
				},
			})
			if (response.status === 200) {
				if (isEditFolder) {
					const updateFolder = folders.find(f => Number(f.idCarpeta) === Number(data.idCarpeta))
					updateFolder.nombre = response.data.nombre
					authProvider.updateFolders([...folders])
				} else {
					authProvider.updateFolders([...folders, response.data])
				}
			}
		} catch (e) {
			console.error(e)
		} finally {
			serviceProvider.folderFormLightBoxOff()
		}
	}

	const handleRegisterForm = async (e) => {
		e.preventDefault()
		const form = e.target
		const formData = new FormData(form)
		try {
			// Registrar carpeta compartida
			const response = await dataProvider.create(`api/carpetas/compartir?codigo=${encodeURIComponent(formData.get('code'))}`, {
				headers: {
					Authorization: userData.jwt,
					'Content-Type': 'application/json',
					Accept: 'application/json, text/plain, */*',
				}, data: {
				}
			})
			if (response.status === 200) {
				const folder = response.data
				const recipes = await GetRecipes(folder.idCarpeta, userData.jwt)
				const sharedFolder = {
					...folder,
					recetas: recipes.data
				}
				authProvider.updateFolders([...folders, sharedFolder])
			}
		} catch (e) {
			console.error(e)
		} finally {
			serviceProvider.folderFormLightBoxOff()
		}
	}

	return (
		<>
			{!isRegisterForm && (
				<form className="form-ichef" onClick={(e) => e.stopPropagation()} onSubmit={handleForm}>
					<h5 className="tit-carpeta">{isEditFolder ? t("edit_folder") : t("new_folder")}</h5>
					<span>{t("folder_name")}</span>
					<input name="name" placeholder={t("enter_name")} type="text" defaultValue={isEditFolder ? data.nombre.trim() : ''} />
					<section>
						<button>{t("save")}</button>
					</section>
				</form>
			)}
			{isRegisterForm && (
				<form className="form-ichef" onClick={(e) => e.stopPropagation()} onSubmit={handleRegisterForm}>
					<h5 className="tit-carpeta">{t("register_shared_folder")}</h5>
					<span>{t("enter_shared_code")}</span>
					<input name="code" type="text" placeholder={t("folder_code")}/>
					<section>
						<button>{t("register")}</button>
					</section>
				</form>
			)}
		</>
	)
}

export default FolderForm
