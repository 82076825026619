import {
	USER_LOGIN,
	USER_LOGOUT,
	UPDATE_FOLDERS,
	UPDATE_WATCH_LATER,
	UPDATE_FAVORITES,
	REMOVE_FAVORITES,
	UPDATE_LIKES,
	REMOVE_LIKES,
} from './authActionTypes'

const authActions = {
	userStorage: function (user) {
		return {
			type: USER_LOGIN,
			payload: user,
		}
	},

	userLogout: function () {
		return { type: USER_LOGOUT }
	},

	updateFolders: function (folders) {
		return {
			type: UPDATE_FOLDERS,
			payload: folders,
		}
	},

	updateWatchLater: function (watchLater) {
		return {
			type: UPDATE_WATCH_LATER,
			payload: watchLater,
		}
	},

	updateFavorites: function (favorites) {
		return {
			type: UPDATE_FAVORITES,
			payload: favorites,
		}
	},

	removeFavorites: function () {
		return {
			type: REMOVE_FAVORITES,
		}
	},

	updateLikes: function (likes) {
		return {
			type: UPDATE_LIKES,
			payload: likes,
		}
	},

	removeLikes: function () {
		return {
			type: REMOVE_LIKES,
		}
	},
}

export default authActions
