import { Link } from 'react-router-dom'
import { slugify } from '../providers/service/Utils'
import StarRating from './StarRating'

function RecipeItemMobile({ recipeData, recipeSelectorActive }) {
	const urlImagen = recipeData.fotosReceta && recipeData.fotosReceta.urlImagen ? recipeData.fotosReceta.urlImagen : null
	const recipeTitleSlug = slugify(recipeData.titulo)
	return (
		<Link to={`/receta/${recipeData.idReceta}/${recipeTitleSlug}`} className="cont-receta">
			<div className={`receta ch transition`}>
				{/*recipeSelectorActive && (
					<div className="select-recipe">
						<input
							id={`receta-${recipeData.idReceta}`}
							name="receta[]"
							type="checkbox"
							defaultValue={recipeData.idReceta}
						/>
						<label htmlFor={`receta-${recipeData.idReceta}`} className={`check`}></label>
					</div>
				)*/}


				<div className="footer">
					<StarRating score={recipeData.puntuacion} iconClass="icon-estrella" />

					<h2>{recipeData.titulo}</h2>
				</div>
				<div className="bg-degrade"></div>
				<figure className="ratio-100">
					{urlImagen && <img src={`${urlImagen}`} alt={`${recipeData.titulo}`} />}
				</figure>
			</div>
		</Link>
	)
}

export default RecipeItemMobile
