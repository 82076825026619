import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ToggleButton from "./ToggleButton";

function LanguageSelector() {
	const { i18n } = useTranslation();

	const langOptions = [
		{
			label: "Español",
			value: "es"
		},
		{
			label: "English",
			value: "en"
		}
	];

	const [selectedIndex, setSelectedIndex] = useState(0);
	useEffect(() => {
		setSelectedIndex(i18n.resolvedLanguage === "en" ? 1 : 0);
	}, [i18n.resolvedLanguage]);

	function onChangeLang(value) {
		i18n.changeLanguage(value);
		document.documentElement.lang = value;
	}

	return <ToggleButton options={langOptions} selected={selectedIndex} onSwitch={onChangeLang} />;
}

export default LanguageSelector;
