import { useFilterProvider } from '../providers/filter'
import { useTranslation } from "react-i18next";

const ClearFiltersButton = () => {
	const { t } = useTranslation();
	//Proveedor de filtros
	const filterProvider = useFilterProvider()

	const handleClick = () => {
		filterProvider.deleteAllFilters()
	}

	return (
		filterProvider.isThereFilterActivated() && (
			<button className="item-botonera clear-btn" onClick={handleClick}>
				{t("clear_filters")}
			</button>
		)
	)
}

export default ClearFiltersButton
