import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { BeatLoader } from 'react-spinners'
import { useAuthProvider } from '../../providers/auth'
import { defaultDataProvider } from '../../providers/data'

const api_path = process.env.REACT_APP_VERCEL_URL

const PaymentMethodSubPage = () => {
	//Proveedor de autenticación
	const authProvider = useAuthProvider()

	//Proveedor de datos
	const dataProvider = defaultDataProvider(api_path)

	const userData = authProvider.getIdentity()

	const [emptyForm, setEmptyForm] = useState(true)
	const [paymentMethodList, setPaymentMethodList] = useState([])

	const {
		isLoading: paymentMethodIsLoading,
		isError: paymentMethodIsError,
		error: paymentMethodError,
	} = useQuery(
		['api/users/payment-method'],
		async () => {
			const { data: response } = await dataProvider.getList(`api/users/payment-method`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: userData.jwt,
				},
			})

			// console.log('response.data: ', response.data)

			setPaymentMethodList(response.data)
			setEmptyForm(false)

			return response.data
		},
		{
			enabled: emptyForm,
		}
	)

	const handleChangePaymentMethod = (e) => {
		dataProvider
			.getOne(`api/users/change-payment-method`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: userData.jwt,
				},
			})
			.then((response) => {
				const { data } = response.data

				if (data && data.url) {
					window.open(data.url, '_blank').focus()
				}
			})
	}

	const handleDeletePaymentMethod = (e) => {
		var instrumentId = e.target.dataset.id

		dataProvider
			.getOne(`api/users/delete-payment-method/${instrumentId}`, {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: userData.jwt,
				},
			})
			.then((response) => {
				const { data } = response.data

				if (data && data.url) {
					window.open(data.url, '_blank').focus()
				}
			})
	}

	return (
		<>
			<form className="form-ichef herramientas">
				<button type="button" className="transition" onClick={handleChangePaymentMethod}>
					Cambiar método de pago
				</button>

				{!paymentMethodIsLoading ? (
					!paymentMethodIsError ? (
						<div className="cyg-row">
							{paymentMethodList &&
								paymentMethodList.map((paymentMethod, key) => {
									return (
										<div key={key} className="cyg-col-full cyg-medios-pago">
											<figure>
												<img src={paymentMethod.issuerImageUrl} alt="" />
											</figure>

											<section>
												<article>
													<p>
														Número de la tarjeta: <span>{paymentMethod.name}</span>
													</p>

													<p>
														Fecha de expiración:{' '}
														<span>
															{new Date(paymentMethod.expiration).toLocaleDateString(
																'es-UY'
															)}
														</span>
													</p>
												</article>

												<button
													type="button"
													className="cyg-btn-delete"
													onClick={handleDeletePaymentMethod}
													data-id={paymentMethod.instrumentId}
												>
													Borrar
												</button>
											</section>
										</div>
									)
								})}
						</div>
					) : (
						<p>{paymentMethodError}</p>
					)
				) : (
					<BeatLoader
						color={'#509F2C'}
						loading={true}
						cssOverride={{
							display: 'block',
							margin: '0 auto',
							borderColor: '#509F2C',
						}}
						size={10}
					/>
				)}
			</form>
		</>
	)
}

export default PaymentMethodSubPage
