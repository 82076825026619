import { QueryClient } from '@tanstack/react-query'

export const queryClientApp = new QueryClient({
	defaultOptions: {
		queries: {
			enabled: true,
			retry: 3,
			retryDelay: 10000,
			suspense: false,
			cacheTime: 1000 * 60 * 60 * 24,
		},
		mutations: {
			// mutation options
		},
	},
})
