import { useContext, useMemo } from 'react'

import serviceProviderContext from './serviceProviderContext'

export const useServiceProvider = () => {
	const serviceProvider = useContext(serviceProviderContext)

	const serviceProviderProxy = useMemo(() => {
		return serviceProvider
	}, [serviceProvider])

	return serviceProviderProxy
}
