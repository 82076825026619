import { createStore, combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import persistStore from 'redux-persist/es/persistStore'
import storage from 'redux-persist/lib/storage'
import { authReducer } from './providers/auth'
import { filtersReducer } from './providers/filter'
import { serviceReducer } from './providers/service'

const rootReducer = combineReducers({
	auth: authReducer,
	filters: filtersReducer,
	services: serviceReducer,
})

const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(
	persistedReducer,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const persistor = persistStore(store)
