import { useServiceProvider } from '../../providers/service'
import FolderSharingForm from './FolderSharingForm'

function FolderSharingFormLightbox() {
	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	return (
		<div
			className="cyg-lightbox carpetas"
			id="compartir-carpeta"
			onClick={() => serviceProvider.folderSharingFormLightBoxOff()}
		>
			<i className="btn-close"></i>

			<FolderSharingForm />
		</div>
	)
}

export default FolderSharingFormLightbox
