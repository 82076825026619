import React from 'react';
import advise from '../../images/pop-up_flyer.gif'
import { useServiceProvider } from '../../providers/service'

function AdvertiseLightbox() {
	//Proveedor de servicios
	const serviceProvider = useServiceProvider();

	const closeAdvertiseLightBox = () => {
		serviceProvider.AdvertiseLightBoxOff()
	};

	return (
		<div
			className="cyg-lightbox advertise"
			id="advertise" onClick={closeAdvertiseLightBox}
		>
			<i className="btn-close" onClick={closeAdvertiseLightBox}></i>
			<div className="modal" >
				<a href="https://forms.gle/xEhcjT1yEN2iBfY37" target="_blank" rel="noreferrer">
					<img src={advise} alt={`Actualizacion`} />
				</a>
			</div>
		</div>
	);
}

export default AdvertiseLightbox;
