import { useQuery } from '@tanstack/react-query'
import { useAuthProvider } from '../auth'
import { useDataProvider } from '../data'

function useMultiData(currData, arr) {
	const authProvider = useAuthProvider()
	const dataProvider = useDataProvider()
	const userData = authProvider.getIdentity()
	const { data } = useQuery(['multiData', {}], async () => {
		//alert(currData.length)
		if (currData.length) return currData

		const ret = []
		for (var t = 0; t < arr.length; ++t) {
			const { data } = await dataProvider.getList(arr[t], {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json, text/plain, */*',
					Authorization: userData.jwt,
				},
			})
			ret.push(data)
		}
		return ret
	})
	return data || []
}

export { useMultiData }
