import { useEffect, useState } from 'react'

export function useIsVisible(ref) {
	const [isIntersecting, setIntersecting] = useState(false)

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
		ref && observer.observe(ref)

		return () => {
			observer.disconnect()
		}
	}, [ref])

	return isIntersecting
}
