import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import moment from "moment";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import { newPosition, swapEffect } from "../../providers/utils/functions";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useAuthProvider } from '../../providers/auth';
import "rc-time-picker/assets/index.css";

function CookingSteps({ pasos: _steps = [], updateField, _modo_velocidad = [], _operaciones = [], ingredientes = [] }) {
	const { t } = useTranslation();
	const [pasos, setPasos] = useState([]);

	const authProvider = useAuthProvider()
	const userData = authProvider.getIdentity()

	const updateTimeField = (idx, field, o) => {
		const value = moment(o).diff(moment(o).startOf("day"), "seconds");
		pasos[idx] = { ...pasos[idx], [field]: value };
		setPasos(pasos);
		updateField("pasos", { target: { value: pasos } });
	};

	useEffect(() => {
		_steps && _steps.length && setPasos(_steps.sort(({ numeroPaso: p1 }, { numeroPaso: p2 }) => p1 - p2));
	}, [_steps]);

	const addStep = () => {
		pasos.push({ numeroPaso: pasos.length + 1, idOperacion: 1,idModoVelocidad:2  });
		setPasos(pasos);
		updateField("pasos", { target: { value: pasos } });
	};

	const updatePaso = (paso, conf, { target: { value } }) => {
		pasos[paso] = { ...pasos[paso], [conf]: value };
		
		if(conf==="idOperacion" && (value==="8" || value==="5") && pasos[paso].idModoVelocidad==null){
			pasos[paso].idModoVelocidad=2;
		}
		setPasos(pasos);
		updateField("pasos", { target: { value: pasos } });
	};

	const removeStep = step => {
		const all = pasos.filter(({ numeroPaso }) => numeroPaso !== step);
		all.forEach((e, i) => {
			all[i].numeroPaso = i + 1;
		});
		setPasos(all);
		updateField("pasos", { target: { value: all } });
	};

	const moveStep = (dir, idx) => {
		const dospasos = [idx, dir === "up" ? idx - 1 : idx + 1];
		const dos = pasos.filter(({ numeroPaso }) => dospasos.includes(numeroPaso));
		if (dos.length === 2) {
			swapEffect(dospasos, () => {
				const value = [...pasos];
				const __pp = value[dos[0].numeroPaso - 1].numeroPaso;
				value[dos[0].numeroPaso - 1].numeroPaso = value[dos[1].numeroPaso - 1].numeroPaso;
				value[dos[1].numeroPaso - 1].numeroPaso = __pp;
				setPasos(value.sort(({ numeroPaso: p1 }, { numeroPaso: p2 }) => p1 - p2));
				updateField("pasos", { target: { value } });
			});
		}
	};

	const insertStep = step => {
		const o = document.querySelector(`section[paso='${step}']`);
		const as = document.getElementById("allSteps");
		const newNode = o.cloneNode();
		newNode.className = "inserted";
		as.insertBefore(newNode, o);
		newNode.style.height = o.clientHeight + "px";

		setTimeout(() => {
			const all = pasos.map(e => {
				const numeroPaso = e.numeroPaso >= step ? e.numeroPaso + 1 : e.numeroPaso;
				return {
					...e,
					numeroPaso
				};
			});
			all.push({ numeroPaso: step });
			updateField("pasos", { target: { value: all } });

			newPosition(newNode, newNode);
			newNode.style.opacity = "0";

			setTimeout(() => {
				newNode.parentNode.removeChild(newNode);
			}, 800);
		}, 700);
	};

	function mod(value, n) {
		return ((value % n) + n) % n;
	}

	return (
		<article id="allSteps" className={`cooking-step`}>
			{		pasos.map((myPaso, index) => {
						const {
							cantidad = "",
							idModoVelocidad = 2,
							idOperacion = 1,
							numeroPaso = "",
							nivelVelocidad = "",
							temperatura = "",
							tiempo = "",
							descripcion = "",
							marca = 0,
							idInsumo = 0,
						} = myPaso;

						const opcionesOperacion = _operaciones.map(({ idOperacion, operacion }) => ({
							value: idOperacion,
							label: t([`operation.${operacion}`, operacion])
						}));
						const opcionesDireccion = _modo_velocidad.map(({ idModoVelocidad, nombreModo }) => ({
							value: idModoVelocidad,
							label: t([`direction.${nombreModo}`, nombreModo])
						}));
						const opcionesIngredientes = ingredientes.map(({ idInsumo, nombreInsumo }) => ({
							value: idInsumo,
							label: nombreInsumo
						}));
						const _operacion = _operaciones && _operaciones.find(operacion => operacion.idOperacion === idOperacion);
						const campos = _operacion?.campos.map(({ campo }) => campo) || [];

						return (
							<section
								className={`cont ${marca ? (marca === 1 ? "bg-warning" : "bg-error") : "bg-verde-rgba-2"}`}
								key={index}
								paso={numeroPaso}
							>
								<div className="sec-paso">
									<span className="paso">
										{t("step")} {numeroPaso}
									</span>
									<div className="operaciones">
										<div className="fld">
											<div className="fld-label">{t("operation.title")}</div>
											<div className="fld-content-dd">
												<Select
													placeholder={t("operation.title")}
													className="select-ingredient"
													options={opcionesOperacion}
													value={opcionesOperacion.find(operacion => operacion.value === idOperacion)}
													onChange={target => updatePaso(index, "idOperacion", { target })}
													menuShouldScrollIntoView={false}
												/>
											</div>
										</div>
										{false && campos.includes("CANTIDAD") && (
											<div className="fld">
											<div className="fld-label">{t("ingredients")}</div>
											<div className="fld-content-dd">
												<Select
													placeholder={t("ingredients")}
													className="select-ingredient"
													options={opcionesIngredientes}
													value={opcionesIngredientes.find(ingr => ingr.value === idInsumo)}
													onChange={target => updatePaso(index, "idInsumo", { target })}
													menuShouldScrollIntoView={false}
												/>
											</div>
											</div>
										)}
										{campos.includes("CANTIDAD") && (
											<div className="fld">
												<div className="fld-label">{t("amount")}</div>
												<div className="fld-addon">{userData.useImperial ? "oz" : "g"}</div>
												<div className="fld-content">
													<input
														id="cantidad"
														value={cantidad || ""}
														onChange={o => updatePaso(index, "cantidad", o)}
													/>
												</div>
											</div>
										)}
										{campos.includes("TEMPERATURA") && (
											<div className="fld">
												<div className="fld-label">{t("temperature")}</div>
												<div className="fld-addon">{userData.useImperial ? "°F" : "°C"}</div>
												<div className="fld-content">
													<input
														id="temperatura"
														value={temperatura || ""}
														onChange={o => updatePaso(index, "temperatura", o)}
													/>
												</div>
											</div>
										)}
										{campos.includes("MODO") && (
											<div className="fld">
												<div className="fld-label">{t("direction.title")}</div>
												<div className="fld-content-dd">
													<Select
														placeholder="Modo"
														className="select-ingredient"
														options={opcionesDireccion}
														value={opcionesDireccion.find(modo => modo.value === idModoVelocidad)}
														onChange={target => updatePaso(index, "idModoVelocidad", { target })}
													/>
												</div>
											</div>
										)}
										{campos.includes("VELOCIDAD") && (
											<div className="fld">
												<div className="fld-label">{t("speed")}</div>
												<div className="fld-content">
													<input
														id="nivel-velocidad"
														value={nivelVelocidad || ""}
														onChange={o => {
															const {
																target: { value }
															} = o;
															if (value >= 0 && value <= 10) updatePaso(index, "nivelVelocidad", o);
														}}
														min={0}
														max={10}
														step={0.5}
														type="number"
													/>
												</div>
											</div>
										)}
										{campos.includes("TIEMPO") && (
											<div className="fld">
												<div className="fld-label">{t("time")}</div>
												<div className="fld-content-tp">
													<TimePicker
														defaultValue={moment().startOf("day")}
														value={moment()
															.startOf("day")
															.add(tiempo || 0, "seconds")}
														className="time-ctrl"
														id="time_ctrl"
														placeholder="Tiempo"
														hideDisabledOptions
														onChange={o => updateTimeField(index, "tiempo", o)}
													/>
												</div>
											</div>
										)}
									</div>
									<div className="controles">
										<Tooltip id="step-tooltip" />
										<button
											type="button"
											className="btn btn-primary"
											onClick={() => updatePaso(index, "marca", { target: { value: mod(marca - 1, 3) } })}
											data-tooltip-id="step-tooltip"
											data-tooltip-content={t("mark")}
										>
											<Icon type="Vista" className="step-ico" />
										</button>

										<button
											className="subir"
											onClick={() => moveStep("up", numeroPaso)}
											data-tooltip-id="step-tooltip"
											data-tooltip-content={t("move_up")}
										>
											<Icon type="Subir" className="step-ico" />
										</button>

										<button
											className="bajar"
											onClick={() => moveStep("down", numeroPaso)}
											data-tooltip-id="step-tooltip"
											data-tooltip-content={t("move_down")}
										>
											<Icon type="Bajar" className="step-ico" />
										</button>

										<button
											className="insertar"
											onClick={() => insertStep(numeroPaso)}
											data-tooltip-id="step-tooltip"
											data-tooltip-content={t("add_above")}
										>
											<Icon type="Insertar" className="step-ico" />
										</button>
										<button
											type="Cerrar"
											className="eliminar"
											onClick={() => removeStep(numeroPaso)}
											data-tooltip-id="step-tooltip"
											data-tooltip-content={t("remove")}
										>
											<Icon type="Eliminar" className="step-ico" />
										</button>
									</div>
								</div>
								{campos.includes("DESCRIPCION") && idOperacion !== 3 && idOperacion !== 8 && (
									<div className="sec-text-area">
										<div>
											<textarea
												placeholder={t("description")}
												value={descripcion ?? ""}
												onChange={o => updatePaso(index, "descripcion", o)}
											/>
										</div>
									</div>
								)}
							</section>
						);
				  })}
				<section className="sec-nuevo-paso">
					<button type="button" className="btn btn-primary" onClick={addStep}>
						{t("add_new_step")} &gt;
					</button>
				</section>
		</article>
	);
}

export default CookingSteps;
