import { BeatLoader } from 'react-spinners'
import { Navigation } from 'swiper'
import { PRIVILEGIOS, tienePermisoDe } from '../../providers/utils/functions'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAuthProvider } from '../../providers/auth'
import { useEffect, useState } from 'react'
import { useFilterProvider } from '../../providers/filter'
import { useTranslation } from "react-i18next";
import './FiltersHeader.scss'
import 'swiper/css'
import 'swiper/css/navigation'

const SECONDARY_MENU_LABEL_TYPE_IDS =
	typeof process.env.REACT_APP_SECONDARY_MENU_LABEL_TYPE_IDS === 'string'
		? process.env.REACT_APP_SECONDARY_MENU_LABEL_TYPE_IDS.split(',').map((id) => parseInt(id))
		: []

function FiltersHeader({ filterList }) {
	const {
		isLoading: filterListIsLoading,
		isError: filterListIsError,
		error: filterListError,
		data: filterListData,
	} = filterList
	
	// Proveedor de filtros
	const filterProvider = useFilterProvider()
	const { t } = useTranslation();

	const { CREAR_RECETA, EDITAR_SCORE } = PRIVILEGIOS
	const authProvider = useAuthProvider()

	const userData = authProvider.getIdentity()

	const searchFilter = filterProvider.getFilter('search')
	const selectedCategories = filterProvider.getFilter('categories')
	const selectedLabels = filterProvider.getFilter('labels')
	const selectedFlavors = filterProvider.getFilter('flavors')
	const selectedDifficulties = filterProvider.getFilter('difficulties')
	//const recipeStatus = filterProvider.getFilter('status')
	const selectedStatuses = filterProvider.getFilter('status')

	/*
	const handleStatusFilters = (data) => {
		let status = selectedStatuses ? selectedStatuses : {}
		status[data.idEstado] = data
		filterProvider.updateFilter('status', status)
	}
	*/

	const deleteStatusFilters = (idStatus) => {
		let selectedStatusAux = selectedStatuses

		if (typeof selectedStatusAux[idStatus] !== 'undefined') {
			delete selectedStatusAux[idStatus]
		}

		filterProvider.updateFilter('status', selectedStatusAux)
	}

	const deleteCategoriesFilters = (idCategoria) => {
		let selectedCategoriesAux = selectedCategories

		if (typeof selectedCategoriesAux[idCategoria] !== 'undefined') {
			delete selectedCategoriesAux[idCategoria]
		}

		filterProvider.updateFilter('categories', selectedCategoriesAux)
	}

	const deleteFlavorsFilters = (idFlavor) => {
		let selectedFlavorsAux = selectedFlavors

		if (typeof selectedFlavorsAux[idFlavor] !== 'undefined') {
			delete selectedFlavorsAux[idFlavor]
		}

		filterProvider.updateFilter('flavors', selectedFlavorsAux)
	}

	const deleteDifficultiesFilters = (idDifficulty) => {
		let selectedDifficultiesAux = selectedDifficulties

		if (typeof selectedDifficultiesAux[idDifficulty] !== 'undefined') {
			delete selectedDifficultiesAux[idDifficulty]
		}

		filterProvider.updateFilter('difficulties', selectedDifficultiesAux)
	}

	const handleLabelsFilters = (data) => {
		let labelTypes = selectedLabels ? selectedLabels : {}
		let labelType = labelTypes[data.idTipoEtiqueta]

		labelType = typeof labelType === 'undefined' ? {} : labelType
		labelType.labels = typeof labelType.labels === 'undefined' ? {} : labelType.labels
		labelType.labels[data.idEtiqueta] = { idEtiqueta: data.idEtiqueta, nombreEtiqueta: data.nombreEtiqueta }

		labelTypes[data.idTipoEtiqueta] = {
			...labelType,
			idTipoEtiqueta: data.idTipoEtiqueta,
			tipoEtiqueta: data.tipoEtiqueta,
		}

		filterProvider.updateFilter('labels', labelTypes)
	}

	const deleteLabelsFilters = (idTipoEtiqueta, idEtiqueta) => {
		let selectedLabelsAux = selectedLabels ? selectedLabels : {}

		if (
			typeof selectedLabelsAux[idTipoEtiqueta] !== 'undefined' &&
			typeof selectedLabelsAux[idTipoEtiqueta]['labels'] !== 'undefined' &&
			typeof selectedLabelsAux[idTipoEtiqueta]['labels'][idEtiqueta] !== 'undefined' &&
			selectedLabelsAux[idTipoEtiqueta]['labels'][idEtiqueta].idEtiqueta === idEtiqueta
		) {
			delete selectedLabelsAux[idTipoEtiqueta]['labels'][idEtiqueta]

			if (
				selectedLabelsAux[idTipoEtiqueta]['labels'] &&
				Object.keys(selectedLabelsAux[idTipoEtiqueta]['labels']).length === 0
			) {
				delete selectedLabelsAux[idTipoEtiqueta]
			}
		}

		filterProvider.updateFilter('labels', selectedLabelsAux)
	}

	const [selectedLabelsWithoutSecondaryMenu, setSelectedLabelsWithoutSecondaryMenu] = useState([])

	useEffect(() => {
		setSelectedLabelsWithoutSecondaryMenu(
			selectedLabels
				? Object.values(selectedLabels)
						.filter((d) => ![...SECONDARY_MENU_LABEL_TYPE_IDS].includes(d.idTipoEtiqueta))
						.map((labelType) => {
							return Object.values(labelType.labels).map((label) => {
								return {
									idTipoEtiqueta: labelType.idTipoEtiqueta,
									idEtiqueta: label.idEtiqueta,
									nombreEtiqueta: label.nombreEtiqueta,
								}
							})
						})
				: []
		)
	}, [selectedLabels, filterProvider])

	return (
		<>
			{SECONDARY_MENU_LABEL_TYPE_IDS.length > 0 && (
				<section>
					<div className="container container-2">
						<div className="cyg-row d-flex">
							{!filterListIsLoading ? (
								<>
									{!filterListIsError ? (
										<Swiper
											modules={[Navigation]}
											navigation
											cssMode
											centerInsufficientSlides
											roundLengths
											breakpoints={{
												320: {
													slidesPerView: 3,
													slidesPerGroup: 3,
													spaceBetween: 5,
												},
												370: {
													slidesPerView: 3,
													slidesPerGroup: 3,
													spaceBetween: 5,
												},
												480: {
													slidesPerView: 4,
													slidesPerGroup: 4,
													spaceBetween: 10,
												},
												769: {
													slidesPerView: 6,
													slidesPerGroup: 6,
													spaceBetween: 10,
												},
												900: {
													slidesPerView: 10,
													slidesPerGroup: 10,
													spaceBetween: 10,
												},
												1180: {
													slidesPerView: 15,
													slidesPerGroup: 15,
													spaceBetween: 10,
												},
											}}
											className="ichef--cont-cate"
										>
											{Array.isArray(filterListData.tiposEtiqueta) &&
												filterListData.tiposEtiqueta
													.filter((d) =>
														[...SECONDARY_MENU_LABEL_TYPE_IDS].includes(d.idTipoEtiqueta)
													)
													.map((labelType) => {
														const selectedLabelType =
															selectedLabels &&
															typeof selectedLabels[labelType.idTipoEtiqueta] !==
																'undefined'
																? selectedLabels[labelType.idTipoEtiqueta]
																: null

														return (
															Array.isArray(labelType.etiquetas) &&
															labelType.etiquetas.filter(l => l.idEtiqueta !== 186).map((label, index) => {
																const isSelectedLabel =
																	selectedLabelType &&
																	selectedLabelType.labels &&
																	selectedLabelType.labels[label.idEtiqueta] &&
																	selectedLabelType.labels[label.idEtiqueta]
																		.idEtiqueta === label.idEtiqueta
																		? true
																		: false

																const iconClass = `cate-0 cate-${label.idEtiqueta}`

																return (
																	<SwiperSlide key={index}>
																		<a
																			href="/#"
																			className={`ichef--cate ${iconClass} ${
																				isSelectedLabel ? 'activo' : ''
																			}`}
																			onClick={() => {
																				if (!isSelectedLabel) {
																					handleLabelsFilters({
																						...label,
																						idTipoEtiqueta:
																							labelType.idTipoEtiqueta,
																						tipoEtiqueta:
																							labelType.tipoEtiqueta,
																					})
																				} else {
																					deleteLabelsFilters(
																						labelType.idTipoEtiqueta,
																						label.idEtiqueta
																					)
																				}
																			}}
																		>
																			<i></i>
																			<span>{t([`labels.${label.nombreEtiqueta}`, label.nombreEtiqueta])}</span>
																		</a>
																	</SwiperSlide>
																)
															})
														)
													})}
											<SwiperSlide key="clear">
												<a
													href="/#"
													className="ichef--cate btn-filter-clear"
													onClick={() => {
														filterProvider.deleteAllFilters()
													}}
												>
													<i></i>
													<span>{t("clear_filters")}</span>
												</a>
											</SwiperSlide>
										</Swiper>
									) : (
										<p>{filterListError}</p>
									)}
								</>
							) : (
								<BeatLoader
									color={'#509F2C'}
									loading={true}
									cssOverride={{
										display: 'block',
										margin: '0 auto',
										borderColor: '#509F2C',
									}}
									size={10}
								/>
							)}
						</div>
					</div>
				</section>
			)}

			<section>
				<div className="container container-2">
					<div className="cyg-row d-flex mod-cat-sel">
						<div className="d-flex">
							{searchFilter && (
								<p>
									<b>{t("search_result")}</b>{' '}
									<span
										onClick={() => {
											filterProvider.deleteFilter('search')
										}}
									>
										{searchFilter}
									</span>
								</p>
							)}

							{selectedCategories && Object.keys(selectedCategories).length > 0 && (
								<p>
									<b>{t("prep_types.title")}</b>{' '}
									{Object.values(selectedCategories).map((category, index) => {
										return (
											<span
												key={index}
												onClick={() => {
													deleteCategoriesFilters(category.idCategoria)
												}}
											>
												{t([`prep_types.${category.nombreCategoria}`, category.nombreCategoria])}
											</span>
										)
									})}
								</p>
							)}

							{selectedDifficulties && Object.keys(selectedDifficulties).length > 0 && (
								<p>
									<b>{t("difficulty.title")}</b>{' '}
									{Object.values(selectedDifficulties).map((difficulty, index) => {
										return (
											<span
												key={index}
												onClick={() => {
													deleteDifficultiesFilters(difficulty.idDificultad)
												}}
											>
												{t([`difficulty.${difficulty.nivel}`, difficulty.nivel])}
											</span>
										)
									})}
								</p>
							)}

							{selectedLabelsWithoutSecondaryMenu.length > 0 && (
								<p>
									<b>{t("recommended_for")}</b>{' '}
									{selectedLabelsWithoutSecondaryMenu.map((labelType) => {
										return labelType.map((label, index) => {
											return (
												<span
													key={index}
													onClick={() => {
														deleteLabelsFilters(label.idTipoEtiqueta, label.idEtiqueta)
													}}
												>
													{t([`labels.${label.nombreEtiqueta}`, label.nombreEtiqueta])}
												</span>
											)
										})
									})}
								</p>
							)}

							{selectedFlavors && Object.keys(selectedFlavors).length > 0 && (
								<p>
									<b>{t("taste.title")}</b>{' '}
									{Object.values(selectedFlavors).map((flavor, index) => {
										return (
											<span
												key={index}
												onClick={() => {
													deleteFlavorsFilters(flavor.idSabor)
												}}
											>
												{t([`taste.${flavor.sabor}`, flavor.sabor])}
											</span>
										)
									})}
								</p>
							)}

							{tienePermisoDe(CREAR_RECETA, userData) &&
								tienePermisoDe(EDITAR_SCORE, userData) &&
								selectedStatuses &&
								Object.keys(selectedStatuses).length > 0 && (
									<p>
										<b>{t("state.title")}</b>{' '}
										{Object.values(selectedStatuses).map((status, index) => {
											return (
												<span
													key={index}
													onClick={() => {
														deleteStatusFilters(status.idEstado)
													}}
												>
													{t([`state.${status.nombreEstado}`, status.nombreEstado])}
												</span>
											)
										})}
									</p>
								)}
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default FiltersHeader
