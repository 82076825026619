import { useState } from "react";
import { ReactComponent as IcoUsuario } from "../../images/svg/ico-usuario.svg";
import { defaultDataProvider } from "../../providers/data";
import { FORMTYPE, STATUS_SERVER } from "../../providers/utils/functions";

function SignUpForm({ setUserFormType }) {
	const inconcertProvider = defaultDataProvider("https://mas-ichef.inconcertcc.com/");

	const [signUpForm, setSignUpForm] = useState({
		name: "",
		surname: "",
		cellphone: "",
		email: "",
		contactId: ""
	});

	const [serverMsg, setServerMsg] = useState({ status: "", msg: "", show: false });

	const handleTokenVerification = async e => {
		e.preventDefault();
		const { status, data } = await inconcertProvider.create("public/integration/process", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json, text/plain, */*"
			},
			data: {
				serviceToken: "dd788dc721c82b114598a446fc40780a",
				serviceAction: "form",
				contactData: {
					firstname: signUpForm.name,
					lastname: signUpForm.surname,
					email: signUpForm.email,
					phone: signUpForm.cellphone,
					language: "es",
					referredAtCampaignId: "registro_web",
					referredAtInteractionId: "portal_recetas"
				}
			}
		});
		console.log("InConcert", status, data);
		if (status === 200) {
			signUpForm.contactId = data.data.contactId;
			setServerMsg({
				status: STATUS_SERVER.SUCCESS,
				msg: "Recibiras tus credenciales de acceso por mail o por WhatsApp.",
				show: true
			});
		} else {
			setServerMsg({ status: STATUS_SERVER.ERROR, msg: data, show: true });
		}

		/*authProvider
		.signUp(signUpForm)
		.then(async response => {
			const { status } = response || { status: null };
			if (status === 200) {
			}
		})
		.catch(error => {
			setServerMsg({ status: STATUS_SERVER.ERROR, msg: error, show: true });
		});*/
	};

	return (
		<form action="" className="transition form-ichef" onSubmit={e => handleTokenVerification(e)} autoComplete="off">
			<header className="text-center">
				<h6>
					<IcoUsuario />
					{"Registro de invitados"}
				</h6>
			</header>

			<div>
				<input
					type="text"
					name="name"
					placeholder="Nombre"
					onChange={e => setSignUpForm({ ...signUpForm, ...{ name: e.target.value } })}
					value={signUpForm.name}
					required
				/>

				<input
					type="text"
					name="surname"
					placeholder="Apellido"
					onChange={e => setSignUpForm({ ...signUpForm, ...{ surname: e.target.value } })}
					value={signUpForm.surname}
					required
				/>

				<input
					type="tel"
					name="cellphone"
					placeholder="Teléfono"
					onChange={e => setSignUpForm({ ...signUpForm, ...{ cellphone: e.target.value } })}
					value={signUpForm.cellphone}
					required
				/>

				<input
					type="email"
					name="email"
					placeholder="E-mail"
					onChange={e => setSignUpForm({ ...signUpForm, ...{ email: e.target.value } })}
					value={signUpForm.email}
					required
				/>
			</div>
			{serverMsg && serverMsg.show ? (
				<div
					className={`msj ${
						serverMsg.status === STATUS_SERVER.ERROR ? "error" : serverMsg.status === STATUS_SERVER.SUCCESS ? "ok" : ""
					}`}
				>
					{serverMsg.msg}
				</div>
			) : (
				<></>
			)}
			<button className="transition" name="submit" type="submit">
				Registrarse
			</button>
			<footer>
				<a
					href="/#"
					onClick={(event) => {
						event.preventDefault();
						setUserFormType(FORMTYPE.LOGIN);
						setServerMsg({ status: null, msg: "", show: false });
					}}
				>
					Inicio de sesión
				</a>
			</footer>
		</form>
	);
}

export default SignUpForm;
