import axios, { AxiosRequestConfig } from 'axios'
import { FetchOptionsParams } from './types'

export const defaultFetch = (url: string, options: Partial<FetchOptionsParams>) => {
	return axios.create({
		baseURL: url,
		headers: { 'Content-Type': 'application/json' },
	})
}

export const SearchEngineDataProvider = (entrypoint: string, httpClient = defaultFetch) => ({
	getSuggestions: async (options: AxiosRequestConfig) => {
		try {
			const {
				data: dataJson,
				headers,
				status,
			} = await httpClient(entrypoint, options).get('api/recipes/suggestion', options)
			return {
				headers: headers,
				status: status,
				data: dataJson.results ? dataJson.results : []
			}
		} catch (error: any) {
			throw error.message
		}
	},
	search: async (options: AxiosRequestConfig) => {
		try {
			const {
				data: dataJson,
				headers,
				status,
			} = await httpClient(entrypoint, options).get('api/recipes', options)

			return {
				headers: headers,
				status: status,
				data: dataJson.data ? dataJson.data : [],
				total: dataJson.total,
				totalPages: dataJson.totalPages,
				currentPage: dataJson.currentPage,
				nextPage: dataJson.nextPage,
				previousPage: dataJson.previousPage,
				message: '',
			}
		} catch (error: any) {
			throw error.message
		}
	},
})
