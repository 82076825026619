const USER_LOGIN = 'USER_LOGIN'
const USER_LOGOUT = 'USER_LOGOUT'
const UPDATE_FOLDERS = 'UPDATE_FOLDERS'
const UPDATE_WATCH_LATER = 'UPDATE_WATCH_LATER'
const UPDATE_FAVORITES = 'UPDATE_FAVORITES'
const REMOVE_FAVORITES = 'REMOVE_FAVORITES'
const UPDATE_LIKES = 'UPDATE_LIKES'
const REMOVE_LIKES = 'REMOVE_LIKES'

export { USER_LOGIN, USER_LOGOUT, UPDATE_FOLDERS, UPDATE_WATCH_LATER, UPDATE_FAVORITES, REMOVE_FAVORITES, UPDATE_LIKES, REMOVE_LIKES }
