import axios, { AxiosRequestConfig } from 'axios'
import { FetchOptionsParams } from './types'

export const defaultFetch = (url: string, options: Partial<FetchOptionsParams>) => {
	return axios.create({
		baseURL: url,
		headers: { 'Content-Type': 'application/json' },
	})
}

export const LocalAPIDataProvider = (entrypoint: string, httpClient = defaultFetch) => ({
	getFavoriteRecipes: async (options: AxiosRequestConfig) => {
		try {
			const {
				data: dataJson,
				headers,
				status,
			} = await httpClient(entrypoint, options).get('api/recipes/favorites', options)

			return {
				headers: headers,
				status: status,
				data: dataJson.data ? dataJson.data : [],
				total: dataJson.total ? dataJson.total : {},
				totalPages: dataJson.totalPages ? dataJson.totalPages : {},
				message: '',
			}
		} catch (error: any) {
			throw error.message
		}
	},
	getPrivateRecipes: async (options: AxiosRequestConfig) => {
		try {
			const {
				data: dataJson,
				headers,
				status,
			} = await httpClient(entrypoint, options).get('api/recipes/private', options)

			return {
				headers: headers,
				status: status,
				data: dataJson.data ? dataJson.data : [],
				total: dataJson.total ? dataJson.total : {},
				totalPages: dataJson.totalPages ? dataJson.totalPages : {},
				message: '',
			}
		} catch (error: any) {
			throw error.message
		}
	},
})
