import "./styles/App.css";
import "./styles/fonts.css";
import "./styles/movil.css";
import "./styles/print.css";
import { AppCore } from "./AppCore";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MyRecipes from "./pages/MyRecipes";
import RecipeCreateAI from "./pages/RecipeCreateAI/RecipeCreateAI";
import RecipeEdit from "./pages/RecipeEdit/RecipeEdit";
import RecipeList from "./pages/RecipeList/RecipeList";
import RecipePage from "./pages/RecipePage/RecipePage";
import RegisterPageUS from "./pages/RegisterPageUS";
import RegisterPageRetail from "./pages/RegisterPage";
import ActivateAccount from "./pages/ActivateAccount";
import ToolsPage from "./pages/ToolsPage/ToolsPage";
import ReportsPage from "./pages/ReportsPage/ReportsPage";
import FinalizarRegistro from "./pages/FinalizarRegistro";
import PreguntasFrecuentes from "./pages/PreguntasFrecuentes";
import Tutoriales from "./pages/Tutoriales";

function App() {
	return (
		<AppCore>
			<Header />
			<main id="main" role="main">
				<Routes>
					<Route path="/" element={<RecipeList />} />
					<Route path=":tipoAccion/:id/:tipoDeVista" element={<RecipeEdit />} />
					<Route path="crear-receta-ia/:mode" element={<RecipeCreateAI />} />
					<Route path="crear-receta-ia/:mode/:id" element={<RecipeCreateAI />} />
					<Route path="herramientas" element={<ToolsPage />} />
					<Route path="reportes" element={<ReportsPage />} />
					<Route path="mis-recetas/:id/:name" element={<MyRecipes />} />
					<Route path="receta/:id/:name" element={<RecipePage />} />
					<Route path="recetas" element={<RecipeList />} />
					<Route path="registro_retail" element={<RegisterPageRetail />} />
					<Route path="registro" element={<RegisterPageUS />} />
					<Route path="register" element={<RegisterPageUS />} />
					<Route path="activar-cuenta" element={<ActivateAccount />} />
					<Route path="activate-account" element={<ActivateAccount />} />
					<Route path="finalizar-registro" element={<FinalizarRegistro />} />
					<Route path="faq" element={<PreguntasFrecuentes />} />
					<Route path="tutorials" element={<Tutoriales />} />
				</Routes>
			</main>
			<Footer showInfo={false} />
		</AppCore>
	);
}

export default App;
