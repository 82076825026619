import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDataProvider } from "../../providers/data";
import { slugify } from "../../providers/service/Utils";
import "../ToolsPage/Referral.scss";

const IngredientesMarcados = () => {
	const dataProvider = useDataProvider();

	const { data: ingredientes } = useQuery(["ingredients-mark"], async () => {
		const response = await dataProvider.getList("/api/recetas/ingredientes-marcados");
		return response.data;
	});

	const ESTADOS = {
		1: "Publica",
		2: "Privada",
		3: "En revisión",
		4: "Eliminada",
	};

	return (
		<div className="referrals">
			<div className="cyg-row">
				{ingredientes && (
					<div className="cyg-col-full">
						<h4 className="usuario-herr">Ingredientes marcados</h4>
						<table>
							<thead>
								<tr>
									<th>Titulo de la receta</th>
									<th>Nombre del editor</th>
									<th>Descripcion</th>
									<th>Insumo seleccionado</th>
									<th>Color de la marca</th>
									<th>Estado receta</th>
								</tr>
							</thead>
							<tbody>
								{ingredientes.filter(ingr => ingr.estado !== 2 && ingr.estado !== 4)
								.map((ingr, index) => (
									<tr key={index}>
										<td>
											<Link to={`/receta/${ingr.idReceta}/${slugify(ingr.tituloReceta)}`}>
												{ingr.tituloReceta}
											</Link>
										</td>
										<td>{ingr.nombreEditor}</td>
										<td>{ingr.descripcion}</td>
										<td>{ingr.nombreInsumo}</td>
										<td>{ingr.marca === 1 ? "Amarillo" : "Rojo"}</td>
										<td>{ESTADOS[ingr.estado]}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
	);
};

export default IngredientesMarcados;
