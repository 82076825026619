function StarRating({ score = 0, iconClass, setShowQualifyForm = null, onClickScore = null }) {
	return (
		<section className="mod-estrellas d-flex">
			<div>
				{[1, 2, 3, 4, 5].map((i) => (
					<i
						key={i}
						className={`icon-svg ${iconClass}${i <= score ? '-activo' : ''}`}
						onClick={() => onClickScore(i)}
					/>
				))}
			</div>
			{setShowQualifyForm && (
				<button
					className="btn btn-primary btn-enviar btn-calificar"
					onClick={() => setShowQualifyForm((show) => !show)}
				>
					Calificar receta
				</button>
			)}
		</section>
	)
}

export default StarRating
