import { useState } from 'react'
import { ReactComponent as IcoUsuario } from '../../images/svg/ico-usuario.svg'
import { useAuthProvider } from '../../providers/auth'
import validator from 'validator'
import { FORMTYPE, STATUS_SERVER } from '../../providers/utils/functions'
import { useTranslation } from "react-i18next";

function PasswordResetForm({ setUserFormType }) {
	const { t } = useTranslation();
	//Proveedor de autenticación
	const authProvider = useAuthProvider()

	const [tokenSent, setTokenSent] = useState(false)
	const [userOrEmail, setUserOrEmail] = useState('')
	const [serverMsg, setServerMsg] = useState({ status: null, msg: '', show: false })

	const handleResetPassword = async (e) => {
		e.preventDefault()
		const isEmail = validator.isEmail(userOrEmail)
		authProvider
			.reset(!isEmail && userOrEmail, isEmail && userOrEmail)
			.then(async (response) => {
				const { status } = response || { status: null, data: null }

				if (status === 200) {
					setServerMsg({
						status: STATUS_SERVER.SUCCESS,
						msg: 'Revise su bandeja de entrada y copie el token que le enviamos.',
						show: true,
					})
					setTokenSent(true)
					setUserOrEmail('')
				}
			})
			.catch((error) => {
				// console.log('error', error)
				setServerMsg({ status: STATUS_SERVER.ERROR, msg: error, show: true })
			})
	}

	return (
		<form action="" className="transition form-ichef">
			<header className="text-center">
				<h6>
					<IcoUsuario />
					{t("recover_password")}
				</h6>
			</header>

			<div>
				<input
					type="text"
					name="user"
					placeholder={t("user_or_email")}
					onChange={(e) => setUserOrEmail(e.target.value)}
					value={userOrEmail}
					required
				/>
			</div>

			<button className="transition" name="submit" onClick={(e) => handleResetPassword(e)}>
				{t("request")}
			</button>

			<footer>
				<a
					href="/#"
					onClick={(event) => {
						event.preventDefault();
						setUserFormType(FORMTYPE.LOGIN)
						setServerMsg({ status: null, msg: '', show: false })
					}}
				>
					{t("login")}
				</a>
				<a
					href="/#"
					onClick={(event) => {
						event.preventDefault();
						setUserFormType(FORMTYPE.TOKEN_VALIDATION)
						setServerMsg({ status: null, msg: '', show: false })
					}}
				>
					{tokenSent ? t("verify_token") : t("already_have_token")}
				</a>
			</footer>

			{serverMsg && serverMsg.show ? (
				<div
					className={`msj ${
						serverMsg.status === STATUS_SERVER.ERROR
							? 'error'
							: serverMsg.status === STATUS_SERVER.SUCCESS
							? 'ok'
							: ''
					}`}
				>
					{serverMsg.msg}
				</div>
			) : (
				<></>
			)}
		</form>
	)
}

export default PasswordResetForm
