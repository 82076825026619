import { useContext, useMemo } from 'react'

import DataProviderContext from './DataProviderContext'
import { defaultDataProvider } from './DefaultDataProvider'

export const useDataProvider = (providerName = 'default') => {
	const contextValue = useContext(DataProviderContext)
	const dataProvider = providerName ? contextValue[providerName] : defaultDataProvider

	const dataProviderProxy = useMemo(() => {
		return dataProvider
	}, [dataProvider])

	return dataProviderProxy
}
