import './ToggleButton.css';

const ToggleButton = ({ options, selected, onSwitch }) => {
	return (
		<div className="toggle-button">
			{options.map((option, index) => (
				<div
					key={option.value}
					className={`option ${index === selected ? 'selected' : ''}`}
					disabled={index === selected}
					onClick={() => onSwitch(option.value)}
				>
					{option.label}
				</div>
			))}
		</div>
	);
};

export default ToggleButton;
