import { useState, useEffect, useRef } from 'react'
import { ReactComponent as IcoLupa } from '../images/svg/ico-lupa.svg'
import { useFilterProvider } from '../providers/filter'
import { useDataProvider } from '../providers/data'
import { useQuery } from '@tanstack/react-query'
import { useServiceProvider } from '../providers/service'
import InputAutoComplete from './InputAutoComplete/InputAutoComplete'
import { useTranslation } from "react-i18next";

function SearchForm() {
	const { t, i18n } = useTranslation();
	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	//Proveedor de filtros
	const filterProvider = useFilterProvider()
	const searchFilter = filterProvider.getFilter('search')

	//Proveedor de datos
	const searchEngineDataProvider = useDataProvider('searchEngine')

	const [searchValue, setSearchValue] = useState(searchFilter || '')

	const { isLoading: suggestionsIsLoading, data: suggestionsData } = useQuery(
		['recipeSuggestions', { searchValue: searchValue }, i18n.resolvedLanguage],
		async () => {
			const response = await searchEngineDataProvider.getSuggestions({
				params: {
					query: searchValue,
					languaje: i18n.resolvedLanguage,
				},
			})
			return response
		},
		{ keepPreviousData: true, enabled: typeof searchValue === 'string' && searchValue.length > 0 }
	)

	useEffect(() => {
		if (searchFilter) {
			setSearchValue(searchFilter)
		} else {
			serviceProvider.searchInputOff()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchFilter])

	const handleSearch = (value = null) => {
		filterProvider.updateFilter('search', value || searchValue)
	}

	const inputAutocompleteRef = useRef(null)

	const handleShowInput = () => {
		serviceProvider.searchInputOn()
		inputAutocompleteRef?.current?.focus()
	}

	const getSelectedVal = (value) => {
		const inputValue = value.trim().toLowerCase()
		setSearchValue(inputValue)
		if (value !== '' && suggestionsData) {
			handleSearch(inputValue)
			serviceProvider.searchInputOff()
		}
	}

	const getChanges = (value) => {
		const inputValue = value.trim().toLowerCase()
		setSearchValue(inputValue)
	}

	return (
		<div>
			<i id="btn-buscar" className="transition" onClick={() => handleShowInput()}>
				<IcoLupa />
			</i>
			<div className="ichef--buscador">
				<form
					className={`form-ichef`}
					onSubmit={(e) => {
						e.preventDefault()
						handleSearch()
						serviceProvider.searchInputOff()
						return false
					}}
				>
					<InputAutoComplete
						ref={inputAutocompleteRef}
						pholder={t("search_text")}
						suggestionsList={!suggestionsIsLoading && suggestionsData ? suggestionsData.data : []}
						onSelected={getSelectedVal}
						onChange={getChanges}
						inputValue={searchFilter}
						onBlur={value => {
							if (!value)
								serviceProvider.searchInputOff()
						}}
						onBack={() => {
							serviceProvider.searchInputOff()
						}}
					/>
				</form>
			</div>
		</div>
	)
}

export default SearchForm
