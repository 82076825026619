import IcoFac3 from '../images/svg/ico-1-dif.svg'
import IcoFac1 from '../images/svg/ico-1-fac.svg'
import IcoFac2 from '../images/svg/ico-1-med.svg'
import TiempoChef from '../images/svg/ico-2.svg'
import TiempoPreparacion from '../images/svg/ico-3.svg'
import Porciones from '../images/svg/ico-4.svg'
import Cerrar from '../images/svg/ico-close.svg'
import Subir from '../images/svg/ico-arrow-up.svg'
import Bajar from '../images/svg/ico-arrow-down.svg'
import Insertar from '../images/svg/ico-insertar.svg'
import Vista from '../images/svg/ico-vista.svg'
import Eliminar from '../images/ico-eliminar.svg'

const Icon = (props) => {
	const types = {
		IcoFac1,
		IcoFac2,
		IcoFac3,
		TiempoChef,
		TiempoPreparacion,
		Porciones,
		Cerrar,
		Subir,
		Bajar,
		Insertar,
		Vista,
		Eliminar,
	}

	const { type, alt = '', ...rest } = props
	if (!types[type]) return null
	return <img alt={alt} src={types[type]} {...rest} />
}

export default Icon
