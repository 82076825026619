const defaultRecipe = {
	valoresNutricionales: {
		cantidad: {
			editSource: 'cantidad',
			defaultValue: '',
		},
		idNutriente: {
			editSource: 'idNutriente',
			defaultValue: '',
		},
		codLenguaje:{
			editSource: 'codLenguaje',
			defaultValue: 'es',
		},
		clasificacion: {
			editSource: 'clasificacion',
			defaultValue: 'A',
		},
		comentario: {
			editSource: 'comentario',
			defaultValue: '',
		},
		comentarioTrad: {
			editSource: 'comentarioTrad',
			defaultValue: '',
		},
		comentarioOrig: {
			editSource: 'comentarioOrig',
			defaultValue: '',
		},
		idUnidad: {
			editSource: 'idUnidad',
			defaultValue: '',
		},
		nombreUnidad: {
			editSource: 'nombreUnidad',
			defaultValue: '',
		},
	},
	fotosReceta: {
		nombreImagen: {
			editSource: 'nombreImagen',
			defaultValue: '',
		},
		urlImagen: {
			editSource: 'urlImagen',
			defaultValue: '',
		},
	},
	etiquetas: {
		idReceta: {
			editSource: 'idReceta',
			defaultValue: '',
		},
		idEtiqueta: {
			editSource: 'idEtiqueta',
			defaultValue: '',
		},
		nombreEtiqueta: {
			editSource: 'nombreEtiqueta',
			defaultValue: '',
		},
		idTipoEtiqueta: {
			editSource: 'idTipoEtiqueta',
			defaultValue: '',
		},
		tipoEtiqueta: {
			editSource: 'tipoEtiqueta',
			defaultValue: '',
		},
	},
	ingredientes: {
		descripcion: {
			editSource: 'descripcion',
			defaultValue: '',
		},
		descripcionTrad: {
			editSource: 'descripcionTrad',
			defaultValue: '',
		},
		descripcionOrig: {
			editSource: 'descripcionOrig',
			defaultValue: '',
		},
		cantidad: {
			editSource: 'cantidad',
			defaultValue: '',
		},
		idUnidad: {
			editSource: 'idUnidad',
			defaultValue: '',
		},
		idInsumo: {
			editSource: 'idInsumo',
			defaultValue: '',
		},
		nombreUnidad: {
			editSource: 'nombreUnidad',
			defaultValue: '',
		},
		nombreInsumo: {
			editSource: 'nombreInsumo',
			defaultValue: '',
		},
		descripcionLarga: {
			editSource: 'descripcionLarga',
			defaultValue: '',
		},
		principal: {
			editSource: 'principal',
			defaultValue: false,
		},
		marca: {
			editSource: 'marca',
			defaultValue: '',
		},
	},
	imagenPasos: {
		numeroPaso: {
			editSource: 'numeroPaso',
			defaultValue: '',
		},
		urlImagen: {
			editSource: 'urlImagen',
			defaultValue: '',
		},
		nombreImagen: {
			editSource: 'nombreImagen',
			defaultValue: '',
		},
	},
	pasos: {
		numeroPaso: {
			editSource: 'numeroPaso',
			defaultValue: '',
		},
		descripcion: {
			editSource: 'descripcion',
			defaultValue: '',
		},
		descripcionTrad: {
			editSource: 'descripcionTrad',
			defaultValue: '',
		},
		descripcionOrig: {
			editSource: 'descripcionOrig',
			defaultValue: '',
		},
		tiempo: {
			editSource: 'tiempo',
			defaultValue: '',
		},
		temperatura: {
			editSource: 'temperatura',
			defaultValue: '',
		},
		potencia: {
			editSource: 'potencia',
			defaultValue: '',
		},
		idModoVelocidad: {
			editSource: 'idModoVelocidad',
			defaultValue: '',
		},
		nivelVelocidad: {
			editSource: 'nivelVelocidad',
			defaultValue: '',
		},
		cantidad: {
			editSource: 'cantidad',
			defaultValue: '',
		},
		idOperacion: {
			editSource: 'idOperacion',
			defaultValue: '',
		},
		idAccesorio: {
			editSource: 'idAccesorio',
			defaultValue: '',
		},
		imagenes: {
			structure: 'imagenPasos',
			defaultValue: [],
		},
		marca: {
			editSource: 'marca',
			defaultValue: '',
		},
	},
	preparacion: {
		numeroPaso: {
			editSource: 'numeroPaso',
			defaultValue: '',
		},
		titulo: {
			editSource: 'titulo',
			defaultValue: '',
		},
		parrafo: {
			editSource: 'parrafo',
			defaultValue: '',
		},
	},
	receta: {
		/*idReceta: {
			editSource: 'idReceta',
			defaultValue: '',
		},
		idTraduccion: {
			editSource: 'idReceta',
			defaultValue: '',
		},*/
		codLenguaje: {
			editSource: 'codLenguaje',
			defaultValue: '',
		},
		idRecetaOriginal: {
			editSource: 'idRecetaOriginal',
			defaultValue: '',
		},
		titulo: {
			editSource: 'titulo',
			defaultValue: '',
		},
		tituloTrad: {
			editSource: 'tituloTrad',
			defaultValue: '',
		},
		tituloOrig: {
			editSource: 'tituloOrig',
			defaultValue: '',
		},
		idCategoria: {
			editSource: 'idCategoria',
			defaultValue: '12',
		},
		clasificacion: {
			editSource: 'clasificacion',
			defaultValue: '',
		},
		tiempoPreparacion: {
			editSource: 'tiempoPreparacion',
			defaultValue: '',
		},
		tiempoChef: {
			editSource: 'tiempoChef',
			defaultValue: '',
		},
		porciones: {
			editSource: 'porciones',
			defaultValue: '',
		},
		notasChef: {
			editSource: 'notasChef',
			defaultValue: '',
		},
		notasChefTrad: {
			editSource: 'notasChefTrad',
			defaultValue: '',
		},
		notasChefOrig: {
			editSource: 'notasChefOrig',
			defaultValue: '',
		},
		accesorios: {
			editSource: 'accesorios',
			defaultValue: '',
		},
		accesoriosTrad: {
			editSource: 'accesoriosTrad',
			defaultValue: '',
		},
		accesoriosOrig: {
			editSource: 'accesoriosOrig',
			defaultValue: '',
		},
		recomendaciones: {
			editSource: 'recomendaciones',
			defaultValue: '',
		},
		recomendacionesTrad: {
			editSource: 'recomendacionesTrad',
			defaultValue: '',
		},
		recomendacionesOrig: {
			editSource: 'recomendacionesOrig',
			defaultValue: '',
		},
		fechaRegistro: {
			editSource: 'fechaRegistro',
			defaultValue: '',
		},
		chefReferente: {
			editSource: 'chefReferente',
			defaultValue: '',
		},
		sabor: {
			editSource: 'sabor',
			defaultValue: '1',
		},
		idDificultad: {
			editSource: 'idDificultad',
			defaultValue: '1',
		},
		puntuacionAdmin: {
			editSource: 'puntuacionAdmin',
			defaultValue: '0',
		},
		privada: {
			editSource: 'privada',
			defaultValue: 'NONE',
		},
		promocionada: {
			editSource: 'promocionada',
			defaultValue: 'NONE',
		},
		homologada: {
			editSource: 'homologada',
			defaultValue: 'NONE',
		},
		totalCalorias: {
			editSource: 'totalCalorias',
			defaultValue: '',
		},
		original: {
			editSource: 'original',
			defaultValue: '',
		},
		estadoReceta: {
			editSource: 'estadoReceta',
			defaultValue: { idEstado: 2, nombreEstado: 'Privadas' },
		},
		descripcion: {
			editSource: 'descripcion',
			defaultValue: '',
		},

		//  ************* ARREGLOS *******************
		etiquetas: {
			defaultValue: [],
			structure: 'etiquetas',
		},
		/*preparacion: {
			defaultValue: [],
			structure: 'preparacion',
		},*/
		valoresNutricionales: {
			defaultValue: [],
			structure: 'valoresNutricionales',
		},
		fotosReceta: {
			defaultValue: [],
			structure: 'fotosReceta',
		},
		ingredientes: {
			defaultValue: [],
			structure: 'ingredientes',
		},
		pasos: {
			defaultValue: [],
			structure: 'pasos',
		},
	},
}

const mapJson = (name, source, map) => {
	const ret = {}
	const info = map[name] || {}

	Object.keys(info).forEach((d) => {
		const { editSource = d, defaultValue, structure = false } = info[d]
		if (structure) {
			const dta = source[structure] || []
			ret[d] = dta.reduce((ret, values) => {
				return [...ret, mapJson(structure, values, map)]
			}, [])
		} else {
			ret[d] = Object.keys(source).indexOf(editSource) !== -1 ? source[editSource] : defaultValue || null
		}
	})
	return ret
}

export { defaultRecipe, mapJson }
