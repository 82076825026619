import { REMOVE_FILTERS, UPDATE_FILTERS } from './filtersActionTypes'

const filtersActions = {
	updateFilters: function (filter) {
		return {
			type: UPDATE_FILTERS,
			payload: filter,
		}
	},
	removeFilters: function (filter) {
		return {
			type: REMOVE_FILTERS,
		}
	},
}

export default filtersActions
