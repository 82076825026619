import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAuthProvider } from '../../providers/auth'
import { useDataProvider } from '../../providers/data'
import { useServiceProvider } from '../../providers/service'
import { useTranslation } from "react-i18next";

function FolderSharingForm() {
	const { t } = useTranslation();
	//Proveedor de autenticación
	const authProvider = useAuthProvider()
	const userData = authProvider.getIdentity()

	//Proveedor de datos
	const dataProvider = useDataProvider()

	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	const [data, setData] = useState({})

	useEffect(() => {
		setData(serviceProvider.getFolderSharingFormLightBoxData())
	}, [serviceProvider])

	const { data: shareCode } = useQuery(['shareCode', data.idCarpeta], async () => {
		const { data: response } = await dataProvider.getList(`api/carpetas/${data.idCarpeta}/compartir`, {
			headers: {
				Authorization: userData.jwt,
				'Content-Type': 'application/json',
				Accept: 'application/json, text/plain, */*',
			},
		})
		return response
		}, {
			enabled: !!data.idCarpeta
	})

	const handleForm = async(e) => {
		e.preventDefault()
		const form = e.target
		const formData = new FormData(form)
		try {
			await dataProvider.create(`api/carpetas/${data.idCarpeta}/compartir?usuario=${formData.get('user')}`, {
				headers: {
					Authorization: userData.jwt,
				}, data: {
				}
			})
		} catch (e) {
			console.error(e)
		} finally {
			serviceProvider.folderSharingFormLightBoxOff()
		}
	}

	return (
		<form className="form-ichef" onClick={(e) => e.stopPropagation()} onSubmit={handleForm}>
			<h5 className="tit-carpeta">{t("share")}: {data.nombre}</h5>
			<span>{t("share_via_code")}</span>
			<input name="code" type="text" placeholder={t("generating_code")} maxLength={1024} defaultValue={shareCode} />
			<p></p>
			<span>{t("share_with_another_user")}</span>
			<input name="user" type="text" placeholder={t("enter_recipient_id")} />
			<section>
				<button>{t("share")}</button>
			</section>
		</form>
	)
}

export default FolderSharingForm
