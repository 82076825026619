const Section = (props) => {
	const { children, title, subtitle, ...rest } = props

	return (
		<section {...rest}>
			<header className="tit">
				<h3>{title}</h3>
				{subtitle && <h6>{subtitle}</h6>}
			</header>
			{children}
		</section>
	)
}

export default Section
