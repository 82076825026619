import { useServiceProvider } from '../../providers/service'
import FolderDeletingForm from './FolderDeletingForm'

function FolderDeletingFormLightbox() {
	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	return (
		<div
			className="cyg-lightbox carpetas"
			id="eliminar-carpeta"
			onClick={() => serviceProvider.folderDeletingFormLightBoxOff()}
		>
			<i className="btn-close"></i>

			<FolderDeletingForm />
		</div>
	)
}

export default FolderDeletingFormLightbox
