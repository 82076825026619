import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const domainDetector = {
	name: "domain",
	lookup(options) {
		const hostname = window.location.hostname;
		const domainToLanguageMap = {
			localhost: "es",
			"recetas.ichef.com.uy": "es",
			"servicios.cns.com.uy": "es",
			"recipes.ichef.us": "en"
		};
		return domainToLanguageMap[hostname] || "es";
	},
	cacheUserLanguage(lng, options) {}
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(domainDetector);

i18next
	.use(initReactI18next)
	.use(languageDetector)
	.init({
		detection: {
			order: ["cookie", "localStorage", "sessionStorage", "domain", "querystring", "navigator"],
			lookupQuerystring: "lang"
		},
		fallbackLng: "es",
		whitelist: ["en", "es"],
		supportedLngs: ["en", "es"],
		resources: {
			en: {
				translation: require("./english.json")
			},
			es: {
				translation: require("./spanish.json")
			}
		},
		returnEmptyString: false,
		interpolation: {
			escapeValue: false
		}
	});
