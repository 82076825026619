import { BeatLoader } from "react-spinners";
import { ErrorBoundary } from "react-error-boundary";
import { Fragment, Suspense, useEffect, useState } from "react";
import { QueryErrorResetBoundary, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useAuthProvider } from "../../providers/auth";
import { useDataProvider } from "../../providers/data";
import { useFilterProvider } from "../../providers/filter";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import { useServiceProvider } from "../../providers/service";
import { useTranslation } from "react-i18next";
import AdvertiseLightbox from "../../components/login/AdvertiseLightbox";
import FiltersHeader from "../../components/filtersHeader/FiltersHeader";
import FiltersSideBar from "../../components/FiltersSideBar";
import FolderDeletingFormLightbox from "../../components/folders/FolderDeletingFormLightbox";
import FolderFormLightbox from "../../components/folders/FolderFormLightbox";
import FolderSharingFormLightbox from "../../components/folders/FolderSharingFormLightbox";
import FoldersSidebar from "../../components/folders/FoldersSidebar";
import FoldersToRecipeLightbox from "../../components/folders/FoldersToRecipeLightbox";
import RecipeItem from "../../components/RecipeItem";
import RecipeItemMobile from "../../components/RecipeItemMobile";

import "./RecipeList.css";

function RecipeList() {
	const { t, i18n } = useTranslation();
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const token = searchParams.get("token");
	const navigate = useNavigate();

	//Proveedor de autenticación
	const authProvider = useAuthProvider();
	const isAuthenticated = authProvider.checkAuth();

	useEffect(() => {
		if (token) {
			authProvider.setToken(token);
			navigate("/recetas");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	//Proveedor de datos
	const dataProvider = useDataProvider();
	const searchEngineDataProvider = useDataProvider("searchEngine");

	//Proveedor de servicios
	const serviceProvider = useServiceProvider();

	//Proveedor de filtros
	const filterProvider = useFilterProvider();
	const filters = filterProvider.getFilters();

	const recipesPerPage = serviceProvider.isMobileActivated() ? 20 : 48;

	const [recipesMessage, setRecipesMessage] = useState("");
	const {
		data: recipesListData,
		isError: recipesListIsError,
		error: recipesListError,
		isFetching: recipesListIsLoading,
		isFetchingNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		["recipeList", { filters: filters }, i18n.resolvedLanguage],
		async ({ pageParam = 1 }) => {
			const result = await searchEngineDataProvider.search({
				params: {
					page: pageParam ?? 1,
					perPage: recipesPerPage,
					language: i18n.resolvedLanguage,
					filters: { ...filters, includeVariants: true }
				}
			});
			if (result.total === 0)
				setRecipesMessage(t("recipes_not_found"));
			else
				setRecipesMessage(result.message);
			return result;
		},
		{
			getPreviousPageParam: firstPage => {
				return firstPage ? firstPage.previousPage : undefined;
			},
			getNextPageParam: lastPage => {
				return lastPage ? lastPage.nextPage : undefined;
			},
			suspense: false
		}
	);

	const { data: difficultiesData } = useQuery(["difficulties", {}], async () => {
		const { data: difficultyLevelsData } = await dataProvider.getList(`api/dificultad/list`, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json, text/plain, */*"
			}
		});

		let difficultyLevels = [];
		difficultyLevelsData.forEach(element => {
			difficultyLevels[element.idDificultad] = element.nivel;
		});

		return difficultyLevels;
	});

	const {
		isLoading: filterListIsLoading,
		isError: filterListIsError,
		error: filterListError,
		data: filterListData
	} = useQuery(["filterList"], async () => {
		const { data } = await dataProvider.getList("api/filtros", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json, text/plain, */*"
			}
		});
		return {
			...data,
			...{
				estados: [
					{
						idEstado: 3,
						nombreEstado: "En Revisión"
					},
					{
						idEstado: 2,
						nombreEstado: "Privadas"
					},
					{
						idEstado: 1,
						nombreEstado: "Públicas"
					}
				]
			}
		};
	});

	const { ref: nextPageRef, inView: nextPageInView } = useInView();
	const [recipeSelectorActive, setRecipeSelectorActive] = useState(false);

	const {
		data: publicFolders
	} = useQuery(["api/carpetas", i18n.resolvedLanguage], async () => {
		const { data: folders } = await dataProvider.getList(`api/carpetas?complete=true&lenguaje=${i18n.resolvedLanguage}`, {
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*"
			}
		});
		for (const folder of folders) {
			//if (serviceProvider.isMobileActivated()) folder.recetas = folder.recetas.slice(0, 2);
			for (const recipe of folder.recetas) {
				recipe.estado = recipe.estadoReceta ?? recipe.estadoReceta.idEstado;
				recipe.fotosReceta = recipe.fotosReceta[0];
				if (recipe.fotosReceta.urlImagen) {
					recipe.fotosReceta.urlImagen = recipe.fotosReceta.urlImagen.replace(
						"res.cloudinary.com/ichef/image/upload/",
						"res.cloudinary.com/ichef/image/upload/w_300,f_auto/"
					);
				}
			}
		}
		return folders;
	});

	useEffect(() => {
		if (nextPageInView) {
			fetchNextPage();
		}
	}, [nextPageInView, fetchNextPage]);

	return (
		<div
			className="main-cont"
			onClick={event => {
				if (serviceProvider.isSearchInputActivated()) {
					serviceProvider.setSearchInputActive(false);
					serviceProvider.searchInputToggle();
				}
			}}
		>
			{isAuthenticated && serviceProvider.isAdvertiseLightBoxActivated() && <AdvertiseLightbox />}
			{isAuthenticated && serviceProvider.isFolderFormLightBoxActivated() && <FolderFormLightbox />}
			{isAuthenticated && serviceProvider.isFoldersToRecipeLightBoxActivated() && <FoldersToRecipeLightbox />}
			{isAuthenticated && serviceProvider.isFolderSharingFormLightBoxActivated() && <FolderSharingFormLightbox />}
			{isAuthenticated && serviceProvider.isFolderDeletingFormLightBoxActivated() && <FolderDeletingFormLightbox />}
			{isAuthenticated && <FoldersSidebar />}

			<FiltersSideBar
				filterList={{
					isLoading: filterListIsLoading,
					isError: filterListIsError,
					error: filterListError,
					data: filterListData
				}}
			/>

			<section id="content" /* className="wrapper-recipe-list" */>
				<FiltersHeader
					filterList={{
						isLoading: filterListIsLoading,
						isError: filterListIsError,
						error: filterListError,
						data: filterListData
					}}
				/>

				<div className="container container-2">
					<div className="cyg-row">
						{!filterProvider.isThereFilterActivated() &&
							publicFolders && publicFolders.map((folder, index) => {
								return (
									<section key={index}>
										<div className="folder-title-bg">
											<h4 className="tit-mis-recetas narrow">{folder.nombre}</h4>
										</div>
										<div className="cyg-col-full d-flex grilla folder-bg folder-scroll">
											{folder.recetas &&
												folder.recetas.map((recipeData, index) => {
													return serviceProvider.isMobileActivated() ? (
														<RecipeItemMobile
															key={index}
															recipeData={recipeData}
															difficultiesData={difficultiesData}
															recipeSelectorActive={recipeSelectorActive}
															setRecipeSelectorActive={setRecipeSelectorActive}
														/>
													) : (
														<RecipeItem
															key={index}
															recipeData={recipeData}
															difficultiesData={difficultiesData}
														/>
													);
												})}
										</div>
									</section>
								);
							})}

						<div className="cyg-col-full d-flex grilla">
							<QueryErrorResetBoundary>
								{({ reset }) => (
									<ErrorBoundary onReset={reset} FallbackComponent={<p>{recipesListIsError ? recipesListError : ""}</p>}>
										<Suspense fallback={<LoadingRecipes isLoading={recipesListIsLoading} />}>
											{recipesListData && (
												Array.isArray(recipesListData.pages) &&
												recipesListData.pages.map((page, index) => (
													<Fragment key={index}>
														{page &&
															Array.isArray(page.data) &&
															page.data.map((recipeData, index) => {
																return serviceProvider.isMobileActivated() ? (
																	<RecipeItemMobile
																		key={index}
																		recipeData={recipeData}
																		difficultiesData={difficultiesData}
																		recipeSelectorActive={recipeSelectorActive}
																		setRecipeSelectorActive={setRecipeSelectorActive}
																	/>
																) : (
																	<RecipeItem
																		key={index}
																		recipeData={recipeData}
																		difficultiesData={difficultiesData}
																	/>
																);
															})}
													</Fragment>
												))
											)}
											<p>{recipesMessage}</p>
											<div ref={nextPageRef} style={{ width: "100%" }}>
												{isFetchingNextPage ? <LoadingRecipes isLoading={recipesListIsLoading} /> : <></>}
											</div>
										</Suspense>
									</ErrorBoundary>
								)}
							</QueryErrorResetBoundary>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

const LoadingRecipes = ({ isLoading }) => {
	return (
		<div className="sweet-loading cont-receta">
			<BeatLoader
				color={"#509F2C"}
				loading={isLoading}
				cssOverride={{
					display: "block",
					margin: "0 auto",
					borderColor: "#509F2C"
				}}
				size={10}
			/>
		</div>
	);
};

export default RecipeList;
