export class ServiceLocalStore {
	constructor(serviceState, serviceActions, dispatch) {
		this.serviceState = serviceState
		this.dispatch = dispatch
		this.serviceActions = serviceActions
	}

	updateServices(services) {
		this.dispatch(this.serviceActions.updateServices(services))
	}

	getService(name) {
		return this.serviceState[name]
	}

	getServices() {
		return this.serviceState
	}
}
