import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EditRecipeButton({ recipeData, className, action }) {
	const { t } = useTranslation();
	return (
		<Link
			type="button"
			className={className}
			to={`/${action}/plantilla-${recipeData.idReceta}/vista-diseno`}
			data-tooltip-id="recipe-page-tooltip"
			data-tooltip-content={
				action === "editar-receta" ? t("edit_recipe") : action === "clonar-receta" ? t("clone_recipe") : t("create_variant")
			}
		>
			{action === "clonar-receta" ? t("clone_recipe") : ""}
		</Link>
	);
}

export default EditRecipeButton;
