import { Link, useLocation, useNavigate } from "react-router-dom";
import { PRIVILEGIOS } from "../providers/utils/functions";
import { ReactComponent as IcoUsuario } from "../images/svg/ico-usuario.svg";
import { tienePermisoDe } from "../providers/utils/functions";
import { useAuthProvider } from "../providers/auth";
import { useEffect, useState } from "react";
import { useFilterProvider } from "../providers/filter";
import { useServiceProvider } from "../providers/service";
import { useTranslation } from "react-i18next";
import LoginLightbox from "./login/LoginLightbox";
import logoIchefSvg from "../images/svg/logo-ichef.svg";
import SearchForm from "./SearchForm";
import UnitSelector from "./UnitSelector";
import LanguageSelector from "./LanguageSelector";
import "../styles/Header.css";

const SHOW_LOGIN_AFTER_EXPIRATION =
	typeof process.env.REACT_APP_SHOW_LOGIN_AFTER_EXPIRATION !== "undefined" ? process.env.REACT_APP_SHOW_LOGIN_AFTER_EXPIRATION : true;

function Header() {
	const { t } = useTranslation();

	// Proveedor de servicios
	const serviceProvider = useServiceProvider();

	// Proveedor de autenticación
	const authProvider = useAuthProvider();

	const filterProvider = useFilterProvider();

	const isAuthenticated = authProvider.checkAuth();

	const userData = authProvider.getIdentity();

	const [authTimeStampId, setAuthTimeStampId] = useState(null);

	const navigate = useNavigate();

	const location = useLocation();

	const { state } = location;
	const { message = null } = state || {};

	const [alertMsg, setAlertMsg] = useState(null);
	const [alertMsgShow, setAlertMsgShow] = useState("");

	useEffect(() => {
		if (message) {
			setAlertMsg(message);
			setTimeout(() => {
				setAlertMsgShow("show");
			}, 1);

			setTimeout(() => {
				setAlertMsgShow("");
				setTimeout(() => {
					setAlertMsg(null);
				}, 1000);
			}, 5000);
		}
	}, [message]);

	useEffect(() => {
		//Activar el temporizador para mostrar el inicio de sesión después de que expire el token jwt.
		if (SHOW_LOGIN_AFTER_EXPIRATION) {
			if (isAuthenticated) {
				var leftTime = authProvider.getJwtExpiration();

				if (authTimeStampId === null && leftTime !== null) {
					leftTime = leftTime - Date.now();
					let timeStampId = setTimeout(() => {
						serviceProvider.LoginLightBoxOn();
						authProvider.logout();
					}, leftTime);
					setAuthTimeStampId(timeStampId);
				}
			} else {
				if (Number.isInteger(authTimeStampId)) {
					clearTimeout(authTimeStampId);
					setAuthTimeStampId(null);
				}
			}
		}

		return () => {
			if (Number.isInteger(authTimeStampId)) {
				clearTimeout(authTimeStampId);
				setAuthTimeStampId(null);
			}
		};
	}, [authProvider, serviceProvider, isAuthenticated, authTimeStampId]);

	useEffect(() => {
		if (isAuthenticated) {
			serviceProvider.userSessionOn();
		} else {
			serviceProvider.userSessionOff();
		}
	}, [serviceProvider, isAuthenticated]);

	useEffect(() => {
		if (serviceProvider.isMobileActivated()) {
			serviceProvider.mobileOn();
		}
	}, [serviceProvider]);

	const openLoginLightBox = () => {
		serviceProvider.LoginLightBoxOn();
		serviceProvider.menuOff();
	};

	const handleLogout = async e => {
		e.preventDefault();

		closeMenu();
		authProvider.logout();
		filterProvider.deleteAllFilters();
		closeLoginLightBox();
		navigate("/");
	};

	const closeLoginLightBox = () => {
		serviceProvider.LoginLightBoxOff();
	};

	const handleMenu = () => {
		setMenuActive(!menuActive);

		serviceProvider.setMenuActive(menuActive);
		serviceProvider.menuToggle();
	};

	const closeMenu = () => {
		setMenuActive(false);

		serviceProvider.setMenuActive(false);
		serviceProvider.menuOff();
	};

	const handleScroll = e => {
		if (serviceProvider.getScrollTop() > 200) {
			serviceProvider.scrollDownOn();
		} else {
			serviceProvider.scrollDownOff();
		}
	};

	window.addEventListener("scroll", handleScroll);

	const [menuActive, setMenuActive] = useState(false);

	const { CREAR_RECETA, CREAR_RECETA_IA } = PRIVILEGIOS;

	return (
		<>
			{!isAuthenticated && serviceProvider.isLoginLightBoxActivated() && <LoginLightbox />}

			<label id="hamburger" className="btn-menu">
				<input type="checkbox" name="button-flag" value="flag" id="flag" onClick={handleMenu} />
				<span></span>
			</label>

			<header id="header">
				<div className="container container-2">
					<div className="cyg-row">
						<div className="cyg-col-full">
							<h1 id="logo">
								<Link to="/">
									<img src={logoIchefSvg} height="70" width="128" alt="iChef" />
								</Link>
								<span>iChef</span>
							</h1>

							<div className="btn-buscar-wrapper">
								<nav className="menu">
									<div className="selectors">
										<LanguageSelector />
										<UnitSelector />
									</div>
									<SessionBtn
										isAuthenticated={isAuthenticated}
										userData={userData}
										openLoginLightBox={openLoginLightBox}
										handleLogout={handleLogout}
										btnClass="btn-sesion-movil"
										closeMenu={closeMenu}
									/>

									<Link
										to="/recetas"
										className={`transition ${location.pathname === "/recetas" ? "activo" : ""}`}
										onClick={closeMenu}
									>
										{t("recipes")}
									</Link>

									{isAuthenticated && (
										<>
											{tienePermisoDe(CREAR_RECETA, userData) && (
												<Link to="/crear-receta/nueva/vista-diseno" onClick={closeMenu} reloadDocument={true}>
													{t("create_recipe")}
												</Link>
											)}

											{tienePermisoDe(CREAR_RECETA_IA, userData) && (
												<Link to="/crear-receta-ia/crear" onClick={closeMenu}>
													{t("create_assisted_recipe")}
												</Link>
											)}

											{tienePermisoDe(CREAR_RECETA_IA, userData) && (
												<Link to="/crear-receta-ia/convertir" onClick={closeMenu}>
													{t("convert_homemade_recipe")}
												</Link>
											)}
										</>
									)}

									<a href="/#" className="show-mobile" onClick={handleLogout}>
										{t("logout")}
									</a>
								</nav>

								{(location.pathname === "/recetas" || location.pathname === "/") && <SearchForm />}

								<SessionBtn
									isAuthenticated={isAuthenticated}
									userData={userData}
									openLoginLightBox={openLoginLightBox}
									handleLogout={handleLogout}
									btnClass="btn-sesion-pc"
									closeMenu={closeMenu}
								/>
							</div>
						</div>
					</div>
					{alertMsg && <div className={`api-response-msg bg-${alertMsg.type} ${alertMsgShow}`}>{alertMsg.title}</div>}
				</div>
			</header>
		</>
	);
}

const SessionBtn = props => {
	const { isAuthenticated, userData, openLoginLightBox, handleLogout, closeMenu, btnClass } = props;
	const { EDITAR_TODAS } = PRIVILEGIOS;
	const location = useLocation();
	const { t, i18n } = useTranslation();
	return (
		<button id="btn-sesion" className={btnClass} onClick={!isAuthenticated ? openLoginLightBox : null}>
			{isAuthenticated && userData.nombre ? (
				<>
					<div>{userData.nombre}</div>
					<nav className="sub-menu transition">
						<li>
							<Link
								to="/herramientas"
								className={`transition ${location.pathname === "/herramientas" ? "activo" : ""}`}
								onClick={closeMenu}
							>
								{t("tools")}
							</Link>
						</li>
						{tienePermisoDe(EDITAR_TODAS, userData) && (
							<li>
								<Link
									to="/reportes"
									className={`transition ${location.pathname === "/reportes" ? "activo" : ""}`}
									onClick={closeMenu}
								>
									{t("reports")}
								</Link>
							</li>
						)}
						{i18n.resolvedLanguage === "es" && (
							<>
								<li>
									<Link
										to="/faq"
										className={`transition ${location.pathname === "/faq" ? "activo" : ""}`}
										onClick={closeMenu}
									>
										{t("faq")}
									</Link>
								</li>
								<li>
									<Link
										to="/tutorials"
										className={`transition ${location.pathname === "/tutorials" ? "activo" : ""}`}
										onClick={closeMenu}
									>
										{t("tutorials")}
									</Link>
								</li>
							</>
						)}
						<li className="show-pc">
							<a href="/#" onClick={handleLogout}>
								{t("logout")}
							</a>
						</li>
					</nav>
				</>
			) : (
				<>
					<IcoUsuario />
					<div>{t("login")}</div>
				</>
			)}
		</button>
	);
};

SessionBtn.defaultProps = {
	isAuthenticated: false,
	userData: { name: null },
	openLoginLightBox: null,
	handleLogout: null,
	closeMenu: null,
	btnClass: "btn-sesion-pc"
};

export default Header;
