import { useEffect, useState } from 'react'
import { useDataProvider } from '../../providers/data'
import { useAuthProvider } from '../../providers/auth'
import { useServiceProvider } from '../../providers/service'
import { useTranslation } from "react-i18next";

function FolderDeletingForm() {
	const { t } = useTranslation();
	//Proveedor de datos
	const dataProvider = useDataProvider()

	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	//Proveedor de autenticación
	const authProvider = useAuthProvider()
	const folders = authProvider.getFolders()
	const userData = authProvider.getIdentity()

	const [data, setData] = useState({})

	useEffect(() => {
		setData(serviceProvider.getFolderDeletingFormLightBoxData())
	}, [serviceProvider])

	const handleForm = async (e) => {
		e.preventDefault()

		try {
			const response = await dataProvider.delete(`api/carpetas`, {
				data: {
					idCarpeta: data.idCarpeta,
				},
				headers: {
					Authorization: userData.jwt,
				},
			})
			if (response && response.status === 200) {
				authProvider.updateFolders(folders.filter(f => Number(f.idCarpeta) !== Number(data.idCarpeta)))
			}
		} catch (e) {
			console.error(e)
		} finally {
			serviceProvider.folderDeletingFormLightBoxOff()
		}
	}

	return (
		<form className="form-ichef" onClick={(e) => e.stopPropagation()} onSubmit={handleForm}>
			<h5 className="tit-carpeta">{t("delete")}: {data.nombre}</h5>
			<section>
				<button>{t("delete")}</button>
			</section>
		</form>
	)
}

export default FolderDeletingForm
