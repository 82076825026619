import React from "react";
import Collapsible from "react-collapsible";

const PreguntasFrecuentes = () => {
	return (
		<div className="cyg-row">
			<div className="container faq">
				<h2>Preguntas Frecuentes</h2>

				<Collapsible contentOuterClassName="main-title" trigger="Información General">
					<Collapsible trigger="¿Cómo puedo ver iChef en acción y comprarla?">
						<p>
							Para ver todo lo que podés hacer con iChef y despejar tus dudas, podés agendar una demo gratuita virtual o
							presencial en nuestro local de Montevideo o Punta del Este.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo comprar iChef sin hacer una Demo?">
						<p>
							¡Claro! Si ya tomaste la decisión de comprar tu iChef, podés hacerlo en cualquiera de nuestros locales
							(Montevideo o Punta del Este), o por la web:
						</p>
						<a href="https://ichef.com.uy/">Ir a la web</a>
					</Collapsible>
					<Collapsible trigger="¿No puedo asistir a una demo en su local, hay alguna demo grabada para ver?">
						<p>¡No hay problema! Contactanos al WhatsApp al número 097148888 y coordinemos una demo gratuita virtual.</p>
					</Collapsible>
					<Collapsible trigger="¿Dónde puedo conseguir accesorios o repuestos?">
						<p>
							Vas a encontrar todos los accesorios y repuestos que necesites en cualquiera de nuestros locales (Montevideo o
							Punta del Este).
						</p>
					</Collapsible>
					<Collapsible trigger="¿Ofrecen capacitación sobre el uso del robot?">
						<p>
							¡Sí! Podés agendar una capacitación tanto presencial como virtual. Solo tenés que contactarnos al WhatsApp:
							097148888
						</p>
					</Collapsible>
					<Collapsible trigger="¿Tiene garantía el robot?">
						<p>Sí, tiene una garantía de 36 meses.</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Financiación y Pago">
					<Collapsible trigger="¿Dónde y Cómo puedo comprar mi iChef?">
						<p>Podés comprar tu iChef en cualquiera de nuestros locales (Montevideo o Punta del Este), o por la web:</p>
						<a href="https://ichef.com.uy/">Ir a la web</a>
						<p>
							Tenés la opción de comprarlo al contacto, o también financiarlo con tarjeta de crédito o nuestra financión
							propia.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Qué métodos de pago aceptan?">
						<p>Aceptamos pagos en efectivo, transferencia bancaria y tarjetas de crédito o débito.</p>
					</Collapsible>
					<Collapsible trigger="¿Tienen financiación propia?">
						<p>Sí, tenemos un plan de financión directa hasta en 48 cuotas.</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Funciones">
					<Collapsible trigger="¿Se puede hacer cocción lenta en iChef?">
						<p>Si, el iChef incluye la función predeterminada de cocción lenta, y también podés hacerlo de forma manual.</p>
					</Collapsible>
					<Collapsible trigger="¿Se puede freir/dorar con iChef?">
						<p>El iChef no permite freír, pero SÍ sofreír o rehogar.</p>
					</Collapsible>
					<Collapsible trigger="¿Tiene función autolimpiante?">
						<p>
							Sí, funciona como lavado o prelavado según las recetas. Se agrega 1 litro de agua y unas gotas de detergente de
							platos, ¡y listo!
						</p>
					</Collapsible>
					<Collapsible trigger="¿El robot tiene función de enfriado?">
						<p>No, el robot no tiene función de enfriado.</p>
					</Collapsible>
					<Collapsible trigger="¿Puede amasar?">
						<p>Sí, el robot puede realizar funciones de amasado.</p>
					</Collapsible>
					<Collapsible trigger="¿iChef puede cocinar al vapor?">
						<p>Si, cocina al vapor de forma manual o con la función predeterminada de vaporera.</p>
					</Collapsible>
					<Collapsible trigger="¿Las cuchillas del robot iChef funcionan en ambas direcciones?">
						<p>Sí, iChef tiene funcion de avance y reversa.</p>
					</Collapsible>
					<Collapsible trigger="¿Cómo se usa en modo manual?">
						<p>
							Es superfácil: solo tenés que seleccionar el modo deseado y ajustar el tiempo, temperatura o velocidad con la
							perilla correspondiente.
						</p>
					</Collapsible>
					<Collapsible trigger="¿El robot emite una señal cuando termina cada paso?">
						<p>
							Sí, emite un sonido para que estés al tanto del proceso. Por supuesto, podrás ajustar el volumen a tu
							preferencia.
						</p>
					</Collapsible>
					{/*<Collapsible trigger="¿Puedo controlar el robot desde mi celular?">
						<p>¡Sí! El robot está diseñado para que lo puedas controlar desde tu celular, computadora o tablet.</p>
					</Collapsible>*/}
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Características Físicas">
					<Collapsible trigger="¿Cuál es la capacidad del vaso?">
						<p>La capacidad del vaso es de 2.2 litros.</p>
					</Collapsible>
					<Collapsible trigger="¿El robot tiene balanza integrada?">
						<p>Sí, tiene una balanza integrada debajo, lo que te permite medir y pesar tus ingredientes sin problemas.</p>
					</Collapsible>
					<Collapsible trigger="¿Cuánto pesa el robot?">
						<p>Con el embalaje, pesa 11,700 Kg.</p>
					</Collapsible>
					<Collapsible trigger="¿Cuál es el potencia del robot?">
						<p>Opera a 1500 W.</p>
					</Collapsible>
					<Collapsible trigger="¿Cuál es el rango de temperatura del robot?">
						<p>El rango de temperatura va desde 37°C a 160°C.</p>
					</Collapsible>
					<Collapsible trigger="¿Los accesorios que vienen con iChef son aptos para lavavajillas?">
						<p>
							Sí. Además, el robot también tiene la función de lavado o prelavado incorporado, así que es muy fácil de
							limpiar.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Es fácil de transportar?">
						<p>Sí, podés transportarlo y conectarlo a la red Wi-Fi correspondiente sin problemas.</p>
					</Collapsible>
					<Collapsible trigger="¿Dónde puedo comprar un bolso para trasladar mi iChef?">
						<p>
							En nuestro iChef Center podés adquirir un bolso práctico para transportar tu iChef, así como también otros
							repuestos y accesorios que necesites.
						</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Recetas">
					<Collapsible trigger="¿Cómo cargo recetas al robot?">
						<p>
							Es muy sencillo. Solo tenés que ingresar al portal con tu usuario y contraseña, y ahí podrás seleccionar y
							cargar las recetas automáticamente. Un icono verde indica la acción.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Necesito conexión a internet para usar el robot?">
						<p>Necesitás conexión para descargar las recetas, pero luego no es necesario.</p>
					</Collapsible>
					<Collapsible trigger="¿Cuánto tiempo tarda en cargar las recetas?">
						<p>Toma aproximadamente 5 segundos por receta, depende de la velocidad de tu conexión a Internet.</p>
					</Collapsible>
					<Collapsible trigger="¿Cuántas recetas puedo tener el robot?">
						<p>Tiene una capacidad de 5000 recetas.</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Uso del Portal">
					<Collapsible trigger="¿El acceso al portal tiene algún costo?">
						<p>Para los pioneros, el acceso al portal es gratuito.</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo crear mis propias recetas?">
						<p>¡Sí! iChef te permite crear recetas y variantes, ya sea de forma manual o utilizando la asistencia de MAIA.</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo organizar mis recetas en carpetas?">
						<p>
							Sí, la idea de iChef es hacerte la vida más fácil y práctica. Por eso, su sistema de permite crear y organizar
							carpetas según tus preferencias.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Para qué sirven las estrellas en las recetas?">
						<p>Las estrellas se utilizan para calificar las recetas.</p>
					</Collapsible>
					<Collapsible trigger="¿Las recetas cuentan con información nutricional?">
						<p>
							Sí, todas las recetas están verificadas y comprobadas, e incorporan información nutricional indispensable para
							que puedas elegir la que mejor se adapte a tus necesidades.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Las recetas están clasificadas por dificultad de preparación?">
						<p>Sí, todas las recetas tienen un indicador de la dificultad de preparación.</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Porciones">
					<Collapsible trigger="¿Para cuántas personas son las porciones?">
						<p>Las recetas están diseñadas para servir de 4 a 5 personas.</p>
					</Collapsible>
					<Collapsible trigger="¿Se pueden duplicar las porciones de las recetas?">
						<p>Sí, siempre y cuando no exceda la capacidad del vaso (que es de 2.2 litros).</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo ajustar el tamaño de las porciones en las recetas?">
						<p>Sí, podés hacerlo utilizando MAIA o de forma manual.</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Ingredientes">
					<Collapsible trigger="¿Puedo reemplazar ingredientes en las recetas?">
						<p>
							¡Claro! Podés reemplazar, quitar o agregar ingredientes según necesites (todo dependerá de la receta y de tu
							conocimiento sobre los ingredientes en cuestión).
						</p>
					</Collapsible>
					<Collapsible trigger="¿Cuál es el tamaño máximo de los ingredientes a colocar en el vaso?">
						<p>No deben superar la abertura de la taza medidora.</p>
					</Collapsible>
					<Collapsible trigger="¿Qué sucede si omito pasos o ingredientes en las recetas?">
						<p>
							La mejor manera de asegurar que una receta te salga perfecta es seguir el paso a paso concreto. Pero iChef te
							permite crear recetas con total libertad, permitiéndote saltarte pasos o ingredientes. ¡Solo que aquí la calidad
							del resultado dependerá de los cambios que hayas hecho! :)
						</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Buscar Recetas">
					<Collapsible trigger="¿Cómo puedo buscar recetas?">
						<p>
							En el Portal de Recetas, podrás buscar las recetas por nombre o por ingredientes, utilizando la función de
							búsqueda o aplicando filtros para refinar tus resultados.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo filtrar las recetas por nombre o ingredientes?">
						<p>Sí, el sistema de iChef te permite filtrar por recetas específicas o ingredientes.</p>
					</Collapsible>
					<Collapsible trigger="¿Por qué no veo opciones al buscar recetas?">
						<p>
							Es posible que no veas recetas porque tengas activados algunos filtros. Probá limpiando todos los filtros y
							empezando la búsqueda de nuevo.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo combinar varios filtros en la búsqueda de recetas?">
						<p>Sí, podés combinar diferentes filtros según tus necesidades para encontrar la receta perfecta.</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo ver recetas de otros usuarios?">
						<p>
							Sí, si otros usuarios hicieron sus recetas públicas y fueron validadas, están disponibles para toda la
							comunidad.
						</p>
					</Collapsible>
				</Collapsible>

				<Collapsible contentOuterClassName="main-title" trigger="Uso Avanzado">
					<Collapsible trigger="¿Cómo publico una receta de mi autoría?">
						<p>
							Es muy fácil: primero ingresá a "crear recetas", y allí subí la receta paso a paso. La receta pasará por un
							proceso de auditoría, y luego se hace pública. Lo recomendable es que la receta esté previamente validada.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Cómo puedo crear una variante de una receta ya publicada?">
						<p>Simplemente seleccioná "crear variantes" o "crear variante asistida".</p>
					</Collapsible>
					<Collapsible trigger="¿Puedo convertir recetas convecionales en recetas para iChef?">
						<p>
							Sí, podés adaptar recetas manualmente o usar nuestro asistente MAIA para adaptar cualquier receta de forma
							automática.
						</p>
					</Collapsible>
					<Collapsible trigger="¿Cómo puedo agregar referidos?">
						<p>Es muy fácil: desde el portal, accedé a la sección de herramientas, y luego a referidos para agregarlos.</p>
					</Collapsible>
				</Collapsible>
			</div>
		</div>
	);
};

export default PreguntasFrecuentes;
