import {
	USER_LOGIN,
	USER_LOGOUT,
	UPDATE_FOLDERS,
	UPDATE_WATCH_LATER,
	UPDATE_FAVORITES,
	REMOVE_FAVORITES,
	UPDATE_LIKES,
	REMOVE_LIKES,
} from './authActionTypes'

function authReducer(state = {}, action) {
	switch (action.type) {
		case USER_LOGIN:
			return { ...state, userData: action.payload }
		case USER_LOGOUT:
			return {}
		case UPDATE_FOLDERS:
			return { ...state, folders: action.payload }
		case UPDATE_WATCH_LATER:
			return { ...state, watchLater: action.payload }
		case UPDATE_FAVORITES:
			return { ...state, favorites: action.payload }
		case REMOVE_FAVORITES:
			return { ...state, favorites: null }
		case UPDATE_LIKES:
			return { ...state, likes: action.payload }
		case REMOVE_LIKES:
			return { ...state, likes: null }
		default:
			return state
	}
}

export default authReducer
