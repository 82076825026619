import React from 'react'
import Icon from '../Icon'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import moment from 'moment'
import { useTranslation } from "react-i18next";

function ModIcons({ updatedData, tipoDeVista, updateField, _dificultad = [] }) {
	const { t } = useTranslation();
	const { idDificultad = 1, tiempoChef = 0, tiempoPreparacion = 0, porciones = 0 } = updatedData

	const IconDif = ['IcoFac1', 'IcoFac1', 'IcoFac2', 'IcoFac3']

	const updateTimeField = (fieldName, o) => {
		const value = moment(o).diff(moment(o).startOf('day'), 'seconds')
		updateField(fieldName, { target: { value } })
	}

	function formatTime(time) {
		if (!time) return '-- min'
		const hours = Math.floor(time / 3600)
		const minutes = Math.floor((time % 3600) / 60)
		const seconds = Math.floor(time % 60)

		if (hours > 0) {
			if (minutes > 0) return `${hours} h ${minutes} min`
			else return `${hours} h`
		} else if (seconds > 0) return `${minutes} min ${seconds} seg`
		return `${minutes} min`
	}

	const sectionIconInfo = {
		dificultad: {
			icon: IconDif[idDificultad],
			value: (_dificultad.length &&
					_dificultad.find(({ idDificultad: dificultad }) => dificultad === parseInt(idDificultad)).nivel) || 0,
			editor: (
				<select value={idDificultad} onChange={o => updateField('idDificultad', o)}>
					{_dificultad.map(({ idDificultad: dificultad, nivel }) => {
						return (
							<option key={nivel} value={dificultad}>
								{t([`difficulty.${nivel}`, nivel])}
							</option>
						)
					})}
				</select>
			),
		},
		tiempo_chef: {
			icon: 'TiempoChef',
			value: tiempoChef ? formatTime(tiempoChef) : '--',

			editor: (
				<TimePicker
					showHour={true}
					showSecond={false}
					defaultValue={moment().startOf('day')}
					value={moment()
						.startOf('day')
						.add(tiempoChef || 0, 'seconds')}
					className="time-ctrl"
					hideDisabledOptions
					onChange={o => updateTimeField('tiempoChef', o)}
				/>
			),
			info: '',
		},
		tiempo_prep: {
			icon: 'TiempoPreparacion',
			value: tiempoPreparacion ? formatTime(tiempoPreparacion) : '--',
			editor: (
				<TimePicker
					showHour={true}
					showSecond={false}
					defaultValue={moment().startOf('day')}
					value={moment()
						.startOf('day')
						.add(tiempoPreparacion || 0, 'seconds')}
					className="time-ctrl"
					hideDisabledOptions
					onChange={o => updateTimeField('tiempoPreparacion', o)}
				/>
			),
			info: '',
		},
		porciones: {
			icon: 'Porciones',
			value: porciones || '--',
			editor: (
				<input
					value={porciones}
					className="porciones-fld"
					style={{ padding: 0 }}
					type="number"
					min="0"
					onChange={(o) => updateField('porciones', o)}
					data-tooltip-id="recipe-ia-tooltip"
					data-tooltip-place="top"
					data-tooltip-content="Modificar la cantidad de porciones"
				/>
			),
			info: t("servings")
		},
	}

	return (
		<div className="i-box">
			{Object.keys(sectionIconInfo).map((k) => {
				const { icon, value, info = '', editor } = sectionIconInfo[k]
				return (
					<div key={k}>
						<i>
							<Icon className="i-box-img" type={icon} />
						</i>
						<div className="d-flex">
							{tipoDeVista === 'vista-diseno' ? <div>{editor}</div> : <div>{value}</div>}{' '}
							{info && <div>{tipoDeVista === 'vista-diseno' ? info.substr(0, 3) : info}</div>}
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default ModIcons
