import React from 'react'

const BoxDragAndDrop = ({ img, value, isDragging,onImageUpdate,onImageUpload,haveImg, dragProps }) => {
	const src = (img && img.dataURL) || value

	return (
		<div onClick={function(){if(haveImg){ onImageUpdate(0);}else{onImageUpload();}}} {...dragProps} className={`container-dnd ${isDragging ? 'isDragging' : ''}`}>
			<img className="image-selected" src={src} alt="" />
			<span className="label-dnd">Click para seleccionar o arrastre una imagen</span>
		</div>
	)
}

export default BoxDragAndDrop
