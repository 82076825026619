import Section from './Section'

const Article = (props) => {
	const { field, content, tipoDeVista, updateField, ...rest } = props

	if (!content && tipoDeVista !== 'vista-diseno') return null
	return (
		<Section {...rest}>
			{tipoDeVista === 'vista-diseno' ? (
				<textarea
					placeholder="..."
					className="mini-fld"
					value={content ?? ''}
					onChange={(o) => updateField(field, o)}
					rows={6}
				/>
			) : (
				<article>
					<p>{content}</p>
				</article>
			)}
		</Section>
	)
}

export default Article
