import { useState, useEffect } from "react";
import { useAuthProvider } from "../providers/auth";
import { useDataProvider } from "../providers/data";
import ToggleButton from "./ToggleButton";

function UnitSelector() {
	const dataProvider = useDataProvider();
	const authProvider = useAuthProvider();
	const userData = authProvider.getIdentity();
	const isAuthenticated = authProvider.checkAuth();

	const unitOptions = [
		{
			label: "°C, gr",
			value: "metric"
		},
		{
			label: "°F, oz",
			value: "imperial"
		}
	];

	const [selectedIndex, setSelectedIndex] = useState(0);
	useEffect(() => {
		setSelectedIndex(userData.useImperial ? 1 : 0);
	}, [userData.useImperial]);

	async function onChangeUnit(value) {
		const useImperial = value === "imperial";
		authProvider.updateIdentity({ ...userData, useImperial: useImperial });
		if (isAuthenticated) {
			await dataProvider.put(`/usuarios/unit-system?imperial=${useImperial}`, {
				data: {},
				headers: {
					Authorization: userData.jwt
				}
			});
		}
	}

	return <ToggleButton options={unitOptions} selected={selectedIndex} onSwitch={onChangeUnit} />;
}

export default UnitSelector;
