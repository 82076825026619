import React from "react";
import { useAuthProvider } from "../providers/auth";
import { useTranslation } from "react-i18next";

function CookingStepsText({ steps }) {
	const { t } = useTranslation();
	const authProvider = useAuthProvider();
	const userData = authProvider.getIdentity()
	//const isAuthenticated = authProvider.checkAuth();

	function splitTime(time_sec) {
		const hours = Math.floor(time_sec / 3600);
		const minutes = Math.floor((time_sec % 3600) / 60);
		const seconds = Math.floor(time_sec % 60);
		return { hours, minutes, seconds };
	}

	function getStringTime(hours, minutes, seconds) {
		let time = "";
		if (hours > 0 && seconds > 0) time = `${hours} ${t("hours")} ${minutes} ${t("minutes")} ${seconds} ${t("seconds")}`;
		else if (hours > 0 && minutes > 0) time = `${hours} ${t("hours")} ${t("and")} ${minutes} ${t("minutes")}`;
		else if (hours > 0) time = `${hours} ${t("hours")}`;
		else if (minutes > 0 && seconds > 0) time = `${minutes} ${t("minutes")} ${t("and")} ${seconds} ${t("seconds")}`;
		else if (minutes > 0) time = `${minutes} ${t("minutes")}`;
		else if (seconds > 0) time = `${seconds} ${t("seconds")}`;
		return time;
	}

	function stepsToPreparation(steps) {
		steps = steps.sort((a, b) => a.numeroPaso - b.numeroPaso);
		const terminators = new RegExp("[^.!:]$");
		let preparation = [];
		let step_number = 1;
		let title = "";
		let paragraph = "";
		for (const step of steps) {
			// Subtitulo o reservar
			if (step.idOperacion === 9 || step.idOperacion === 13) {
				// Commit previous step
				if (paragraph !== "") {
					preparation.push({
						numeroPaso: step_number,
						titulo: title,
						parrafo: paragraph
					});
					step_number++;
					title = "";
					paragraph = "";
				}
				if (step.idOperacion === 9) {
					title = step.descripcion;
				} else if (step.descripcion && step.descripcion.trim() !== "") {
					paragraph += step.descripcion.trim();
					if (terminators.test(paragraph)) paragraph += ".";
					paragraph += " ";
				}
			} else {
				const time = splitTime(step.tiempo);
				if (step.descripcion && step.descripcion.trim() !== "") {
					paragraph += step.descripcion.trim();
					if (terminators.test(paragraph)) paragraph += ".";
					paragraph += " ";
				}
				switch (step.idOperacion) {
					case 2:
						preparation.push({
							numeroPaso: step_number,
							titulo: title,
							parrafo: `${paragraph} ${t("steam_cooking")} ${t("at_a_speed_of")} ${step.nivelVelocidad} ${t(
								"for"
							)} ${getStringTime(time.hours, time.minutes, time.seconds)}.`
						});
						step_number++;
						title = "";
						paragraph = "";
						break;
					case 3:
						preparation.push({
							numeroPaso: step_number,
							titulo: title,
							parrafo: `${paragraph} ${t("knead")} ${t("for")} ${getStringTime(time.hours, time.minutes, time.seconds)}.`
						});
						step_number++;
						title = "";
						paragraph = "";
						break;
					case 5:
						preparation.push({
							numeroPaso: step_number,
							titulo: title,
							parrafo: `${paragraph} ${t("cook_at")} ${step.temperatura} ${userData.useImperial ? "°F" : "°C"} ${t("at_a_speed_of")} ${step.nivelVelocidad} (${
								step.idModoVelocidad === 1 ? t("reverse") : t("forward")
							}) ${t("for")} ${getStringTime(time.hours, time.minutes, time.seconds)}.`
						});
						step_number++;
						title = "";
						paragraph = "";
						break;
					case 8:
						preparation.push({
							numeroPaso: step_number,
							titulo: title,
							parrafo: `${paragraph} ${t("process")} ${step.nivelVelocidad} (${
								step.idModoVelocidad === 1 ? t("reverse") : t("forward")
							}) ${t("for")} ${getStringTime(time.hours, time.minutes, time.seconds)}.`
						});
						step_number++;
						title = "";
						paragraph = "";
						break;
					default:
						break;
				}
			}
		}
		// Commit last step
		if (paragraph !== "") {
			preparation.push({
				numeroPaso: step_number,
				titulo: title,
				parrafo: paragraph
			});
		}
		return preparation;
	}
/*
	function PayWall(text) {
		const showLength = 100;
		if (isAuthenticated || text.length <= showLength) return text;
		return text.substr(0, showLength).trim() + "...";
	}
*/
	const preparation = stepsToPreparation(steps);

	return (
		<article>
			{preparation && Array.isArray(preparation)
				? preparation.map((paso, index) => {
						return (
							<section key={index}>
								<h5>
									{t("step")} {paso.numeroPaso}
								</h5>
								{paso.titulo && <h6>{paso.titulo}</h6>}
								<p>{paso.parrafo}</p>
							</section>
						);
				  })
				: "Vacio"}
		</article>
	);
}

export default CookingStepsText;
