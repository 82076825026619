import { useContext, useMemo } from 'react'

import authProviderContext from './authProviderContext'

export const useAuthProvider = () => {
	const authProvider = useContext(authProviderContext)

	const authProviderProxy = useMemo(() => {
		return authProvider
	}, [authProvider])

	return authProviderProxy
}
