class GeneralServicesProvider {
	constructor(bodyTagValues = {}, store = null) {
		this.bodyTagValues = bodyTagValues
		this.store = store
		this.isEnabledLocalStore = store ? true : false

		this.body = document.getElementById('body')
	}

	getClassName() {
		return this.body.className
	}

	setClassName(className) {
		this.body.className = className
	}

	setfilterActive(active) {
		this.bodyTagValues['filterActive'] = active
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	isFilterActivated() {
		return this.bodyTagValues['filterActive']
	}

	filterOn() {
		this.bodyTagValues['filterActive'] = true

		this.body?.classList?.add(this.bodyTagValues['filterActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	filterOff() {
		this.bodyTagValues['filterActive'] = false

		this.body?.classList?.remove(this.bodyTagValues['filterActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	filterToggle() {
		this.bodyTagValues['filterActive'] = this.body?.classList?.toggle(this.bodyTagValues['filterActiveClass'])
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setFoldersActive(active) {
		this.bodyTagValues['foldersActive'] = active
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	isFoldersActivated() {
		return this.bodyTagValues['foldersActive']
	}

	foldersOn() {
		this.bodyTagValues['foldersActive'] = true

		this.body?.classList?.add(this.bodyTagValues['foldersActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	foldersOff() {
		this.bodyTagValues['foldersActive'] = false

		this.body?.classList?.remove(this.bodyTagValues['foldersActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	foldersToggle() {
		this.bodyTagValues['foldersActive'] = this.body?.classList?.toggle(this.bodyTagValues['foldersActiveClass'])
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setLoginLightBoxActive(active) {
		this.bodyTagValues['LoginLightBoxActive'] = active
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	isLoginLightBoxActivated() {
		return this.bodyTagValues['LoginLightBoxActive']
	}

	LoginLightBoxOn() {
		this.bodyTagValues['LoginLightBoxActive'] = true

		this.body?.classList?.add(this.bodyTagValues['LoginLightBoxActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	LoginLightBoxOff() {
		this.body?.classList?.remove(this.bodyTagValues['LoginLightBoxActiveClass'])

		this.bodyTagValues['LoginLightBoxActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	LoginLightBoxToggle() {
		this.bodyTagValues['LoginLightBoxActive'] = this.body?.classList?.toggle(
			this.bodyTagValues['LoginLightBoxActiveClass']
		)
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setFolderFormLightBoxData(data) {
		this.bodyTagValues['folderFormLightBoxData'] = data
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	getFolderFormLightBoxData() {
		return this.bodyTagValues['folderFormLightBoxData']
	}

	isFolderFormLightBoxActivated() {
		return this.bodyTagValues['folderFormLightBoxActive']
	}

	folderFormLightBoxOn() {
		this.bodyTagValues['folderFormLightBoxActive'] = true

		this.body?.classList?.add(this.bodyTagValues['folderFormLightBoxActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	folderFormLightBoxOff() {
		this.body?.classList?.remove(this.bodyTagValues['folderFormLightBoxActiveClass'])

		this.bodyTagValues['folderFormLightBoxActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setFoldersToRecipeLightBoxData(data) {
		this.bodyTagValues['foldersToRecipeLightBoxData'] = data
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	getFoldersToRecipeLightBoxData() {
		return this.bodyTagValues['foldersToRecipeLightBoxData']
	}

	isFoldersToRecipeLightBoxActivated() {
		return this.bodyTagValues['foldersToRecipeLightBoxActive']
	}

	foldersToRecipeLightBoxOn() {
		this.bodyTagValues['foldersToRecipeLightBoxActive'] = true

		this.body?.classList?.add(this.bodyTagValues['foldersToRecipeLightBoxActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	foldersToRecipeLightBoxOff() {
		this.body?.classList?.remove(this.bodyTagValues['foldersToRecipeLightBoxActiveClass'])

		this.bodyTagValues['foldersToRecipeLightBoxActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setFolderSharingFormLightBoxData(data) {
		this.bodyTagValues['folderSharingFormLightBoxData'] = data
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	getFolderSharingFormLightBoxData() {
		return this.bodyTagValues['folderSharingFormLightBoxData']
	}

	isFolderSharingFormLightBoxActivated() {
		return this.bodyTagValues['folderSharingFormLightBoxActive']
	}

	folderSharingFormLightBoxOn() {
		this.bodyTagValues['folderSharingFormLightBoxActive'] = true

		this.body?.classList?.add(this.bodyTagValues['folderSharingFormLightBoxActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	folderSharingFormLightBoxOff() {
		this.body?.classList?.remove(this.bodyTagValues['folderSharingFormLightBoxActiveClass'])

		this.bodyTagValues['folderSharingFormLightBoxActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setFolderDeletingFormLightBoxData(data) {
		this.bodyTagValues['folderDeletingFormLightBoxData'] = data
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	getFolderDeletingFormLightBoxData() {
		return this.bodyTagValues['folderDeletingFormLightBoxData']
	}

	isFolderDeletingFormLightBoxActivated() {
		return this.bodyTagValues['folderDeletingFormLightBoxActive']
	}

	folderDeletingFormLightBoxOn() {
		this.bodyTagValues['folderDeletingFormLightBoxActive'] = true

		this.body?.classList?.add(this.bodyTagValues['folderDeletingFormLightBoxActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	folderDeletingFormLightBoxOff() {
		this.body?.classList?.remove(this.bodyTagValues['folderDeletingFormLightBoxActiveClass'])

		this.bodyTagValues['folderDeletingFormLightBoxActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setRecoverPasswordActive(active) {
		this.bodyTagValues['RecoverPasswordActive'] = active
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	isRecoverPasswordActivated() {
		return this.bodyTagValues['RecoverPasswordActive']
	}

	RecoverPasswordOn() {
		this.bodyTagValues['RecoverPasswordActive'] = true

		this.body?.classList?.add(this.bodyTagValues['RecoverPasswordActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	RecoverPasswordOff() {
		this.body?.classList?.remove(this.bodyTagValues['RecoverPasswordActiveClass'])

		this.bodyTagValues['RecoverPasswordActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	RecoverPasswordToggle() {
		this.bodyTagValues['RecoverPasswordActive'] = this.body?.classList?.toggle(
			this.bodyTagValues['RecoverPasswordActiveClass']
		)
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setSearchInputActive(active) {
		this.bodyTagValues['searchInputActive'] = active

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	isSearchInputActivated() {
		return this.bodyTagValues['searchInputActive']
	}

	searchInputOn() {
		this.bodyTagValues['searchInputActive'] = true

		this.body?.classList?.add(this.bodyTagValues['searchInputActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	searchInputOff() {
		this.bodyTagValues['searchInputActive'] = false

		this.body?.classList?.remove(this.bodyTagValues['searchInputActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	searchInputToggle() {
		this.bodyTagValues['searchInputActive'] = this.body?.classList?.toggle(
			this.bodyTagValues['searchInputActiveClass']
		)
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	setMenuActive(active) {
		this.bodyTagValues['menuActive'] = active
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	isMenuActivated() {
		return this.bodyTagValues['menuActive']
	}

	menuOn() {
		this.bodyTagValues['menuActive'] = true

		this.body?.classList?.add(this.bodyTagValues['menuActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	menuOff() {
		this.body?.classList?.remove(this.bodyTagValues['menuActiveClass'])

		this.bodyTagValues['menuActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	menuToggle() {
		this.bodyTagValues['menuActive'] = this.body?.classList?.toggle(this.bodyTagValues['menuActiveClass'])
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	mobileOn() {
		this.bodyTagValues['mobileActive'] = true

		this.body?.classList?.add(this.bodyTagValues['mobileActiveClass'])

		/* if (this.isEnabledLocalStore) {
				this.store.updateServices({ ...this.bodyTagValues })
		} */
	}

	isMobileActivated() {
		this.bodyTagValues['mobileActive'] = window.matchMedia('(max-width: 660px)').matches
		/* if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		} */

		return this.bodyTagValues['mobileActive']
	}

	setScrollDownActive(active) {
		this.bodyTagValues['scrollDownActive'] = active
	}

	isScrollDownActivated() {
		return this.bodyTagValues['scrollDownActive']
	}

	scrollDownOn() {
		this.bodyTagValues['scrollDownActive'] = true

		this.body?.classList?.add(this.bodyTagValues['scrollDownActiveClass'])
	}

	scrollDownOff() {
		this.body?.classList?.remove(this.bodyTagValues['scrollDownActiveClass'])

		this.bodyTagValues['scrollDownActive'] = false
	}

	scrollDownToggle() {
		this.bodyTagValues['scrollDownActive'] = this.body?.classList?.toggle(
			this.bodyTagValues['scrollDownActiveClass']
		)
	}

	getScrollTop() {
		return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
	}

	setUserSessionActive(active) {
		this.bodyTagValues['userSessionActive'] = active
		/* if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		} */
	}

	isUserSessionActivated() {
		return this.bodyTagValues['userSessionActive']
	}

	userSessionOn() {
		this.bodyTagValues['userSessionActive'] = true

		this.body?.classList?.add(this.bodyTagValues['userSessionActiveClass'])

		/* if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		} */
	}

	userSessionOff() {
		this.body?.classList?.remove(this.bodyTagValues['userSessionActiveClass'])

		this.bodyTagValues['userSessionActive'] = false

		/* if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		} */
	}

	getMainOnScroll() {
		return this.bodyTagValues['mainOnScroll']
	}

	mainOnScrollEnable() {
		if (!this.bodyTagValues['mainOnScroll']) {
			this.bodyTagValues['mainOnScroll'] = true
		}
	}

	mainOnScrollDisable() {
		this.bodyTagValues['mainOnScroll'] = false
	}

	setAdvertiseLightBoxData(data) {
		this.bodyTagValues['AdvertiseLightBoxData'] = data
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	getAdvertiseLightBoxData() {
		return this.bodyTagValues['AdvertiseLightBoxData']
	}

	isAdvertiseLightBoxActivated() {
		return this.bodyTagValues['AdvertiseLightBoxActive']
	}

	AdvertiseLightBoxOn() {
		this.bodyTagValues['AdvertiseLightBoxActive'] = true

		this.body?.classList?.add(this.bodyTagValues['AdvertiseLightBoxActiveClass'])

		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}

	AdvertiseLightBoxOff() {
		this.body?.classList?.remove(this.bodyTagValues['AdvertiseLightBoxActiveClass'])

		this.bodyTagValues['AdvertiseLightBoxActive'] = false
		if (this.isEnabledLocalStore) {
			this.store.updateServices({ ...this.bodyTagValues })
		}
	}
}

export default GeneralServicesProvider
