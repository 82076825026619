export const PRIVILEGIOS = {
	CREAR_RECETA: 'ichef:receta:create',
	CREAR_RECETA_IA: 'ichef:receta:create:ai',
	EDITAR_PROPIAS: 'ichef:receta:update:own',
	CARGAR_A_ROBOT: 'ichef:receta:upload',
	CREAR_VARIANTE: 'ichef:receta:variants:create',
	CAMBIAR_AJUSTES: 'ichef:user:settings',
	CLONAR_RECETA:'ichef:receta:clone',
	EDITAR_SCORE: 'ichef:recetas:score:edit',
	PUBLICAR_RECETA: 'ichef:receta:publish',
	EDITAR_TODAS: 'ichef:receta:update:all',
	EDITAR_CARPETAS_PUBLICAS:'ichef:carpeta:public:edit',
	ELIMINAR_RESENAS:"ichef:comment:delete"
}

export const DIFFICULTY_LEVELS = {
	EASY: 1,
	INTERMEDIATE: 2,
	HARD: 3,
}

export const RECIPE_STATUS = {
	PUBLIC: 1,
	PRIVATE: 2,
	IN_REVIEW: 3,
	ELIMINATED: 4,
}

export const STATUS_SERVER = {
	SUCCESS: 1,
	ERROR: 2,
}

export const FORMTYPE = {
	LOGIN: 1,
	PASSWORD_RESET_REQUEST: 2,
	TOKEN_VALIDATION: 3,
	CHANGE_PASSWORD: 4,
	SIGNUP: 5,
	ACTIVATE_ACCOUNT: 6,
	RESEND_ACTIVATE_TOKEN: 7
}

export const tienePermisoDe = (_perm, userData) => {
	const { permisos = [] } = userData
	const _permisos = typeof _perm === 'object' ? _perm : [_perm]
	return _permisos.reduce((ret, v) => {
		return ret || permisos.includes(v)
	}, false)
}

export const newPosition = (o1, c1, sg = '') => {
	const top1 = o1.getBoundingClientRect().top + document.documentElement.scrollTop

	c1.className = 'inserted'
	c1.style.position = 'absolute'
	c1.style.top = top1 + 'px'
	c1.style.left = o1.getBoundingClientRect().left + 'px'
	c1.style.width = o1.getBoundingClientRect().width + 'px'
	c1.style.height = o1.getBoundingClientRect().height + 'px'
	document.getElementById('body').appendChild(c1)
	return sg + o1.getBoundingClientRect().height + 'px'
}

export const swapEffect = ([p1, p2], fn, who = 'paso') => {
	const o1 = document.querySelector(`section[${who}='${p1}']`)
	const o2 = document.querySelector(`section[${who}='${p2}']`)
	const c1 = o1.cloneNode()
	const c2 = o2.cloneNode()
	const t1 = newPosition(o1, c1, p1 > p2 ? '-' : '')
	const t2 = newPosition(o2, c2, p1 > p2 ? '' : '-')
	o1.style.visibility = 'hidden'
	o2.style.visibility = 'hidden'
	c1.style.marginTop = t1
	c2.style.marginTop = t2
	setTimeout(() => {
		o1.style.visibility = ''
		o2.style.visibility = ''
		c1.parentNode.removeChild(c1)
		c2.parentNode.removeChild(c2)
		fn()
	}, 350)
}
