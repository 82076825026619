import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { queryClientApp } from './queryClientApp'

import { AuthLocalStore, authProviderContext, AuthProvider, authActions } from './providers/auth'
import {
	DataProviderContext,
	defaultDataProvider,
	defaultFetch,
	SearchEngineDataProvider,
	LocalAPIDataProvider,
} from './providers/data'
import { FilterLocalStore, FilterProviderContext, FilterProvider, filtersActions } from './providers/filter'
import { GeneralServicesProvider, ServiceLocalStore, serviceProviderContext, serviceActions } from './providers/service'

const API_PATH = process.env.REACT_APP_API_PATH
const port = process.env.REACT_APP_NODE_SERVER_PORT
const LOCAL_API_PATH =
	typeof process.env.REACT_APP_VERCEL_URL !== 'undefined'
		? process.env.REACT_APP_VERCEL_URL
		: `http://localhost:${port}`

export const AppCore = (props) => {
	const { children } = props
	const [auths, setAuths] = useState({})
	const [filters, setFilters] = useState({})
	const [services, setBodyTagValues] = useState({
		filterActiveClass: `visible-filtro`,

		foldersActiveClass: `visible-carpetas`,

		folderFormLightBoxActive: false,
		folderFormLightBoxActiveClass: `visible-form-carpeta`,
		folderFormLightBoxData: {},

		AdvertiseLightBoxActive: false,
		AdvertiseLightBoxActiveClass: `visible-advertise`,
		AdvertiseLightBoxData: {},

		foldersToRecipeLightBoxActive: false,
		foldersToRecipeLightBoxActiveClass: `visible-asignar-carpetas`,
		foldersToRecipeLightBoxData: {},

		folderSharingFormLightBoxActive: false,
		folderSharingFormLightBoxActiveClass: `visible-compartir-carpeta`,
		folderSharingFormLightBoxData: {},

		folderDeletingFormLightBoxActive: false,
		folderDeletingFormLightBoxActiveClass: `visible-eliminar-carpeta`,
		folderDeletingFormLightBoxData: {},

		LoginLightBoxActive: false,
		LoginLightBoxActiveClass: `visible-sesion`,

		RecoverPasswordActive: false,
		RecoverPasswordActiveClass: `visible-recuperar-contra`,

		searchInputActive: false,
		searchInputActiveClass: `visible-buscador`,

		menuActive: false,
		menuActiveClass: `visible-menu`,

		mobileActive: false,
		mobileActiveClass: `mobile`,

		userSessionActive: false,
		userSessionActiveClass: `sesion-activa`,

		scrollDownActive: false,
		scrollDownActiveClass: `scroll-down`,

		mainOnScroll: false,
	})

	const dispatch = useDispatch()

	//controlador de autentificación
	const authState = useSelector((state) => state.auth)

	useEffect(() => {
		setAuths(authState)
	}, [authState])

	const authProvider = useMemo(
		() => new AuthProvider(LOCAL_API_PATH, auths, new AuthLocalStore(authState, authActions, dispatch)),
		[auths, authState, dispatch]
	)

	//controlador de filtros
	const filtersState = useSelector((state) => state.filters)

	useEffect(() => {
		setFilters(filtersState)
	}, [filtersState])

	const filterProvider = useMemo(
		() => new FilterProvider(filters, new FilterLocalStore(filtersState, filtersActions, dispatch)),
		[filters, filtersState, dispatch]
	)

	//controlador de servicios
	const serviceState = useSelector((state) => state.services)

	const bodyTagService = useMemo(
		() => new GeneralServicesProvider(services, new ServiceLocalStore(serviceState, serviceActions, dispatch)),
		[services, serviceState, dispatch]
	)

	useEffect(() => {
		setBodyTagValues(serviceState)
	}, [serviceState])

	useEffect(() => {
		dispatch(serviceActions.updateServices(services))
	}, [dispatch, services])

	// const [mainOnScrollTimeoutId, setMainOnScrollTimeoutId] = useState(null)

	useEffect(() => {
		const handleScroll = () => {
			bodyTagService.mainOnScrollEnable()

			if (timeoutId !== null) {
				clearTimeout(timeoutId)
			}

			timeoutId = setTimeout(() => {
				bodyTagService.mainOnScrollDisable()
			}, 450)

			// setMainOnScrollTimeoutId(timeoutId)

			// console.log('setTimeout > ', timeoutId)
		}

		var timeoutId = null
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
			bodyTagService.mainOnScrollDisable()
		}
	}, [bodyTagService])

	const persister = createSyncStoragePersister({
		storage: window.localStorage,
	})

	return (
		<PersistQueryClientProvider client={queryClientApp} persistOptions={{ persister }}>
			<authProviderContext.Provider value={authProvider}>
				<DataProviderContext.Provider
					value={{
						default: defaultDataProvider(API_PATH, defaultFetch),
						searchEngine: SearchEngineDataProvider(LOCAL_API_PATH),
						localApi: LocalAPIDataProvider(LOCAL_API_PATH),
					}}
				>
					<FilterProviderContext.Provider value={filterProvider}>
						<serviceProviderContext.Provider value={bodyTagService}>
							{children}
						</serviceProviderContext.Provider>
					</FilterProviderContext.Provider>
				</DataProviderContext.Provider>
			</authProviderContext.Provider>
			<ReactQueryDevtools initialIsOpen={false} position="bottom-left" panelPosition="bottom" />
		</PersistQueryClientProvider>
	)
}
