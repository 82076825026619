export class FilterLocalStore {
	constructor(filtersState, filtersActions, dispatch) {
		this.filtersState = filtersState
		this.dispatch = dispatch
		this.filtersActions = filtersActions
	}

	updateFilters(filters) {
		this.dispatch(this.filtersActions.updateFilters(filters))
	}

	getFilter(name) {
		return this.filtersState[name]
	}

	getFilters() {
		return this.filtersState
	}
}
