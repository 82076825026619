import { BeatLoader } from "react-spinners";
import { defaultDataProvider } from "../providers/data";
import { STATUS_SERVER } from "../providers/utils/functions";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

const api_path = process.env.REACT_APP_VERCEL_URL;

const FinalizarRegistro = () => {
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const token = searchParams.get("token");

	const dataProvider = defaultDataProvider(api_path);

	const [serverPasswordMsg, setServerPasswordMsg] = useState({ status: null, msg: "", show: false });
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const {
		isLoading,
		isError,
		error,
		data: userProfile
	} = useQuery(["api/users/profile"], async () => {
		const { data: response } = await dataProvider.getOne("api/users/profile", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${token}`
			}
		});
		return response.data;
	});

	const handlePasswordForm = event => {
		event.preventDefault();
		setServerPasswordMsg({ status: null, msg: "", show: false });

		if (password !== confirmPassword) {
			setServerPasswordMsg({
				status: STATUS_SERVER.ERROR,
				msg: "Las contraseñas no coinciden.",
				show: true
			});
			return;
		}

		dataProvider
			.update("api/users/profile/change-password", {
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${token}`
				},
				data: {
					idUsuario: userProfile.idUsuario,
					pwd: "",
					newPwd: password
				}
			})
			.then(response => {
				setServerPasswordMsg({
					status: STATUS_SERVER.SUCCESS,
					msg: "Contraseña actualizada con éxito.",
					show: true
				});
			})
			.catch(error => {
				setServerPasswordMsg({
					status: STATUS_SERVER.ERROR,
					msg: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
					show: true
				});
			});
	};

	return (
		<div className="cyg-row">
			<div className="container not-logged">
				<h1 className="not-logged-title">¡Gracias por registrarte en iChef!</h1>
				<p className="not-logged-subtitle">Para finalizar el registro es necesario que ingreses una contraseña.</p>
				<p className="not-logged-subtitle">Luego podras iniciar sesión con tu nombre de usuario y contraseña.</p>
			</div>
			<div className="container not-logged">
				{!isLoading && !isError ? (
					<div>
						<label className="usuario-herr">
							Usuario: <span>{userProfile.username}</span>
						</label>
						<form action="" className="form-ichef herramientas" autoComplete="off" onSubmit={handlePasswordForm}>
							<label className="usuario-herr">Registro de contraseña</label>
							<input
								type="password"
								name="newPwd"
								placeholder="Ingresar nueva contraseña"
								value={password}
								onChange={e => setPassword(e.target.value)}
								autoComplete="off"
							/>
							<input
								type="password"
								name="newPwd2"
								placeholder="Confirmar contraseña"
								value={confirmPassword}
								onChange={e => setConfirmPassword(e.target.value)}
								autoComplete="off"
							/>
							<button className="transition" name="">
								Confirmar
							</button>
							{serverPasswordMsg && serverPasswordMsg.show ? (
								<div
									className={`msj ${
										serverPasswordMsg.status === STATUS_SERVER.ERROR
											? "error"
											: serverPasswordMsg.status === STATUS_SERVER.SUCCESS
											? "ok"
											: ""
									}`}
								>
									{serverPasswordMsg.msg}
								</div>
							) : (
								<></>
							)}
						</form>
					</div>
				) : isError ? (
					<p>{error}</p>
				) : (
					<BeatLoader
						color={"#509F2C"}
						loading={true}
						cssOverride={{
							display: "block",
							margin: "0 auto",
							borderColor: "#509F2C"
						}}
						size={10}
					/>
				)}
			</div>
		</div>
	);
};

export default FinalizarRegistro;
