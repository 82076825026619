/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import RecipeItem from '../components/RecipeItem'
import BeatLoader from 'react-spinners/BeatLoader'
import { useDataProvider } from '../providers/data'
import { useAuthProvider } from '../providers/auth'
import FiltersSideBar from '../components/FiltersSideBar'
import { useServiceProvider } from '../providers/service'
import { useNavigate } from 'react-router-dom'
import FoldersSidebar from '../components/folders/FoldersSidebar'
import FolderFormLightbox from '../components/folders/FolderFormLightbox'
import FoldersToRecipeLightbox from '../components/folders/FoldersToRecipeLightbox'
import FolderSharingFormLightbox from '../components/folders/FolderSharingFormLightbox'
import FolderDeletingFormLightbox from '../components/folders/FolderDeletingFormLightbox'
import RecipeItemMobile from '../components/RecipeItemMobile'
import AdvertiseLightbox from '../components/login/AdvertiseLightbox'
import { useTranslation } from "react-i18next";
import './RecipeList/RecipeList.css'

const override = {
	display: 'block',
	margin: '0 auto',
	borderColor: '#509F2C',
}

function MyRecipes() {
	const { t } = useTranslation();
	const navigate = useNavigate()
	const { id: folderId, name: folderName } = useParams()

	const authProvider = useAuthProvider()
	const isAuthenticated = authProvider.checkAuth()
	const userData = authProvider.getIdentity()
	if (!isAuthenticated) {
		navigate('/')
	}

	const serviceProvider = useServiceProvider()
	const dataProvider = useDataProvider()
	const localApiDataProvider = useDataProvider('localApi')

	const {
		isLoading: favoriteRecipesIsLoading,
		isError: favoriteRecipesIsError,
		error: favoriteRecipesError,
		data: favoriteRecipesData,
	} = useQuery(
		['favoriteRecipes', {}],
		async () => {
			const { data, status } = await localApiDataProvider.getFavoriteRecipes({
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json, text/plain, */*',
					Authorization: userData.jwt,
				},
				params: {
					userId: userData.idUsuario,
				},
			})
			if (status === 200) {
				authProvider.updateFavorites(data.map(recipe => Number(recipe.idReceta)))
				authProvider.updateLikes(
					data.filter(recipe => recipe.likes === 1).map(recipe => Number(recipe.idReceta))
				)
			}
			return data
		},
		{ enabled: folderId === 'fav' }
	)

	const {
		isLoading: privateRecipesIsLoading,
		isError: privateRecipesIsError,
		error: privateRecipesError,
		data: privateRecipesData,
	} = useQuery(
		['privateRecipes', {}],
		async () => {
			const { data, status } = await localApiDataProvider.getPrivateRecipes({
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json, text/plain, */*',
					Authorization: userData.jwt,
				},
				params: {
					page: 1,
					perPage: 20,
					userId: userData.idUsuario,
				},
			})
			if (status === 200) {
				authProvider.updateFavorites(data.map((recipe) => Number(recipe.idReceta)))
				authProvider.updateLikes(
					data.filter((recipe) => recipe.likes === 1).map((recipe) => Number(recipe.idReceta))
				)
			}
			return data
		},
		{ enabled: folderId === 'priv' }
	)

	const [folderRecipesData, setFolderRecipesData] = useState([])
	const [folderRecipesIsLoading, setFolderRecipesIsLoading] = useState(true)

	useEffect(() => {
			async function fetchData() {
				var recipes = []
				const folders = authProvider.getFolders()
				const currentFolder = folders.find((folder) => folder.idCarpeta === Number(folderId))
				if (!currentFolder.recipes) currentFolder.recipes = []
				const { data: folderRecipes } = await dataProvider.getOne(`api/carpetas/${folderId}/recetas`, {
					headers: {
						Authorization: userData.jwt,
					},
				})
				for (const recipeData of folderRecipes.recetas) {
					recipeData.fotosReceta = recipeData.fotosReceta[0]
					recipeData.estado=recipeData.estadoReceta ?? recipeData.estadoReceta.idEstado
					recipes.push(recipeData)
				}
				setFolderRecipesData(recipes);
				setFolderRecipesIsLoading(false)
			}
		if (folderId !== 'fav' && folderId !== 'priv' && folderId !== 'later') {
			setFolderRecipesIsLoading(true)
			fetchData()
		}
	}, [folderId])

	useEffect(() => {
		// Update folder when a recipe is removed
		if (folderId !== 'fav' && folderId !== 'priv' && folderId !== 'later' && folderRecipesData.length > 0) {
			const folders = authProvider.getFolders()
			const currentFolder = folders.find((folder) => folder.idCarpeta === Number(folderId))
			const recipes = folderRecipesData.filter((recipe) => currentFolder.recipes.includes(recipe.idReceta))
			setFolderRecipesData(recipes)
		}
	}, [authProvider.getFolderUpdated()])

	const [laterRecipesData, setLaterRecipesData] = useState([])
	useEffect(() => {
		setLaterRecipesData(authProvider.getWatchLater())
	}, [authProvider.getWatchLater().length])

	const { data: difficultiesData } = useQuery(['difficulties', {}], async () => {
		const { data: difficultyLevelsData } = await dataProvider.getList(`api/dificultad/list`, {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json, text/plain, */*',
			},
		})

		let difficultyLevels = []
		difficultyLevelsData.forEach((element) => {
			difficultyLevels[element.idDificultad] = element.nivel
		})
		return difficultyLevels
	})

	const {
		isLoading: filterListIsLoading,
		isError: filterListIsError,
		error: filterListError,
		data: filterListData,
	} = useQuery(['filterList'], async () => {
		const { data } = await dataProvider.getList('api/filtros', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json, text/plain, */*',
			},
		})
		return {
			...data,
			...{
				estados: [
					{
						idEstado: 3,
						nombreEstado: 'En Revisión',
					},
					{
						idEstado: 2,
						nombreEstado: 'Privadas',
					},
					{
						idEstado: 1,
						nombreEstado: 'Públicas',
					},
				],
			},
		}
	})

	return (
		<div>
			{isAuthenticated && serviceProvider.isAdvertiseLightBoxActivated() && <AdvertiseLightbox />}
			{isAuthenticated && serviceProvider.isFolderFormLightBoxActivated() && <FolderFormLightbox />}
			{isAuthenticated && serviceProvider.isFoldersToRecipeLightBoxActivated() && <FoldersToRecipeLightbox />}
			{isAuthenticated && serviceProvider.isFolderSharingFormLightBoxActivated() && <FolderSharingFormLightbox />}
			{isAuthenticated && serviceProvider.isFolderDeletingFormLightBoxActivated() && <FolderDeletingFormLightbox />}
			{isAuthenticated && <FoldersSidebar />}
			<FiltersSideBar
				filterList={{
					isLoading: filterListIsLoading,
					isError: filterListIsError,
					error: filterListError,
					data: filterListData,
				}}
			/>

			<div className="container container-2">
				{folderId === 'fav' && (
					<div className="cyg-row" id="carpeta-1">
						<h4 className="tit-mis-recetas">{t("favorites")}</h4>
						{!favoriteRecipesIsLoading ? (
							!favoriteRecipesIsError ? (
								Array.isArray(favoriteRecipesData) &&
								(favoriteRecipesData.length > 0 ? (
										<div className="cyg-col-full d-flex grilla favoritas-lista">
											{favoriteRecipesData.map((recipe, index) =>
												serviceProvider.isMobileActivated() ? (
													<RecipeItemMobile
														key={index}
														recipeData={recipe}
														difficultiesData={difficultiesData}
													/>
												) : (
													<RecipeItem
														key={index}
														recipeData={recipe}
														difficultiesData={difficultiesData}
													/>
												)
											)}
										</div>
								) : (
										<div className="cyg-col-full d-flex grilla privadas-lista">
											<p>No hay recetas marcadas como favoritas</p>
										</div>
								))
							) : (
								<p>{favoriteRecipesError}</p>
							)
						) : (
							<div className="sweet-loading cont-receta">
								<BeatLoader
									color={'#509F2C'}
									loading={favoriteRecipesIsLoading}
									cssOverride={override}
									size={10}
								/>
							</div>
						)}
					</div>
				)}

				{folderId === 'priv' && (
					<div className="cyg-row" id="carpeta-2">
						{!privateRecipesIsLoading ? (
							!privateRecipesIsError ? (
								Array.isArray(privateRecipesData) &&
								(privateRecipesData.length > 0 ? (
									<>
										<h4 className="tit-mis-recetas fix">Privadas</h4>
										<div className="cyg-col-full d-flex grilla privadas-lista">
											{privateRecipesData.map((recipe, index) => (
												<RecipeItem
													key={index}
													recipeData={recipe}
													difficultiesData={difficultiesData}
												/>
											))}
										</div>
									</>
								) : (
									<>
										<h4 className="tit-mis-recetas fix">Privadas</h4>
										<div className="cyg-col-full d-flex grilla privadas-lista">
											<p>No hay recetas privadas</p>
										</div>
									</>
								))
							) : (
								<p>{privateRecipesError}</p>
							)
						) : (
							<div className="sweet-loading cont-receta">
								<BeatLoader
									color={'#509F2C'}
									loading={privateRecipesIsLoading}
									cssOverride={override}
									size={10}
								/>
							</div>
						)}
					</div>
				)}

				{folderId === 'later' && (
					<div className="cyg-row" id="carpeta-2">
						{Array.isArray(laterRecipesData) &&
								(laterRecipesData.length > 0 ? (
									<>
										<h4 className="tit-mis-recetas fix">Ver más tarde</h4>
										<div className="cyg-col-full d-flex grilla privadas-lista">
											{laterRecipesData.map((recipe, index) => (
												<RecipeItem
													key={index}
													recipeData={recipe}
													difficultiesData={difficultiesData}
												/>
											))}
										</div>
									</>
								) : (
									<>
										<h4 className="tit-mis-recetas fix">Ver más tarde</h4>
										<div className="cyg-col-full d-flex grilla privadas-lista">
											<p>No hay recetas marcadas para ver más tarde</p>
										</div>
									</>
								))}
					</div>
				)}

				{!isNaN(folderId) && (
					<div className="cyg-row" id="carpeta-3">
						{!folderRecipesIsLoading ? (
							Array.isArray(folderRecipesData) &&
							(folderRecipesData.length > 0 ? (
								<>
									<h4 className="tit-mis-recetas fix">{folderName}</h4>
									<div className="cyg-col-full d-flex grilla privadas-lista">
											{folderRecipesData.map((recipe, index) =>
												serviceProvider.isMobileActivated() ? (
													<RecipeItemMobile
														key={index}
														recipeData={recipe}
														difficultiesData={difficultiesData}
													/>
												) : (
													<RecipeItem
														key={index}
														recipeData={recipe}
														difficultiesData={difficultiesData}
													/>
												)
											)}
									</div>
								</>
							) : (
								<>
									<h4 className="tit-mis-recetas fix">{folderName}</h4>
									<div className="cyg-col-full d-flex grilla privadas-lista">
										<p>La carpeta no contiene recetas</p>
									</div>
								</>
							))
						) : (
							<div className="sweet-loading cont-receta">
								<BeatLoader
									color={'#509F2C'}
									loading={folderRecipesIsLoading}
									cssOverride={override}
									size={10}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default MyRecipes
