import { useState } from 'react'
import { ReactComponent as IcoUsuario } from '../../images/svg/ico-usuario.svg'
import { useAuthProvider } from '../../providers/auth'
import { FORMTYPE, STATUS_SERVER } from '../../providers/utils/functions'
import { useTranslation } from 'react-i18next'

function TokenValidationForm({ setUserFormType, inputToken, setInputToken }) {
	const { t } = useTranslation();
	//Proveedor de autenticación
	const authProvider = useAuthProvider()

	const [serverMsg, setServerMsg] = useState({ status: null, msg: '', show: false })

	const handleTokenVerification = async (e) => {
		e.preventDefault()

		authProvider
			.verifyToken(inputToken)
			.then(async (response) => {
				const { status, data } = response || { status: null, data: null }
				const { token } = data || { token: null, code: null, error: null }
				if (status === 200 && token) {
					setServerMsg({
						status: STATUS_SERVER.SUCCESS,
						msg: 'Token válido, establezca su nueva contraseña y confirme.',
						show: true,
					})
					setUserFormType(FORMTYPE.CHANGE_PASSWORD)
				}
			})
			.catch((error) => {
				// console.log('error', error)
				setServerMsg({ status: STATUS_SERVER.ERROR, msg: error, show: true })
			})
	}

	return (
		<form action="" className="transition form-ichef">
			<header className="text-center">
				<h6>
					<IcoUsuario />
					{t("verify_recovery_token")}
				</h6>
			</header>

			<div>
				<input
					type="text"
					name="token"
					placeholder="Token"
					onChange={(e) => setInputToken(e.target.value)}
					value={inputToken}
					required
				/>
			</div>
			<button className="transition" name="submit" onClick={(e) => handleTokenVerification(e)}>
				{t("verify")}
			</button>
			<footer>
				<a
					href="/#"
					onClick={(event) => {
						event.preventDefault();
						setUserFormType(FORMTYPE.PASSWORD_RESET_REQUEST)
						setServerMsg({ status: null, msg: '', show: false })
					}}
				>
					{t("request_new_token")}
				</a>
			</footer>

			{serverMsg && serverMsg.show ? (
				<div
					className={`msj ${
						serverMsg.status === STATUS_SERVER.ERROR
							? 'error'
							: serverMsg.status === STATUS_SERVER.SUCCESS
							? 'ok'
							: ''
					}`}
				>
					{serverMsg.msg}
				</div>
			) : (
				<></>
			)}
		</form>
	)
}

export default TokenValidationForm
