import { UPDATE_SERVICES, REMOVE_SERVICES } from './serviceActionTypes'

const serviceActions = {
	updateServices: function (service) {
		return {
			type: UPDATE_SERVICES,
			payload: service,
		}
	},
	removeServices: function (service) {
		return {
			type: REMOVE_SERVICES,
		}
	},
}

export default serviceActions
