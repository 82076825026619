import { useEffect, useState } from 'react'
import Select from 'react-select'
import Icon from '../Icon'
import { useTranslation } from "react-i18next";

const Tags = (props) => {
	const { t } = useTranslation();
	const { noTagMsg = '', items = [], _etiquetas, tipoDeVista = false, idReceta, updateField, ...rest } = props

	const [etiquetas, setEtiquetas] = useState([])
	useEffect(() => {
		if (items.length || etiquetas.length)
			setEtiquetas(items)
		// eslint-disable-next-line
	}, [items])

	const handleTagChange = ({ item }) => {
		etiquetas.push(item)
		updateField('etiquetas', { target: { value: etiquetas } })
	}

	const removeTag = (idRemove) => {
		const newTags = etiquetas.filter(({ idEtiqueta }) => idEtiqueta !== idRemove)
		updateField('etiquetas', { target: { value: newTags } })
	}

	const etiquetas_sorted = _etiquetas.sort((a, b) => a.idTipoEtiqueta - b.idTipoEtiqueta)

	return (
		<section {...rest}>
			{tipoDeVista !== 'vista-diseno' &&
				etiquetas.map(({ nombreEtiqueta }, index) => (
					<div key={index} className="tags">
						<span className="link-recipe-page">{t([`labels.${nombreEtiqueta}`, nombreEtiqueta])}</span>
					</div>
				))}
			{!etiquetas.length && noTagMsg && tipoDeVista !== 'vista-diseno' && <>{noTagMsg}</>}

			{tipoDeVista === 'vista-diseno' &&
				Object.keys(etiquetas_sorted).map(k => {
					const { etiquetas: options, tipoEtiqueta, idTipoEtiqueta } = etiquetas_sorted[k]
					return (
						<div key={k}>
							<Select
								key={k}
								placeholder={t([`labels.${tipoEtiqueta}`, tipoEtiqueta])}
								className="add-tag"
								options={options
									.filter(
										({ idEtiqueta }) =>
											etiquetas.findIndex(({ idEtiqueta: itemId }) => idEtiqueta === itemId) ===
											-1
									)
									.map(({ idEtiqueta, nombreEtiqueta }) => ({
										label: t([`labels.${nombreEtiqueta}`, nombreEtiqueta]),
										value: idEtiqueta,
										item: {
											idEtiqueta,
											tipoEtiqueta,
											idTipoEtiqueta,
											nombreEtiqueta,
										}})
									).sort((a, b) => a.label.localeCompare(b.label))
								}
								isClearable={true}
								onChange={handleTagChange}
								closeMenuOnSelect={false}
								value=""
								noOptionsMessage={() => t("no_labels_left")}
							/>

							{etiquetas
								.filter(({ idTipoEtiqueta: id }) => id === idTipoEtiqueta)
								.map(({ nombreEtiqueta, idEtiqueta }, index) => (
									<div key={index} className="tags">
										<span className="link-recipe-page">
											{t([`labels.${nombreEtiqueta}`, nombreEtiqueta])}
										</span>
										<Icon
											type="Cerrar"
											className="remove-tag"
											onClick={() => {
												removeTag(idEtiqueta)
											}}
										/>
									</div>
								))}
						</div>
					)
				})}
		</section>
	)
}

export default Tags
