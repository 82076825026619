import { useEffect, useState } from 'react'
import { useAuthProvider } from '../../providers/auth'
import { useServiceProvider } from '../../providers/service'
import { FORMTYPE } from '../../providers/utils/functions'
import ChangePasswordForm from './ChangePasswordForm'
import LoginForm from './LoginForm'
import PasswordResetForm from './PasswordResetForm'
import SignUpForm from './SignUpForm'
import TokenValidationForm from './TokenValidationForm'
import ActivateAccountForm from './ActivateAccountForm'
import ResendActivateAccountForm from './ResendActivateAccountForm'
import { useTranslation } from "react-i18next";

function LoginLightbox() {

	//Proveedor de autenticación
	const authProvider = useAuthProvider()

	const isAuthenticated = authProvider.checkAuth()

	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	const [userFormType, setUserFormType] = useState(FORMTYPE.LOGIN)

	const closeLoginLightBox = () => {
		serviceProvider.LoginLightBoxOff()
		setLightboxActivated(false)
	}

	const [lightboxActivated, setLightboxActivated] = useState(true)

	useEffect(() => {
		if (!lightboxActivated) {
			setUserFormType(FORMTYPE.LOGIN)
		}
	}, [lightboxActivated])

	const [inputToken, setInputToken] = useState('')

	return (

		<div
			className={`cyg-lightbox transition ${isAuthenticated ? 'active' : ''}`}
			id="sesion"		>
			<i className="btn-close" onClick={closeLoginLightBox}></i>


			{userFormType === FORMTYPE.LOGIN ? (
				<LoginForm setUserFormType={setUserFormType} />
			) : userFormType === FORMTYPE.PASSWORD_RESET_REQUEST ? (
				<PasswordResetForm setUserFormType={setUserFormType} />
			) : userFormType === FORMTYPE.TOKEN_VALIDATION ? (
				<TokenValidationForm
					setUserFormType={setUserFormType}
					inputToken={inputToken}
					setInputToken={setInputToken}
				/>
			) : userFormType === FORMTYPE.ACTIVATE_ACCOUNT ? (
				<ActivateAccountForm
					setUserFormType={setUserFormType}
					inputToken={inputToken}
					setInputToken={setInputToken}
				/>
			) : userFormType === FORMTYPE.RESEND_ACTIVATE_TOKEN ? (
				<ResendActivateAccountForm setUserFormType={setUserFormType} />
			) : userFormType === FORMTYPE.CHANGE_PASSWORD ? (
				<ChangePasswordForm
					setUserFormType={setUserFormType}
					inputToken={inputToken}
					setInputToken={setInputToken}
				/>
			) : userFormType === FORMTYPE.SIGNUP ? (
				<SignUpForm setUserFormType={setUserFormType} />
			) : (
				<></>
			)}
		</div>
	)
}

export default LoginLightbox
