export class AuthLocalStore {
	constructor(authState, authActions, dispatch) {
		this.authState = authState
		this.dispatch = dispatch
		this.authActions = authActions
	}

	logout() {
		this.dispatch(this.authActions.userLogout())
	}

	updateUserData(userData) {
		this.dispatch(this.authActions.userStorage(userData))
	}

	updateFolders(folders) {
		this.dispatch(this.authActions.updateFolders(folders))
	}

	updateWatchLater(watchLater) {
		this.dispatch(this.authActions.updateWatchLater(watchLater))
	}

	updateFavorites(favorites) {
		this.dispatch(this.authActions.updateFavorites(favorites))
	}

	updateLikes(likes) {
		this.dispatch(this.authActions.updateLikes(likes))
	}

	getAuth(name) {
		return this.authState[name]
	}

	getAuths() {
		return this.authState
	}
}
