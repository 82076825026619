import React, { useState, useEffect } from 'react'
import { useFilterProvider } from '../providers/filter'
import { ReactComponent as IcoFiltro } from '../images/svg/ico-filter.svg'
import { ReactComponent as IcoFiltroBlanco } from '../images/svg/ico-filter-blanco.svg'
import { useServiceProvider } from '../providers/service'
import '../styles/FiltersSideBar.css'
import { PRIVILEGIOS, tienePermisoDe } from '../providers/utils/functions'
import { useAuthProvider } from '../providers/auth'
import ClearFiltersButton from './ClearFiltersButton'
import { useTranslation } from "react-i18next";

const SECONDARY_MENU_LABEL_TYPE_IDS =
	typeof process.env.REACT_APP_SECONDARY_MENU_LABEL_TYPE_IDS === 'string'
		? process.env.REACT_APP_SECONDARY_MENU_LABEL_TYPE_IDS.split(',').map((id) => parseInt(id))
		: []

function FiltersSideBar({ filterList }) {
	const {
		isLoading: filterListIsLoading,
		isError: filterListIsError,
		error: filterListError,
		data: filterListData,
	} = filterList
	const { t } = useTranslation();

	useEffect(() => {
		if (filterListData) {
		filterListData.categorias = filterListData.categorias.map(category => {
			return {
				...category,
				nombreCategoria: t([`prep_types.${category.nombreCategoria}`, category.nombreCategoria])
			};
		});
		filterListData.categorias = filterListData.categorias.sort((a, b) => a.nombreCategoria.localeCompare(b.nombreCategoria));
		}
	}, [t, filterListData]);

	//Proveedor de filtros
	const filterProvider = useFilterProvider()
	const selectedCategories = filterProvider.getFilter('categories')
	const selectedLabels = filterProvider.getFilter('labels')
	const selectedFlavors = filterProvider.getFilter('flavors')
	const selectedDifficulties = filterProvider.getFilter('difficulties')
	//const recipeStatus = filterProvider.getFilter('status')
	const selectedStatuses = filterProvider.getFilter('status')

	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	const openLabelType = (event) => {
		event.target.classList?.toggle('activo')
	}

	const handleStatusFilters = (data) => {
		let status = selectedStatuses ? selectedStatuses : {}
		status[data.idEstado] = data
		filterProvider.updateFilter('status', status)
	}

	const deleteStatusFilters = (idStatus) => {
		let selectedStatusAux = selectedStatuses

		if (typeof selectedStatusAux[idStatus] !== 'undefined') {
			delete selectedStatusAux[idStatus]
		}

		filterProvider.updateFilter('status', selectedStatusAux)
	}

	const handleCategoriesFilters = (data) => {
		let categories = selectedCategories ? selectedCategories : {}
		categories[data.idCategoria] = data
		filterProvider.updateFilter('categories', categories)
	}

	const deleteCategoriesFilters = (idCategoria) => {
		let selectedCategoriesAux = selectedCategories

		if (typeof selectedCategoriesAux[idCategoria] !== 'undefined') {
			delete selectedCategoriesAux[idCategoria]
		}

		filterProvider.updateFilter('categories', selectedCategoriesAux)
	}

	const handleFlavorsFilters = (data) => {
		let flavors = selectedFlavors ? selectedFlavors : {}
		flavors[data.idSabor] = data
		filterProvider.updateFilter('flavors', flavors)
	}

	const deleteFlavorsFilters = (idFlavor) => {
		let selectedFlavorsAux = selectedFlavors

		if (typeof selectedFlavorsAux[idFlavor] !== 'undefined') {
			delete selectedFlavorsAux[idFlavor]
		}

		filterProvider.updateFilter('flavors', selectedFlavorsAux)
	}

	const handleDifficultiesFilters = (data) => {
		let difficulties = selectedDifficulties ? selectedDifficulties : {}
		difficulties[data.idDificultad] = data
		filterProvider.updateFilter('difficulties', difficulties)
	}

	const deleteDifficultiesFilters = (idDifficulty) => {
		let selectedDifficultiesAux = selectedDifficulties

		if (typeof selectedDifficultiesAux[idDifficulty] !== 'undefined') {
			delete selectedDifficultiesAux[idDifficulty]
		}

		filterProvider.updateFilter('difficulties', selectedDifficultiesAux)
	}

	const handleLabelsFilters = (data) => {
		let labelTypes = selectedLabels ? selectedLabels : {}
		let labelType = labelTypes[data.idTipoEtiqueta]

		labelType = typeof labelType === 'undefined' ? {} : labelType
		labelType.labels = typeof labelType.labels === 'undefined' ? {} : labelType.labels
		labelType.labels[data.idEtiqueta] = { idEtiqueta: data.idEtiqueta, nombreEtiqueta: data.nombreEtiqueta }

		labelTypes[data.idTipoEtiqueta] = {
			...labelType,
			idTipoEtiqueta: data.idTipoEtiqueta,
			tipoEtiqueta: data.tipoEtiqueta,
		}

		filterProvider.updateFilter('labels', labelTypes)
	}

	const deleteLabelsFilters = (idTipoEtiqueta, idEtiqueta) => {
		let selectedLabelsAux = selectedLabels ? selectedLabels : {}

		if (
			typeof selectedLabelsAux[idTipoEtiqueta] !== 'undefined' &&
			typeof selectedLabelsAux[idTipoEtiqueta]['labels'] !== 'undefined' &&
			typeof selectedLabelsAux[idTipoEtiqueta]['labels'][idEtiqueta] !== 'undefined' &&
			selectedLabelsAux[idTipoEtiqueta]['labels'][idEtiqueta].idEtiqueta === idEtiqueta
		) {
			delete selectedLabelsAux[idTipoEtiqueta]['labels'][idEtiqueta]

			if (
				selectedLabelsAux[idTipoEtiqueta]['labels'] &&
				Object.keys(selectedLabelsAux[idTipoEtiqueta]['labels']).length === 0
			) {
				delete selectedLabelsAux[idTipoEtiqueta]
			}
		}

		filterProvider.updateFilter('labels', selectedLabelsAux)
	}

	//Timeout id
	const [sidebarBackgroundTimeoutId, setSidebarBackgroundTimeoutId] = useState(null)

	//Demora para el cierre del sidebar.
	const sidebarBackgroundDelay = 13000

	const authProvider = useAuthProvider()
	const userData = authProvider.getIdentity()

	const { CREAR_RECETA, EDITAR_SCORE } = PRIVILEGIOS

	const [btnFilterOnHover, setBtnFilterOnHover] = useState(false)

	return (
		<div>
			{!serviceProvider.isFoldersActivated() && (
				<i
					className={`transition btn-filtro `}
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()

						serviceProvider.filterToggle()
					}}
					onMouseEnter={() => setBtnFilterOnHover(true)}
					onMouseLeave={() => setBtnFilterOnHover(false)}
				>
					{btnFilterOnHover ? <IcoFiltroBlanco /> : <IcoFiltro />}
				</i>
			)}

			<div
				className={`sidebar-background ${serviceProvider.isFilterActivated() ? 'activo' : ''}`}
				onClick={(e) => {
					serviceProvider.filterOff()
					if (sidebarBackgroundTimeoutId) {
						clearTimeout(sidebarBackgroundTimeoutId)
						setSidebarBackgroundTimeoutId(null)
					}
				}}
				onMouseEnter={(e) => {
					if (!serviceProvider.isMobileActivated()) {
						if (serviceProvider.isFilterActivated()) {
							setSidebarBackgroundTimeoutId(
								setTimeout(() => serviceProvider.filterOff(), sidebarBackgroundDelay)
							)
						}
					}
				}}
				onMouseLeave={(e) => {
					if (!serviceProvider.isMobileActivated() && serviceProvider.isFilterActivated()) {
						if (sidebarBackgroundTimeoutId) {
							clearTimeout(sidebarBackgroundTimeoutId)
							setSidebarBackgroundTimeoutId(null)
						}
					}
				}}
			></div>

			<div id="filtros">
				{!filterListIsLoading && (
					<>
						{!filterListIsError ? (
							<>
								<h4 className="color-verde">{t("filter_by")}</h4>

								<section className="clear">
									<ClearFiltersButton />
								</section>

								<section>
									<div id="aco-1" className="mod-acordeon">
										<div>
											<h6 className={'item-aco'} id="category" onClick={openLabelType}>
												{t("prep_types.title")}
											</h6>
											<div className="content">
												{Array.isArray(filterListData.categorias) &&
													filterListData.categorias.map((category, index) => {
														// Activar la categoría seleccionada
														const selectedCategory =
															selectedCategories &&
															typeof selectedCategories[category.idCategoria] !==
																'undefined'
																? selectedCategories[category.idCategoria]
																: null

														const isSelectedCategory =
															selectedCategory &&
															selectedCategory.idCategoria === category.idCategoria
																? true
																: false

														const categoryClassName = isSelectedCategory
															? 'etiqueta activo'
															: 'etiqueta'

														return (
															<div
																className={categoryClassName}
																key={index}
																onClick={() => {
																	if (!isSelectedCategory) {
																		handleCategoriesFilters(category)
																	} else {
																		deleteCategoriesFilters(category.idCategoria)
																	}
																}}
															>
																{/*t([`prep_types.${category.nombreCategoria}`, category.nombreCategoria])*/}
																{category.nombreCategoria}
															</div>
														)
													})}
											</div>
										</div>
									</div>

									<div id="aco-1" className="mod-acordeon">
										<div>
											<h6 className={'item-aco'} id="difficulty" onClick={openLabelType}>
												{t("difficulty.title")}
											</h6>
											<div className="content">
												{Array.isArray(filterListData.dificultades) &&
													filterListData.dificultades.map((difficulty, index) => {
														// Activar dificultad si está seleccionado
														const selectedDifficulty =
															selectedDifficulties &&
															typeof selectedDifficulties[difficulty.idDificultad] !==
																'undefined'
																? selectedDifficulties[difficulty.idDificultad]
																: null

														const isSelectedDifficulty =
															selectedDifficulty &&
															selectedDifficulty.idDificultad === difficulty.idDificultad
																? true
																: false

														const dificultyClassName = isSelectedDifficulty
															? 'etiqueta activo'
															: 'etiqueta'

														return (
															<div
																className={dificultyClassName}
																key={index}
																onClick={() => {
																	if (!isSelectedDifficulty) {
																		handleDifficultiesFilters(difficulty)
																	} else {
																		deleteDifficultiesFilters(
																			difficulty.idDificultad
																		)
																	}
																}}
															>
																{t([`difficulty.${difficulty.nivel}`, difficulty.nivel])}
															</div>
														)
													})}
											</div>
										</div>
									</div>

									<div id="aco-1" className="mod-acordeon">
										{Array.isArray(filterListData.tiposEtiqueta) &&
											filterListData.tiposEtiqueta
												// Excluyo los de tipo categoría
												.filter(d =>
													![...SECONDARY_MENU_LABEL_TYPE_IDS].includes(d.idTipoEtiqueta)
												)
												.map((labelType, index) => {
													const labelTypeId = `filter-type-${labelType.idTipoEtiqueta}`
													const selectedLabelType =
														selectedLabels &&
														typeof selectedLabels[labelType.idTipoEtiqueta] !== 'undefined'
															? selectedLabels[labelType.idTipoEtiqueta]
															: null

													const isSelectedLabelType =
														selectedLabelType &&
														selectedLabelType.idTipoEtiqueta === labelType.idTipoEtiqueta

													// Activar acordeón si hay una etiqueta seleccionada
													const labelTypeClassName = isSelectedLabelType
														? 'item-aco activo'
														: 'item-aco'

													return (
														<div key={index}>
															<h6
																className={labelTypeClassName}
																id={labelTypeId}
																key={index}
																onClick={openLabelType}
															>
																{t([`labels.${labelType.tipoEtiqueta}`, labelType.tipoEtiqueta])}
															</h6>
															<div className="content">
																{Array.isArray(labelType.etiquetas) &&
																	labelType.etiquetas.map((label, index) => {
																		// Activar etiqueta si está seleccionada

																		const isSelectedLabel =
																			selectedLabelType &&
																			selectedLabelType.labels &&
																			selectedLabelType.labels[
																				label.idEtiqueta
																			] &&
																			selectedLabelType.labels[label.idEtiqueta]
																				.idEtiqueta === label.idEtiqueta
																				? true
																				: false

																		const labelClassName = isSelectedLabel
																			? 'etiqueta activo'
																			: 'etiqueta'

																		return (
																			<div
																				key={index}
																				className={labelClassName}
																				onClick={() => {
																					if (!isSelectedLabel) {
																						handleLabelsFilters({
																							...label,
																							idTipoEtiqueta:
																								labelType.idTipoEtiqueta,
																							tipoEtiqueta:
																								labelType.tipoEtiqueta,
																						})
																					} else {
																						deleteLabelsFilters(
																							labelType.idTipoEtiqueta,
																							label.idEtiqueta
																						)
																					}
																				}}
																			>
																				{t([`labels.${label.nombreEtiqueta}`, label.nombreEtiqueta])}
																			</div>
																		)
																	})}
															</div>
														</div>
													)
												})}
									</div>

									<div id="aco-1" className="mod-acordeon">
										<div>
											<h6 className={'item-aco'} id="flavor" onClick={openLabelType}>
												{t("taste.title")}
											</h6>
											<div className="content">
												{Array.isArray(filterListData.sabores) &&
													filterListData.sabores.map((flavor, index) => {
														// Activar sabor si está seleccionado
														const selectedFlavor =
															selectedFlavors &&
															typeof selectedFlavors[flavor.idSabor] !== 'undefined'
																? selectedFlavors[flavor.idSabor]
																: null

														const isSelectedFlavor =
															selectedFlavor && selectedFlavor.idSabor === flavor.idSabor
																? true
																: false

														const flavorClassName = isSelectedFlavor
															? 'etiqueta activo'
															: 'etiqueta'

														return (
															<div
																key={index}
																className={flavorClassName}
																onClick={() => {
																	if (!isSelectedFlavor) {
																		handleFlavorsFilters(flavor)
																	} else {
																		deleteFlavorsFilters(flavor.idSabor)
																	}
																}}
															>
																{t([`taste.${flavor.sabor}`, flavor.sabor])}
															</div>
														)
													})}
											</div>
										</div>
									</div>

									{tienePermisoDe(CREAR_RECETA, userData) &&
										tienePermisoDe(EDITAR_SCORE, userData) && (
											<div id="aco-1" className="mod-acordeon">
												<h6 className={'item-aco'} id="flavor" onClick={openLabelType}>
													{t("state.title")}
												</h6>
												<div className="content">
													{Array.isArray(filterListData.estados) &&
														filterListData.estados.map((estado, index) => {
															const selectedStatus =
																selectedStatuses &&
																typeof selectedStatuses[estado.idEstado] !== 'undefined'
																	? selectedStatuses[estado.idEstado]
																	: null

															const isSelectedStatus =
																selectedStatus &&
																selectedStatus.idEstado === estado.idEstado
																	? true
																	: false
															const dificultyClassName =
																selectedStatus &&
																selectedStatus.idEstado === estado.idEstado
																	? 'etiqueta activo'
																	: 'etiqueta'
															return (
																<div
																	className={dificultyClassName}
																	key={index}
																	onClick={() => {
																		if (!isSelectedStatus) {
																			handleStatusFilters(estado)
																		} else {
																			deleteStatusFilters(estado.idEstado)
																		}
																	}}
																>
																	{t([`state.${estado.nombreEstado}`, estado.nombreEstado])}
																</div>
															)
														})}
												</div>
											</div>
										)}
								</section>
							</>
						) : (
							<p>{filterListError}</p>
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default FiltersSideBar
