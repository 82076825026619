import { REMOVE_FILTERS, UPDATE_FILTERS } from './filtersActionTypes'

function filtersReducer(state = {}, action) {
	switch (action.type) {
		case UPDATE_FILTERS:
			return action.payload
		case REMOVE_FILTERS:
			return {}
		default:
			return state
	}
}

export default filtersReducer
