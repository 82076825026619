import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { defaultDataProvider } from "../../providers/data";
import { STATUS_SERVER } from "../../providers/utils/functions";
import "./Referral.scss";

//const REFERRAL_API = "http://localhost:8080/CNS-Services-WAR/"
const REFERRAL_API = "https://www.ichef.uy:8443/CNS-Services-WAR/";

function formatDate(date) {
	if (!date) return "";

	const d = new Date(date);
	if (isNaN(d)) return date;

	var day = "" + d.getDate();
	var month = "" + (d.getMonth() + 1);
	const year = "" + d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;
	return [day, month, year].join("/");
}

function getInfo(record) {
	const user = record.cliente_primer_registro;
	const date = record.fecha_primer_registro;
	if (user || date) {
		var result = "Este cliente ya fue registrado ";
		if (user) result += `por ${user} `;
		if (date) result += `el ${formatDate(date)} `;
		return result;
	}
	return "";
}

const Referral = ({ profileForm }) => {
	const { t } = useTranslation();
	const [rname, setRname] = useState("");
	const [rphone, setRphone] = useState("");
	const [comment, setComment] = useState("");

	const dataProvider = defaultDataProvider(REFERRAL_API);
	const [serverProfileMsg, setServerProfileMsg] = useState({ status: null, msg: "", show: false });

	// profileForm.clientId = "test_ci";

	const { data: referralRecords } = useQuery(["referralList"], async () => {
		const response = await dataProvider.getList(`referrals?clientId=${profileForm.clientId}`);
		return response.data;
	});

	async function SendHSM(celular, referido, lover, codigo) {
		const SAILBOT_API = "https://app.sailbot.biz/Bot-Server/api/";
		const sailbotDataProvider = defaultDataProvider(SAILBOT_API);

		const response = await sailbotDataProvider.create("messages/whatsapp-template", {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Basic aWNoZWZAbWFpbC5jb206c2FpbGJvdDIwMjA=",
				Accept: "*/*"
			},
			data: {
				from: "103339406144029",
				templateName: "envio_cupon",
				to: [
					{
						contactPhone: celular,
						variables: [
							{
								header_image: "https://i.ibb.co/Tbgy5PT/recibir.png",
								nombreReferido: referido,
								nombreLover: lover,
								button_url_suffix: codigo
							}
						]
					}
				]
			}
		});
		if (response.status === 200) {
			setServerProfileMsg({
				status: STATUS_SERVER.SUCCESS,
				msg: "Cupón enviado con éxito.",
				show: true
			});
		} else {
			setServerProfileMsg({
				status: STATUS_SERVER.ERROR,
				msg: "Error al enviar el cupón.",
				show: true
			});
		}
	}

	// eslint-disable-next-line
	async function SendCoupon(perfil, referido) {
		const WORDPRESS_API = "https://ichef.com.uy";
		const wordPressDataProvider = defaultDataProvider(WORDPRESS_API);

		const response = await wordPressDataProvider.create("", {
			headers: {
				"Content-Type": "application/json",
				Accept: "*/*"
			},
			data: {
				nombre_lover: perfil.name,
				usuario_lover: perfil.username,
				cedula_lover: perfil.clientId,
				nombre_referido: referido.nombre_referido,
				celular_referido: referido.celular_referido
			}
		});

		if (response.status === 200) {
			SendHSM(referido.celular_referido, referido.nombre_referido, perfil.name, response.data.codigo_descuento);
		} else {
			setServerProfileMsg({
				status: STATUS_SERVER.ERROR,
				msg: "Error al obtener el cupón.",
				show: true
			});
		}
	}

	const handleProfileForm = event => {
		event.preventDefault();
		setServerProfileMsg({ status: null, msg: "", show: false });

		let params = `cid=${profileForm.clientId}&cname=${encodeURIComponent(profileForm.name)}&rname=${encodeURIComponent(
			rname
		)}&rphone=${rphone}&comment=${encodeURIComponent(comment)}`;

		dataProvider
			.create(`referrals?${params}`, {
				headers: {
					"Content-Type": "application/json",
					Accept: "*/*"
				}
			})
			.then(() => {
				setServerProfileMsg({
					status: STATUS_SERVER.SUCCESS,
					msg: "Referido agregado con éxito.",
					show: true
				});
			})
			.catch(error => {
				setServerProfileMsg({
					status: STATUS_SERVER.ERROR,
					msg: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
					show: true
				});
			});
	};

	return (
		<div className="referrals">
			<div className="cyg-row">
				<div className="cyg-col-full">
					<form action="" className="form-ichef herramientas" autoComplete="off" onSubmit={handleProfileForm}>
						<h4 className="usuario-herr">{t("referral_registration")}</h4>
						<input
							type="text"
							name="name"
							placeholder={t("referral_name")}
							value={rname}
							onChange={e => setRname(e.target.value)}
							required
							autoComplete="off"
						/>
						<input
							type="text"
							name="phoneNumber"
							placeholder={t("referral_phone")}
							value={rphone}
							onChange={e => setRphone(e.target.value)}
							required
							autoComplete="off"
						/>
						<input
							type="text"
							name="comment"
							placeholder={t("comment")}
							value={comment}
							onChange={e => setComment(e.target.value)}
							autoComplete="off"
						/>
						<button className="transition" name="">
							{t("add_referral")}
						</button>
						{serverProfileMsg && serverProfileMsg.show ? (
							<div
								className={`msj ${
									serverProfileMsg.status === STATUS_SERVER.ERROR
										? "error"
										: serverProfileMsg.status === STATUS_SERVER.SUCCESS
										? "ok"
										: ""
								}`}
							>
								{serverProfileMsg.msg}
							</div>
						) : (
							<></>
						)}
					</form>
				</div>
				{referralRecords && (
					<div className="cyg-col-full">
						<h4>{t("registered_referrals")}</h4>
						<table>
							<thead>
								<tr>
									<th>{t("registration_date")}</th>
									<th>{t("name")}</th>
									<th>{t("phone")}</th>
									<th>{t("comment")}</th>
									<th>{t("purchase_status")}</th>
									<th>{t("credit_note_refund_date")}</th>
									<th>{t("notes")}</th>
								</tr>
							</thead>
							<tbody>
								{referralRecords.map((record, index) => (
									<tr key={index}>
										<td>{formatDate(record.fecha_creacion)}</td>
										<td>{record.nombre_referido}</td>
										<td>{record.celular_referido}</td>
										<td>{record.comentario}</td>
										<td>{record.estado === 1 ? t("pending") : record.estado === 2 ? t("purchased") : t("not_interested")}</td>
										<td>{formatDate(record.fecha_reintegro)}</td>
										<td>{getInfo(record)}</td>
										{/*<td>
											<button className="submit-btn" onClick={() => SendCoupon(profileForm, record)}>
												{t("send_coupon")}
											</button>
										</td>*/}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
	);
};

export default Referral;
