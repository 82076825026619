import { HashLink } from 'react-router-hash-link'
import { useServiceProvider } from '../../providers/service'
import { useTranslation } from "react-i18next";

function FolderItem({ itemFolder, folderList, setFolderList }) {
	const { t } = useTranslation();
	// Proveedor de servicios
	const serviceProvider = useServiceProvider()

	return (
		<span className={`carpeta ${itemFolder.cssClass} ${itemFolder.menuActivated ? 'activo' : ''}`}>
			<HashLink smooth to={`/mis-recetas/${itemFolder.idCarpeta}/${itemFolder.nombre}`}>
				{itemFolder.nombre}
			</HashLink>

			<i
				onClick={(e) => {
					e.stopPropagation()

					setFolderList(
						folderList.map((folder) => {
							return folder.idCarpeta === itemFolder.idCarpeta
								? {
										...folder,
										menuActivated: !folder.menuActivated,
								  }
								: {
										...folder,
										menuActivated: false,
								  }
						})
					)
				}}
			></i>

			{itemFolder.menuActivated && !itemFolder.readOnly &&  (
				<div className={`sub--carpeta activo`}>
					{!itemFolder.isPublic && (
						<a
							href="/#"
							className={`btn-compartir-carpeta ${
								serviceProvider.isFolderSharingFormLightBoxActivated() ? 'activo' : ''
							}`}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()

								serviceProvider.folderSharingFormLightBoxOn()
								serviceProvider.setFolderSharingFormLightBoxData(itemFolder)
							}}
						>
							{t("share")}
						</a>)}

						<a
							href="/#"
							className={`btn-edit-carpeta ${
								serviceProvider.isFolderFormLightBoxActivated() ? 'activo' : ''
							}`}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()

								serviceProvider.folderFormLightBoxOn()
								serviceProvider.setFolderFormLightBoxData(itemFolder)
							}}
						>
							{t("rename")}
						</a>

						<a
							href="/#"
							className={`btn-eliminar-carpeta ${
								serviceProvider.isFolderDeletingFormLightBoxActivated() ? 'activo' : ''
							}`}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()

								serviceProvider.folderDeletingFormLightBoxOn()
								serviceProvider.setFolderDeletingFormLightBoxData(itemFolder)
							}}
						>
							{t("delete")}
						</a>
				</div>
			)}
		</span>
	)
}

export default FolderItem
