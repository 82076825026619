import InputNumber from "rc-input-number";
import { useTranslation } from "react-i18next";

function NutriValues(props) {
	const { t } = useTranslation();
	const { valoresNutricionales = [], tipoDeVista, updateField, calNut = [] } = props;

	const fieldChange = (i, value) => {
		const idx = valoresNutricionales.findIndex(({ idNutriente: id }) => id === i);
		valoresNutricionales[idx].cantidad = value;
		updateField("valoresNutricionales", { target: { value: valoresNutricionales } });
	};

	const updateNutritionalValues = () => {
		valoresNutricionales.forEach(({ idNutriente }, vnIndex) => {
			const calNutIndex = calNut.findIndex(({ idNutriente: id }) => id === idNutriente);
			if (calNutIndex === -1) return;
			const cantidad = calNut[calNutIndex].cantidad.toFixed(2);
			valoresNutricionales[vnIndex].cantidad = cantidad;
		});
		updateField("valoresNutricionales", { target: { value: valoresNutricionales } });
	};

	return (
		<>
			{tipoDeVista === "vista-diseno" && (
				<button type="button" className="btn btn-primary pc" onClick={updateNutritionalValues}>
					{t("update_nutrients")}
				</button>
			)}
			<div className="nutri-values">
				{tipoDeVista === "vista-diseno" && (
					<div className="row-nutri">
						<div className="col col-1">{t("nutrient")}</div>
						<div className="col col-2">{t("proposed_value")}</div>
						<div className="col col-3">{t("original_value")}</div>
					</div>
				)}
				{valoresNutricionales &&
					valoresNutricionales.map(({ idNutriente, nombreNutriente, nombreUnidad, cantidad = 0 }, index) => {
						const nutPropuesto = calNut.find(({ idNutriente: id }) => idNutriente === id);
						return (
							<div className="row-nutri" key={index}>
								<div className="col col-1">{t([`nutritional.${nombreNutriente}`, nombreNutriente])}</div>
								{tipoDeVista === "vista-diseno" && nutPropuesto && (
									<div className="col col-2">{nutPropuesto.cantidad.toFixed(2)}</div>
								)}
								<div className="col col-3">
									<InputNumber
										className="mini-fld"
										min={0}
										value={cantidad}
										onChange={value => {
											fieldChange(idNutriente, value);
										}}
										readOnly={tipoDeVista === "vista-previa"}
									/>{" "}
									{nombreUnidad && t([`units.${nombreUnidad}`, nombreUnidad])}
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
}

export default NutriValues;
