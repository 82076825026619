import { UPDATE_SERVICES, REMOVE_SERVICES } from './serviceActionTypes'

function serviceReducer(state = {}, action) {
	switch (action.type) {
		case UPDATE_SERVICES:
			return action.payload
		case REMOVE_SERVICES:
			return {}
		default:
			return state
	}
}

export default serviceReducer
