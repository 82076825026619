import { useServiceProvider } from '../../providers/service'
import FoldersToRecipeForm from './FoldersToRecipeForm'

function FoldersToRecipeLightbox() {
	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	return (
		<div
			className="cyg-lightbox carpetas"
			id="asignar-carpeta"
			onClick={() => serviceProvider.foldersToRecipeLightBoxOff()}
		>
			<i className="btn-close"></i>

			<FoldersToRecipeForm />
		</div>
	)
}

export default FoldersToRecipeLightbox
