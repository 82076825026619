import { useEffect, useState } from 'react'
import { fileUpload } from '../providers/utils/fileUpload'

export const useUploadImage = (value) => {
	const [images, setImages] = useState([])
	const [loading, setLoading] = useState(false)
	const [urlImage, setUrlImage] = useState({})

	const handleChange = (imageList) => setImages(imageList)

	const onUpload = async () => {
		setLoading(true)
		const fileInfo = await fileUpload(images[0].file)
		setLoading(false)
		if (fileInfo) setUrlImage(fileInfo)
		setImages([])
		return fileInfo
	}

	useEffect(() => {
		urlImage && setUrlImage('')
	}, [urlImage])

	return {
		loading,
		onUpload,
		handleChange,
		urlImage,
		images,
	}
}

//export default useUploadImage
