/* eslint-disable react-hooks/exhaustive-deps */
import { defaultRecipe, mapJson } from '../../providers/utils/FieldMaps'
import { PRIVILEGIOS } from '../../providers/utils/functions'
import { ReactComponent as IcoImprimir } from '../../images/svg/ico-imprimir.svg'
import { slugify } from '../../providers/service'
import { tienePermisoDe } from '../../providers/utils/functions'
import { useAuthProvider } from '../../providers/auth'
import { useDataProvider } from '../../providers/data'
import { useEffect, useState } from 'react'
import { useIsVisible } from '../../hooks/useIsVisible'
import { useMultiData } from '../../providers/utils/MultiData'
import { useNavigate, useParams } from 'react-router-dom'
import Article from '../../components/Article'
import BeatLoader from 'react-spinners/BeatLoader'
import CookingSteps from '../../components/edition/CookingSteps'
import CookingStepsText from '../../components/CookingSteps'
import Dialog from '../../components/Dialog'
import DragAndDrop from '../../components/upload/DragAndDrop'
import Ingredients from '../../components/edition/Ingredients'
import ModIcons from '../../components/edition/ModIcons'
import NutriValues from '../../components/edition/NutriValues'
import Section from '../../components/Section'
import Select from 'react-select'
import StarRating from '../../components/StarRating'
import Tags from '../../components/edition/Tags'
import { useTranslation } from "react-i18next";
import './RecipeEdit.scss'
import './RecipeEditMobile.scss'

const override = {
	display: 'block',
	margin: '0 auto',
	borderColor: '#509F2C',
	width: 'fit-content',
}

const RecipeEdit = () => {
	const { t, i18n } = useTranslation();
	const [updatedData, setUpdatedData] = useState({})
	const [loadConfig, setLoadConfig] = useState({})
	const [loading, setLoading] = useState(false)
	const [serverMsg, setServerMsg] = useState([])
	const [lastNutrientes, setLastNutrientes] = useState('')
	const [showDialog, setShowDialog] = useState(false)
	const [uploadActions, setUploadActions] = useState({})
	const { id, tipoDeVista, tipoAccion } = useParams()

	const navigate = useNavigate()
	const authProvider = useAuthProvider()
	const loadData = useMultiData(loadConfig, ['api/filtros/list'])

	useEffect(() => {
		if (loadConfig.length || !loadData.length) return
		setLoadConfig(loadData[0])
	}, [loadData])

	const sendAlertMsg = ({ title, path = window.location.pathname, type = 'success' }) => {
		navigate(path, {
			state: {
				message: {
					type,
					title,
				},
			},
		})
	}

	const {
		tipoEtiquetas: _etiquetas = [],
		dificultad: _dificultad = [],
		modo_velocidad: _modo_velocidad = [],
		nutrientes: _nutrientes = [],
		operaciones: _operaciones = [],
		accesorios: _accesorios = [],
		insumos: _insumos = [],
		categoria: _categorias = [],
		estados: _estados = [],
		sabor: _sabor = [],
	} = loadConfig

	const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N","Ñ", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

	const _clasificaciones = alphabet.map((_, i) => ({
		value: alphabet[i],
		label: alphabet[i],
	  }));

	const dataProvider = useDataProvider()
	const handlePrint = () => {
		window.print()
	}

	const [dataFilled, setDataFilled] = useState({})

	useEffect(() => {
		if (id === 'nueva') return
		const [, idPlantilla] = id.split('-')
		!isNaN(parseFloat(idPlantilla)) && isFinite(idPlantilla) && fill_data(idPlantilla)
	}, [id])

	const userData = authProvider.getIdentity()
	const { CREAR_RECETA, EDITAR_SCORE } = PRIVILEGIOS
	if (!tienePermisoDe(CREAR_RECETA, userData)) navigate('/')

	const headers = {
		'Content-Type': 'application/json',
		Accept: 'application/json;charset=UTF-8',
		Authorization: userData.jwt,
	}

	const fill_data = async idPlantilla => {
		setLoading(true);
		const { data } = await dataProvider.getOne(`api/recetas/${idPlantilla}`, { headers });
		if (data) {
			const translate = Math.max(data?.traducciones?.findIndex(({ codLenguaje }) => codLenguaje === i18n.resolvedLanguage) || 0, 0);
			const mixData = { ...data, ...data.traducciones[translate] };
			const { titulo, versionNumber, editor, chefReferente, idReceta, fechaRegistro } = mixData;
			setDataFilled({
				idReceta,
				titulo,
				editor,
				chefReferente,
				versionNumber,
				fechaRegistro
			});
			setUpdatedData(mixData);
		}
		setLoading(false);
	};

	const {
		idReceta,
		titulo = '',
		descripcion = '',
		fotosReceta = uploadActions.images || [],
		etiquetas,
		notasChef,
		accesorios,
		ingredientes = [],
		valoresNutricionales = _nutrientes.map(n => ({...n, ...n.unidad, nombreNutriente: n.nutriente})),
		pasos = [],
		idCategoria,
		clasificacion,
		estadoReceta: { idEstado = 2 } = {},
		sabor,
		puntuacionAdmin,
		idRecetaOriginal,
	} = updatedData

	const [calNut, setCalNut] = useState([])

	const calcularNutrientes = async (_ingredientes) => {
		const ingredientes = _ingredientes.filter(({ idInsumo, cantidad }) => !!idInsumo && !!cantidad)
		if (JSON.stringify(ingredientes) === lastNutrientes) return
		setLastNutrientes(JSON.stringify(ingredientes))

		if (ingredientes.length) {
			const info = {
				headers,
				data: {
					ingredientes,
				},
			}
			const {
				data: { nutrientes = [] },
			} = await dataProvider.create(`api/nutrientes/generate`, info)
			setCalNut(nutrientes)
		}
	}

	useEffect(() => {
		const { ingredientes = [] } = updatedData
		calcularNutrientes(ingredientes)
	}, [updatedData])

	useEffect(() => {
		const asScroll = document.querySelectorAll('.as-scroll')
		asScroll.forEach((el) => {
			el.scrollTo({ top: 0, behavior: 'smooth' })
		})
	}, [tipoDeVista])

	const updateField = (name, { target: { value } }) => {
		setServerMsg([])
		setUpdatedData({ ...updatedData, [name]: value })
	}

	const [accion] = tipoAccion.split('-')

	const sendChanges = async () => {
		const { onUpload, images } = uploadActions
		let _updatedData = { ...updatedData }
		setShowDialog(false)

		if (images && images.length) {
			let res = await onUpload()
			const { original_filename, format, secure_url } = res
			const fotosReceta = [
				{
					nombreImagen: `${original_filename}.${format}`,
					urlImagen: secure_url,
				},
			]
			_updatedData = { ...updatedData, fotosReceta }
		}

		const json = mapJson(
			'receta',
			_updatedData,
			defaultRecipe
		)

		const info = {
			headers,
			data: { ...json, codLenguaje: i18n.resolvedLanguage },
		}

		const acciones = {
			editar: async () => {
				const { data } = await dataProvider.put(`api/recetas/${idReceta}/traduccion`, info)
				return { data, title: 'Los cambios se han guardado satisfactoriamente...' }
			},
			crear: async () => {
				const { data } = await dataProvider.create(`api/recetas`, info)
				return { data, title: 'La receta se ha creado satisfactoriamente...' }
			},
			clonar:  async () => {
				const { data } = await dataProvider.create(`api/recetas`, info)
				return { data, title: 'La receta se ha creado satisfactoriamente...' }
			},
			variante: async () => {
				const { data } = await dataProvider.create(`api/recetas`, {
					...info,
					data: { ...json, idRecetaOriginal: idReceta },
				})
				return { data, title: 'Los nueva variante se ha creado satisfactoriamente...' }
			},
		}

		;(async () => {
			try {
				const { data, title } = await acciones[accion]()
				const slug_title = slugify(titulo)
				const { idReceta } = data

				sendAlertMsg({
					path: `/receta/${idReceta}/${slug_title}`,
					title,
				})

				window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
			} catch (e) {
				const {
					response: {
						data: { errors = [] },
					},
				} = e
				setServerMsg((errors || []).map(({ errorMsg }) => errorMsg))
				window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
				sendAlertMsg({
					title: 'Ocurrió un error al enviar los datos, verifique los datos y vuelva a intentarlo...',
					type: 'danger',
				})
			}
		})()
	}

	const [fotoPrincipal = {}] = fotosReceta || []
	const { nombreImagen = '' } = fotoPrincipal || {}
	const loadComplete = !!Object.keys(loadConfig).length && !loading

	const handleUploadFile = (actions) => {
		setUploadActions(actions)
	}

	const setVista = (tipo) => {
		navigate(`/${accion}-receta/${id}/vista-${tipo}`)
	}

	const currClasificacion = (_clasificaciones.length &&
		_clasificaciones.find(({ value: v }) => v === clasificacion)) || {
		label: 'A',
		value: 'A',
	}

	const currCat = (_categorias.length && _categorias.find(({ idCategoria: id }) => id === idCategoria)) || {
		idCategoria: 0,
		nombreCategoria: '(Ninguno)',
	}

	const currEstado = (_estados.length && _estados.find(({ idEstado: id }) => id === idEstado)) || {
		idEstado: 2,
		nombreEstado: 'Privadas',
	}

	const currSabor = (_sabor.length && _sabor.find(({ idSabor: id }) => id === sabor)) || {
		idSabor: 0,
		sabor: '(Ninguno)',
	}

	const isDisabled = ingredientes.length === 0 || pasos.length === 0

	const [popover, handlePopoverContent] = useState(null)
	const [popoverPos, setPopoverPos] = useState(null)

	const { contentPopover = null, elementPopover } = popover || {}

	const popoverIsVisible = useIsVisible(elementPopover)

	useEffect(() => {
		const { elementPopover = null } = popover || {}
		if (elementPopover && popoverIsVisible) {
			var rect = elementPopover.getBoundingClientRect()
			setPopoverPos(rect)
			return
		}
		setPopoverPos(null)
	}, [popover, popoverIsVisible])
	const { x = null, y = null } = popoverPos || {}

	const [portionSize, setPortionSize] = useState(0);
	useEffect(() => {
		const tipoPreparacion = _categorias.find(({ idCategoria }) => updatedData.idCategoria === idCategoria);
		if (tipoPreparacion && tipoPreparacion.tamanioPorcion) {
			setPortionSize(tipoPreparacion.tamanioPorcion);
		}
	}, [updatedData.idCategoria, _categorias]);

	return (
		<>
			<div className={`edit-page ${tipoDeVista !== 'vista-previa' ? 'editing' : 'preview'}`}>
				<div>
					<section id="intro" className={`recipe-create`}>
						<div className="container container-3">
							<div className="cyg-row d-flex">
								<div className="cyg-col-left">
									{tipoDeVista === 'vista-diseno' ? (
										<DragAndDrop handleUploadFile={handleUploadFile} value={fotoPrincipal} />
									) : (
										<figure className="ratio-75">
											{fotoPrincipal && (
												<img
													src={`${fotoPrincipal.urlImagen || fotoPrincipal.dataURL}`}
													alt={`${nombreImagen}`}
												/>
											)}
										</figure>
									)}
								</div>

								<div className="cyg-col-right">
									<div className="cont">
										{tipoDeVista === 'vista-previa' ? (
											<>
												<header className="tit">
													<h2>{titulo}</h2>
												</header>
												<table>
													<tbody>
														<tr>
															<td>{t("subtitle")}</td>
															<td>{descripcion}</td>
														</tr>
														<tr>
															<td>{t("score")}</td>
															<td>
																<StarRating
																	score={puntuacionAdmin}
																	iconClass="icon-estrella-gris"
																/>
															</td>
														</tr>
														<tr>
															<td>{t("prep_types.title")}</td>
															<td>{t([`prep_types.${currCat.nombreCategoria}`, currCat.nombreCategoria])}</td>
														</tr>
														<tr>
															<td>{t("taste.title")}</td>
															<td>{t([`taste.${currSabor.sabor}`, currSabor.sabor])}</td>
														</tr>
														<tr>
															<td>{t("state.title")}</td>
															<td>{t([`state.${currEstado.nombreEstado}`, currEstado.nombreEstado])}</td>
														</tr>
														<tr>
															<td>{t("letter")}</td>
															<td>{currClasificacion.label}</td>
														</tr>
													</tbody>
												</table>
											</>
										) : (
											<>
												<p>
													<input
														style={{ float: 'inherit' }}
														type="text"
														placeholder={t("recipe_name")}
														value={titulo}
														onChange={o => updateField('titulo', o)}
													/>
												</p>
												{idRecetaOriginal && (
												<div>
													<input
														className='descripcion'
														type="text"
														placeholder="Subtítulo"
														value={descripcion}
														onChange={o => updateField('descripcion', o)}
													/>
												</div>
												)}
												<table>
													<tbody>
														{tienePermisoDe(EDITAR_SCORE, userData) && (
															<tr>
																<td>{t("score")}</td>
																<td>
																	<StarRating
																		score={puntuacionAdmin}
																		iconClass="icon-estrella-amarilla"
																		onClickScore={(value) =>
																			updateField('puntuacionAdmin', {
																				target: { value },
																			})
																		}
																	/>
																</td>
															</tr>
														)}
														<tr>
															<td>{t("prep_types.title")}</td>
															<td>
																<Select
																	placeholder={t("prep_types.title")}
																	options={_categorias.map(
																		({
																			idCategoria: value,
																			nombreCategoria: label,
																			tamanioPorcion: size
																		}) => ({
																			//label: t([`prep_types.${label}`, label]),
																			label: `${t([`prep_types.${label}`, label])} (${size}g)`,
																			value,
																		})
																	).sort((a, b) => a.label.localeCompare(b.label))}
																	onChange={({ value }) => {
																		updateField('idCategoria', {
																			target: { value },
																		})
																	}}
																	value={
																		currCat && {
																			value: currCat.idCategoria,
																			label: t([`prep_types.${currCat.nombreCategoria}`, currCat.nombreCategoria])
																		}
																	}
																/>
															</td>
														</tr>
														<tr>
															<td>{t("taste.title")}</td>
															<td>
																<Select
																	placeholder={t("taste.title")}
																	options={_sabor.map(
																		({ idSabor: value, sabor: label }) => ({
																			label: t([`taste.${label}`, label]),
																			value,
																		})
																	)}
																	onChange={({ value }) => {
																		updateField('sabor', {
																			target: {
																				value,
																			},
																		})
																	}}
																	value={
																		currSabor && {
																			value: currSabor.idSabor,
																			label: t([`taste.${currSabor.sabor}`, currSabor.sabor]),
																		}
																	}
																/>
															</td>
														</tr>
														<tr>
															<td>{t("state.title")}</td>
															<td>
																<Select
																	placeholder={t("state.title")}
																	options={_estados.map(
																		({ idEstado: value, nombreEstado: label }) => ({
																			label: t([`state.${label}`, label]),
																			value,
																		})
																	)}
																	onChange={({
																		value: idEstado,
																		label: nombreEstado,
																	}) => {
																		updateField('estadoReceta', {
																			target: {
																				value: { idEstado, nombreEstado },
																			},
																		})
																	}}
																	value={
																		currEstado && {
																			value: currEstado.idEstado,
																			label: t([`state.${currEstado.nombreEstado}`, currEstado.nombreEstado]),
																		}
																	}
																/>
															</td>
														</tr>
														<tr>
															<td>{t("letter")}</td>
															<td>
																<Select
																	placeholder={t("letter")}
																	options={_clasificaciones}
																	onChange={({ value }) => {
																		updateField('clasificacion', {
																			target: {
																				value,
																			},
																		})
																	}}
																	value={
																		currClasificacion && {
																			value: currClasificacion.value,
																			label: currClasificacion.label,
																		}
																	}
																/>
															</td>
														</tr>
													</tbody>
												</table>
											</>
										)}

										<div className="cont-select-nombre-receta">
											{dataFilled.idReceta && (
												<table>
													<tbody>
														<tr>
															<td>{t("original_recipe")}</td>
															<td>
																<b>{dataFilled.titulo}</b>
															</td>
														</tr>
														<tr>
															<td>{t("chef")}</td>
															<td>
																<b>{dataFilled.chefReferente}</b>
															</td>
														</tr>
														<tr>
															<td>{t("editor")}</td>
															<td>
																<b>{dataFilled.editor || '-'}</b>
															</td>
														</tr>
													</tbody>
												</table>
											)}
										</div>
									</div>
									<div className="mod-i">
										<ModIcons {...{ updateField, tipoDeVista, updatedData, _dificultad }} />
									</div>
								</div>
							</div>
							<div className="cyg-col-full">
								<div className="linea bg-verde"></div>
							</div>
						</div>
						<figure className="bg-textura bg-verde-rgba-2"></figure>
					</section>

					<section className="info">
						<div className="container container-3">
							<div className="cyg-row">
								<div className="cyg-col-right">
									<div className="as-scroll" onScroll={() => setPopoverPos(null)}>
										<Section
											title={t("ingredients")}
											className="bg-verde-rgba-1 mod-ingredientes edit-ingredientes"
										>
											<Ingredients
												{...{
													ingredientes,
													updateField,
													tipoDeVista,
													_insumos,
													handlePopoverContent,
													pasos,
													portionSize
												}}
											/>
										</Section>
										<Section
											title={t("nutritional_values")}
											subtitle={t("per_100g_of_food")}
											className="mod-valores edit-valores"
										>
											<NutriValues
												{...{
													valoresNutricionales,
													updateField,
													tipoDeVista,
													calNut,
												}}
											/>
										</Section>
										<button
											type="button"
											className={`btn btn-primary btn-enviar movil ${
												isDisabled && 'is-disabled'
											}`}
											onClick={() => !isDisabled && setShowDialog(true)}
										>
											<span>{t("save_recipe")} &gt;</span>
										</button>
									</div>
								</div>
								<div className="cyg-col-left pl-30">
									<div className="as-scroll">
										{!!serverMsg.length && (
											<div className="msj error">
												<ul>
													{serverMsg.map((msg, idx) => (
														<li key={`smsg${idx}`}>{msg}</li>
													))}
												</ul>
											</div>
										)}
										<Section
											title={`${t("preparation")} ${titulo}`}
											className="mod mod-preparacion edit-preparacion"
										>
											{tipoDeVista === "vista-previa" ? (
												<CookingStepsText
													steps={pasos}
												/>
											) : (
											<CookingSteps
												{...{
													pasos,
													updateField,
													_accesorios,
													_modo_velocidad,
													_operaciones,
													ingredientes
												}}
											/>)}
										</Section>
										<Section
											title={t("recommended_for")}
											className="mod mod-etiquetas vg edit-etiquetas"
										>
											<Tags
												noTagMsg={t("no_loaded_labels")}
												items={etiquetas}
												{...{ idReceta, _etiquetas, tipoDeVista, updateField }}
											/>
										</Section>
										<Article
											tipoDeVista={tipoDeVista}
											field="notasChef"
											content={notasChef}
											title={t("chef_notes")}
											className="mod edit-notas"
											updateField={updateField}
										/>
										<Article
											tipoDeVista={tipoDeVista}
											field="accesorios"
											content={accesorios}
											title={t("accessories")}
											className="mod edit-accesorios"
											updateField={updateField}
										/>
										{tipoDeVista !== 'vista-diseno' && (
											<section className="mod-imprimir" onClick={handlePrint}>
												<div id="btn-imprimir" className="transition">
													{t("print_recipe")}
													<IcoImprimir />
												</div>
											</section>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				{!loadComplete && (
					<div className="sweet-loading mt-5 pt-5">
						<BeatLoader color={'#509F2C'} loading={true} cssOverride={override} size={20} />
					</div>
				)}
				{showDialog && (
					<Dialog
						buttons={
							<>
								<button className="btn btn-primary btn-enviar" onClick={sendChanges}>
									{t("save")}
								</button>
								<button className="btn btn-primary btn-enviar btn-salir" onClick={() => setShowDialog(false)}>
									{t("back")}
								</button>
							</>
						}
					>
						<h4>{t("confirm_action")}</h4>
						<table>
							<tbody>
								<tr>
									<td>{t("recipe_name")}</td>
									<td>
										<h5>{titulo}</h5>
									</td>
								</tr>
								<tr>
									<td>{t("prep_types.title")}</td>
									<td>
										<Select
											placeholder={t("prep_types.title")}
											options={_categorias.map(
												({ idCategoria: value, nombreCategoria: label }) => ({
													value,
													label: t([`prep_types.${label}`, label])
												})
											)}
											onChange={({ value }) => {
												updateField('idCategoria', {
													target: { value },
												})
											}}
											value={
												currCat && {
													value: currCat.idCategoria,
													label: t([`prep_types.${ currCat.nombreCategoria}`,  currCat.nombreCategoria])
												}
											}
										/>
									</td>
								</tr>
								<tr>
									<td>{t("state.title")}</td>
									<td>
										<Select
											placeholder={t("state.title")}
											options={_estados.map(({ idEstado: value, nombreEstado: label }) => ({
												value,
												label: t([`state.${label}`, label])
											}))}
											onChange={({ value: idEstado, label: nombreEstado }) => {
												updateField('estadoReceta', {
													target: {
														value: { idEstado, nombreEstado },
													},
												})
											}}
											value={
												currEstado && {
													value: currEstado.idEstado,
													label: t([`state.${ currEstado.nombreEstado}`,  currEstado.nombreEstado])
												}
											}
										/>
									</td>
								</tr>
								<tr>
									<td>{t("action")}</td>
									<td>{accion}</td>
								</tr>
							</tbody>
						</table>
						<div></div>
					</Dialog>
				)}
				<div className="footer-buttons">
					<div className="container container-3">
						<div className="cyg-row">
							<div className="cyg-col-left">
								<p></p>
							</div>
							<div className="cyg-col-right">
								<button
									type="button"
									className={`btn btn-primary btn-enviar pc ${isDisabled && 'is-disabled'}`}
									onClick={() => !isDisabled && setShowDialog(true)}
								>
									<span>{t("save")}</span>
								</button>
								{tipoDeVista === 'vista-diseno' ? (
									<button
										type="button"
										className={`btn btn-primary btn-enviar btn-previa pc ${
											isDisabled && 'is-disabled'
										}`}
										onClick={() => !isDisabled && setVista('previa')}
									>
										<span>{t("preview")}</span>
									</button>
								) : (
									<button
										type="button"
										className={'btn btn-primary btn-enviar btn-previa pc'}
										onClick={() => setVista('diseno')}
									>
										<span>{t("edit")}</span>
									</button>
								)}
									<button
										type="button"
										className={'btn btn-primary btn-enviar btn-previa btn-salir pc'}
										onClick={() => navigate('/')}
									>
										<span>{t("exit")}</span>
									</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{x && contentPopover && (
				<div className="right-popup" style={{ left: x + 330, top: y + window.scrollY }}>
					{contentPopover}
				</div>
			)}
		</>
	)
}

export default RecipeEdit
