/* eslint-disable react-hooks/exhaustive-deps */
import { defaultRecipe, mapJson } from "../../providers/utils/FieldMaps";
import { PRIVILEGIOS } from "../../providers/utils/functions";
import { ReactComponent as IcoImprimir } from "../../images/svg/ico-imprimir.svg";
import { slugify } from "../../providers/service";
import { tienePermisoDe } from "../../providers/utils/functions";
import { useAuthProvider } from "../../providers/auth";
import { useDataProvider } from "../../providers/data";
import { useEffect, useState, useRef } from "react";
import { useIsVisible } from "../../hooks/useIsVisible";
import { useMultiData } from "../../providers/utils/MultiData";
import { useNavigate, useParams } from "react-router-dom";
import Article from "../../components/Article";
import BeatLoader from "react-spinners/BeatLoader";
import cookingRobot from "../../images/cooking-robot.png";
import CookingSteps from "../../components/edition/CookingSteps";
import CookingStepsText from '../../components/CookingSteps'
import Dialog from "../../components/Dialog";
import DragAndDrop from "../../components/upload/DragAndDrop";
import FiltersPicker from "../../components/filtersHeader/FilterPicker";
import Ingredients from "../../components/edition/Ingredients";
import ModIcons from "../../components/edition/ModIcons";
import NutriValues from "../../components/edition/NutriValues";
import Section from "../../components/Section";
import Select from "react-select";
import StarRating from "../../components/StarRating";
import Tags from "../../components/edition/Tags";
import { Tooltip } from "react-tooltip"
import { useTranslation } from "react-i18next";
import "../RecipeEdit/RecipeEditMobile.scss";
import "./RecipeCreateAI.scss";
import { createRecipe, convertRecipe, modifyRecipe, generateRecipeJSON } from "./gptUtils.js";

const override = {
	display: "block",
	margin: "0 auto",
	borderColor: "#509F2C",
	width: "fit-content"
};

const RecipeCreateAI = () => {
	const { t, i18n } = useTranslation();
	const { mode, id } = useParams();
	const [updatedData, setUpdatedData] = useState({});
	const [loadConfig, setLoadConfig] = useState({});
	const [loading, setLoading] = useState(false);
	const [preparacion, setPreparacion] = useState([]);
	const [serverMsg, setServerMsg] = useState([]);
	const [someErr, setSomeErr] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [uploadActions, setUploadActions] = useState({});
	const [tipoDeVista, setTipoDeVista] = useState("vista-previa");
	const infoSection = useRef(null);

	useEffect(() => {
		if (mode === "convertir") setUpdatedData({ porciones: 0 });
		else if (mode === "crear") setUpdatedData({ porciones: 4 });
	}, [mode]);

	const authProvider = useAuthProvider();
	const navigate = useNavigate();
	const loadData = useMultiData(loadConfig, ["api/filtros/list"]);

	useEffect(() => {
		if (loadConfig.length || !loadData.length) return;
		setLoadConfig(loadData[0]);
	}, [loadData]);

	const sendAlertMsg = ({ title, path = window.location.pathname, type = "success" }) => {
		navigate(path, {
			state: {
				message: {
					type,
					title
				}
			}
		});
	};

	// Separar campos en variables
	const {
		tipoEtiquetas: _etiquetas = [],
		dificultad: _dificultad = [],
		modo_velocidad: _modo_velocidad = [],
		nutrientes: _nutrientes = [],
		operaciones: _operaciones = [],
		accesorios: _accesorios = [],
		insumos: _insumos = [],
		categoria: _categorias = [],
		estados: _estados = [],
		sabor: _sabor = []
	} = loadConfig;

	const [recipesByCategory, setRecipesByCategory] = useState([]);
	useEffect(() => {
		const categories = _etiquetas.filter(({ idTipoEtiqueta }) => idTipoEtiqueta === 21);
		if (categories.length) setRecipesByCategory(categories[0].etiquetas);
	}, [_etiquetas]);

	const alphabet = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"Ñ",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z"
	];

	const _clasificaciones = alphabet.map((_, i) => ({
		value: alphabet[i],
		label: alphabet[i]
	}));

	useEffect(() => {
		if (mode === "modificar") fill_data(id);
	}, [mode]);

	const [porcionesOC, setPorcionesOC] = useState(0);
	const [tituloOC, setTituloOC] = useState("");

	const fill_data = async idPlantilla => {
		setLoading(true);
		const { data } = await dataProvider.getOne(`api/recetas/${idPlantilla}`, { headers });
		if (data) {
			const translate = Math.max(data?.traducciones?.findIndex(({ codLenguaje }) => codLenguaje === i18n.resolvedLanguage) || 0, 0)
			const mixData = { ...data, ...data.traducciones[translate] };
			setUpdatedData(mixData);

			// Datos originales
			setInputTitulo(mixData.titulo);
			setPorcionesOC(mixData.porciones);
			setTituloOC(mixData.titulo);
			setPreparacion(mixData.preparacion); // ?
		}
		setLoading(false);
	};

	const dataProvider = useDataProvider();
	const handlePrint = () => {
		window.print();
	};

	const userData = authProvider.getIdentity();
	const { EDITAR_SCORE } = PRIVILEGIOS;

	const headers = {
		"Content-Type": "application/json",
		Accept: "*/*",
		Authorization: userData.jwt
	};

	const {
		idReceta,
		titulo = "",
		fotosReceta = uploadActions.images || [],
		etiquetas,
		notasChef,
		accesorios,
		ingredientes = [],
		valoresNutricionales = _nutrientes.map(n => ({...n, ...n.unidad, nombreNutriente: n.nutriente})),
		pasos = [],
		idCategoria,
		clasificacion,
		estadoReceta: { idEstado = 2 } = {},
		sabor,
		puntuacionAdmin
	} = updatedData;

	useEffect(() => {
		if (pasos && pasos.length) {
			generateSteps();
		} else {
			setSomeErr(true);
			setPreparacion([]);
		}
		const asScroll = document.querySelectorAll(".as-scroll");
		asScroll.forEach(el => {
			el.scrollTo({ top: 0, behavior: "smooth" });
		});
	}, [tipoDeVista]);

	const updateField = (name, { target: { value } }) => {
		setServerMsg([]);
		setUpdatedData({ ...updatedData, [name]: value });
	};

	const sendChanges = async isVariante => {
		const { onUpload, images } = uploadActions;
		let _updatedData = { ...updatedData };
		setShowDialog(false);

		if (images && images.length) {
			let res = await onUpload();
			const { original_filename, format, secure_url } = res;

			const fotosReceta = [
				{
					nombreImagen: `${original_filename}.${format}`,
					urlImagen: secure_url
				}
			];

			_updatedData = { ...updatedData, fotosReceta };
		}

		const json = mapJson("receta", _updatedData, defaultRecipe);

		const info = {
			headers,
			data: { ...json, ...(id && isVariante ? { idRecetaOriginal: id } : {}) }
		};

		async function CrearReceta() {
			try {
				const { data } = await dataProvider.create(`api/recetas`, info);
				const title = "La receta se ha creado satisfactoriamente...";
				const slug_title = slugify(titulo);
				const { idReceta } = data;
				sendAlertMsg({
					path: `/receta/${idReceta}/${slug_title}`,
					title
				});
				window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			} catch (e) {
				const {
					response: {
						data: { errors = [] }
					}
				} = e;
				setServerMsg((errors || []).map(({ errorMsg }) => errorMsg));
				window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
				sendAlertMsg({
					title: "Ocurrió un error al enviar los datos, verifique los datos y vuelva a intentarlo...",
					type: "danger"
				});
			}
		}
		CrearReceta();
	};

	const [fotoPrincipal = { urlImagen: cookingRobot }] = fotosReceta || [];

	const loadComplete = !!Object.keys(loadConfig).length && !loading;

	const handleUploadFile = actions => {
		setUploadActions(actions);
	};

	const currClasificacion = (_clasificaciones.length && _clasificaciones.find(({ value: v }) => v === clasificacion)) || {
		label: "A",
		value: "A"
	};

	const currCat = (_categorias.length && _categorias.find(({ idCategoria: id }) => id === idCategoria)) || {
		idCategoria: 0,
		nombreCategoria: "ninguna"
	};

	const currEstado = (_estados.length && _estados.find(({ idEstado: id }) => id === idEstado)) || {
		idEstado: 2,
		nombreEstado: "Privadas"
	};

	const currSabor = (_sabor.length && _sabor.find(({ idSabor: id }) => id === sabor)) || {
		idSabor: 0,
		sabor: "(Ninguno)"
	};

	const [popover, handlePopoverContent] = useState(null);
	const [popoverPos, setPopoverPos] = useState(null);
	const { contentPopover = null, elementPopover } = popover || {};

	const popoverIsVisible = useIsVisible(elementPopover);

	useEffect(() => {
		const { elementPopover = null } = popover || {};
		if (elementPopover && popoverIsVisible) {
			const rect = elementPopover.getBoundingClientRect();
			setPopoverPos(rect);
		} else setPopoverPos(null);
	}, [popover, popoverIsVisible]);
	const { x = null, y = null } = popoverPos || {};

	const [calNut, setCalNut] = useState([]);
	const [lastNutrientes, setLastNutrientes] = useState("");

	const calcularNutrientes = async _ingredientes => {
		const ingredientes = _ingredientes.filter(({ idInsumo, cantidad }) => !!idInsumo && !!cantidad);
		if (JSON.stringify(ingredientes) === lastNutrientes) return;
		setLastNutrientes(JSON.stringify(ingredientes));
		if (ingredientes.length) {
			const info = {
				headers,
				data: {
					ingredientes
				}
			};
			const {
				data: { nutrientes = [] }
			} = await dataProvider.create(`api/nutrientes/generate`, info);
			setCalNut(nutrientes);
		}
	};

	async function generateSteps() {
		setLoading(true);
		const info = {
			headers,
			data: updatedData
		};
		try {
			const {
				data: { preparacion = [] }
			} = (await dataProvider.create(`api/recetas/preview`, info)) || {};
			setPreparacion(preparacion);
			setSomeErr(false);
		} catch (e) {
			sendAlertMsg({
				title: "Los datos no están completos...",
				type: "danger"
			});
		}
		setLoading(false);
	}

	const [aiLoading, setAiLoading] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState({
		nombreEtiqueta: "Haz click en uno de los iconos de categoría"
	});
	const defaultSubCategory = {
		index: 0,
		categoria: {
			nombreCategoria: "ninguna"
		}
	};
	const [subCategory, setSubCategory] = useState(defaultSubCategory);
	useEffect(() => {
		if (selectedCategory.categoriasAsociadas && selectedCategory.categoriasAsociadas.length)
			setSubCategory(selectedCategory.categoriasAsociadas[0]);
		else setSubCategory(defaultSubCategory);
	}, [selectedCategory]);

	useEffect(() => {
		const { ingredientes = [] } = updatedData;
		calcularNutrientes(ingredientes);
	}, [updatedData]);

	const [inputTitulo, setInputTitulo] = useState("");
	const [inputIngredientes, setInputIngredientes] = useState("");
	const [inputObservaciones, setInputObservaciones] = useState("");
	const [inputPreparacion, setInputPreparacion] = useState("");

	function RefreshData(data, updateTitle) {
		if (updateTitle)
			updatedData.titulo = data.titulo;
		updatedData.ingredientes = data.ingredientes;
		updatedData.pasos = data.pasos;
		generateSteps();
		calcularNutrientes(data.ingredientes);
	}

	async function SubmitCreate() {
		setAiLoading(true);

		var userQuery = "";
		if (mode === "convertir") userQuery = convertRecipe(inputTitulo, inputPreparacion);
		else if (mode === "crear")
			userQuery = createRecipe(
				inputTitulo,
				selectedCategory?.nombreEtiqueta,
				subCategory?.categoria.nombreCategoria,
				inputIngredientes,
				inputObservaciones,
				updatedData.porciones
			);
		else {
			if (inputTitulo === tituloOC && updatedData.porciones === porcionesOC && !inputIngredientes && !inputObservaciones) return;
			userQuery = modifyRecipe(inputTitulo, tituloOC, updatedData.porciones, porcionesOC, inputIngredientes, inputObservaciones);
		}
		const data = await generateRecipeJSON(userQuery, subCategory?.recetas || [id], _insumos, mode === "convertir");

		setAiLoading(false);
		RefreshData(data, mode !== "modificar");
		infoSection.current.scrollIntoView();
	}

	const [portionSize, setPortionSize] = useState(0);
	useEffect(() => {
		const tipoPreparacion = _categorias.find(({ idCategoria }) => updatedData.idCategoria === idCategoria);
		if (tipoPreparacion && tipoPreparacion.tamanioPorcion) {
			setPortionSize(tipoPreparacion.tamanioPorcion);
		}
	}, [updatedData.idCategoria, _categorias]);

	return (
		<>
			<div className="edit-page-ia editing">
				<div>
					<section id="intro" className={`recipe-create`}>
						<div className="container container-3">
							<div className="cyg-row d-flex">
								<div className="cyg-col-left">
									<div className="mod-i">
										<ModIcons {...{ updateField, tipoDeVista: "vista-diseno", updatedData, _dificultad }} />
									</div>
									{tipoDeVista === "vista-diseno" ? (
										<DragAndDrop handleUploadFile={handleUploadFile} value={fotoPrincipal} />
									) : (
										<figure className="ratio-75">
											{fotoPrincipal && (
												<img src={`${fotoPrincipal.urlImagen || fotoPrincipal.dataURL}`} alt="cooking-robot" />
											)}
										</figure>
									)}
								</div>
								<div className="cyg-col-left">
									<div className="cont">
										<header className="mod-input-container">
											{mode === "convertir" && <h4>Convertir una receta para preparla en iChef</h4>}
											{mode === "crear" && <h4>Crear receta de forma asistida</h4>}
											{mode === "modificar" && <h4>Crear variante asistida por Maia</h4>}
										</header>
										{mode !== "crear" ? (
											<input
												type="text"
												placeholder="Título de la receta"
												value={inputTitulo}
												onChange={c => {
													updateField("titulo", c);
													setInputTitulo(c.target.value);
												}}
												data-tooltip-id="recipe-ia-tooltip"
												data-tooltip-place="bottom"
												data-tooltip-content="Modifica el titulo para crear una nueva receta"
											/>
										) : (
											<input
												type="text"
												placeholder="Título o descripción de la receta"
												value={inputTitulo}
												onChange={c => {
													updateField("titulo", c);
													setInputTitulo(c.target.value);
												}}
											/>
										)}
										{mode !== "modificar" && (
											<FiltersPicker
												etiquetas={recipesByCategory}
												selectedFilter={selectedCategory}
												setSelectedFilter={setSelectedCategory}
											/>
										)}
										<table>
											<tbody>
												{mode !== "modificar" && (
													<>
														<tr>
															<td>Categoría:</td>
															<td>{selectedCategory.nombreEtiqueta}</td>
														</tr>
														<tr>
															<td>Tipo de preparación:</td>
															<td>
																<Select
																	noOptionsMessage={() => "No hay tipos para está categoría."}
																	options={selectedCategory.categoriasAsociadas?.map((entry, i) => ({
																		label: entry.categoria.nombreCategoria,
																		value: i
																	}))}
																	value={
																		subCategory && {
																			label: subCategory.categoria.nombreCategoria,
																			value: subCategory.index
																		}
																	}
																	onChange={({ value }) => {
																		setSubCategory(selectedCategory.categoriasAsociadas[value]);
																	}}
																/>
															</td>
														</tr>
													</>
												)}
												{mode !== "convertir" && (
													<>
														<tr>
															<td>Ingredientes:</td>
															<td>
																<input
																	type="text"
																	placeholder="Papas, zanahoria, pollo, etc."
																	value={inputIngredientes}
																	onChange={c => setInputIngredientes(c.target.value)}
																	data-tooltip-id="recipe-ia-tooltip"
																	data-tooltip-place="bottom"
																	data-tooltip-content="Agrega o cambia ingredientes de la receta"
																/>
															</td>
														</tr>
														<tr>
															<td>Características:</td>
															<td>
																<input
																	type="text"
																	placeholder="Libre de glúten, para celíacos, etc."
																	value={inputObservaciones}
																	onChange={c => {
																		setInputObservaciones(c.target.value);
																		updateField("descripcion", c);
																	}}
																	data-tooltip-id="recipe-ia-tooltip"
																	data-tooltip-place="bottom"
																	data-tooltip-content="Agrega características"
																/>
															</td>
														</tr>
													</>
												)}
											</tbody>
										</table>
										{mode === "convertir" && (
											<textarea
												className="editing-text"
												type="text"
												placeholder="Texto de la receta"
												value={inputPreparacion}
												rows={9}
												onChange={c => {
													setInputPreparacion(c.target.value);
												}}
											/>
										)}
										<button type="button" className="btn btn-primary" onClick={SubmitCreate}
											data-tooltip-id="recipe-ia-tooltip"
											data-tooltip-place="right"
											data-tooltip-content="Modifica la receta usando nuestro asistente Maia"
										>
											{mode === "convertir" ? "Convertir para iChef" : "Modificar con Maia"}
										</button>
									</div>
								</div>
								<Tooltip id="recipe-ia-tooltip" />
							</div>
							<div className="cyg-col-full">
								<div className="linea bg-verde"></div>
							</div>
						</div>
						<figure className="bg-textura bg-textura-ia bg-verde-rgba-2"></figure>
					</section>
					<section className="info" ref={infoSection}>
						<div className="container container-3">
							<div className="cyg-row">
								<div className="cyg-col-right">
									<div className="as-scroll" onScroll={() => setPopoverPos(null)}>
										<Section title="Ingredientes" className="bg-verde-rgba-1 mod-ingredientes edit-ingredientes">
											<Ingredients
												{...{
													ingredientes,
													updateField,
													tipoDeVista,
													_insumos,
													handlePopoverContent,
													pasos,
													portionSize
												}}
											/>
										</Section>
										<Section
											title={t("nutritional_values")}
											subtitle={t("per_100g_of_food")}
											className="mod-valores edit-valores"
										>
											<NutriValues
												{...{
													valoresNutricionales,
													updateField,
													tipoDeVista,
													calNut
												}}
											/>
										</Section>
									</div>
								</div>
								<div className="cyg-col-left pl-30">
									<div className="as-scroll">
										{!!serverMsg.length && (
											<div className="msj error">
												<ul>
													{serverMsg.map((msg, idx) => (
														<li key={`smsg${idx}`}>{msg}</li>
													))}
												</ul>
											</div>
										)}
										<Section title={`Preparación de ${titulo}`} className="mod mod-preparacion edit-preparacion">
											{tipoDeVista === "vista-previa" ? (
												<CookingStepsText
													steps={pasos}
												/>
											) : (
											<CookingSteps
												{...{
													pasos,
													updateField,
													tipoDeVista,
													_accesorios,
													_modo_velocidad,
													_operaciones,
													preparacion
												}}
											/>
											)}
										</Section>
										{tipoDeVista === "vista-diseno" && (
											<table>
												<tbody>
													<tr>
														<td>Tipo de preparación:</td>
														<td>
															<Select
																placeholder="- Tipo de preparación -"
																options={_categorias.map(
																	({ idCategoria: value, nombreCategoria: label }) => ({
																		label,
																		value
																	})
																)}
																onChange={({ value }) => {
																	updateField("idCategoria", {
																		target: { value }
																	});
																}}
																value={
																	currCat && {
																		value: currCat.idCategoria,
																		label: currCat.nombreCategoria
																	}
																}
															/>
														</td>
													</tr>
													<tr>
														<td>Sabor:</td>
														<td>
															<Select
																placeholder="- Sabor -"
																options={_sabor.map(({ idSabor: value, sabor: label }) => ({
																	label,
																	value
																}))}
																onChange={({ value }) => {
																	updateField("sabor", {
																		target: {
																			value
																		}
																	});
																}}
																value={
																	currSabor && {
																		value: currSabor.idSabor,
																		label: currSabor.sabor
																	}
																}
															/>
														</td>
													</tr>
													<tr>
														<td>Estado de la receta:</td>
														<td>
															<Select
																placeholder="- Estado -"
																options={_estados.map(({ idEstado: value, nombreEstado: label }) => ({
																	label,
																	value
																}))}
																onChange={({ value: idEstado, label: nombreEstado }) => {
																	updateField("estadoReceta", {
																		target: {
																			value: { idEstado, nombreEstado }
																		}
																	});
																}}
																value={
																	currEstado && {
																		value: currEstado.idEstado,
																		label: currEstado.nombreEstado
																	}
																}
															/>
														</td>
													</tr>
													<tr>
														<td>Letra:</td>
														<td>
															<Select
																placeholder="- Letra -"
																options={_clasificaciones}
																onChange={({ value }) => {
																	updateField("clasificacion", {
																		target: {
																			value
																		}
																	});
																}}
																value={
																	currClasificacion && {
																		value: currClasificacion.value,
																		label: currClasificacion.label
																	}
																}
															/>
														</td>
													</tr>
													{tienePermisoDe(EDITAR_SCORE, userData) && (
														<tr>
															<td>Puntuación:</td>
															<td style={{ padding: "6px 0px" }}>
																<StarRating
																	score={puntuacionAdmin}
																	iconClass="icon-estrella-amarilla"
																	onClickScore={value =>
																		updateField("puntuacionAdmin", {
																			target: { value }
																		})
																	}
																/>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										)}
										<Section title="Recomendado para" className="mod mod-etiquetas vg edit-etiquetas">
											<Tags
												noTagMsg={t("no_loaded_labels")}
												items={etiquetas}
												{...{ idReceta, _etiquetas, tipoDeVista, updateField }}
											></Tags>
										</Section>
										<Article
											tipoDeVista={tipoDeVista}
											field="notasChef"
											content={notasChef}
											title="Notas del Chef"
											className="mod edit-notas"
											updateField={updateField}
										/>
										<Article
											tipoDeVista={tipoDeVista}
											field="accesorios"
											content={accesorios}
											title="Accesorios"
											className="mod edit-accesorios"
											updateField={updateField}
										/>
										{tipoDeVista !== "vista-diseno" && (
											<section className="mod-imprimir" onClick={handlePrint}>
												<div id="btn-imprimir" className="transition">
													Imprimir receta.
													<IcoImprimir />
												</div>
											</section>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
				{!loadComplete && (
					<div className="sweet-loading mt-5 pt-5">
						<BeatLoader color={"#509F2C"} loading={true} cssOverride={override} size={20} />
					</div>
				)}
				{aiLoading && (
					<div className="sweet-loading mt-5 pt-5">
						<section className="bg-verde-rgba-1 mod-ingredientes">
							<BeatLoader color={"#FFFFFF"} loading={true} cssOverride={override} size={20} />
							<header className="loading-tit tx-white">
								<h3>Generando receta...</h3>
							</header>
							<article className="text-center tx-white">
								<p>Estamos procesando su solicitud.</p>
								<p>Este proceso puede demorar hasta 2 minutos.</p>
							</article>
						</section>
					</div>
				)}
				{showDialog && (
					<Dialog
						buttons={
							<>
								{(!id && titulo || titulo !== tituloOC) && (
									<button className="btn btn-primary btn-enviar" onClick={sendChanges}>
										Crear nueva
									</button>
								)}
								{id && inputObservaciones && (
									<button className="btn btn-primary btn-enviar" onClick={() => sendChanges(true)}>
										Crear variante
									</button>
								)}
								<button className="btn btn-primary btn-enviar btn-salir" onClick={() => setShowDialog(false)}>
									Regresar
								</button>
							</>
						}
					>
						<h4>Confirme su acción:</h4>
						<table>
							<tbody>
								<tr>
									<td>Nombre de la receta:</td>
									<td>
										<h5>{titulo}</h5>
									</td>
								</tr>
								<tr>
									<td>Porciones:</td>
									<td>
										<h5>{updatedData.porciones}</h5>
									</td>
								</tr>
								<tr>
									<td>Subtítulo:</td>
									<td>
										<input
											type="text"
											placeholder="Subtitulo de la variante"
											value={inputObservaciones}
											onChange={c => {
												setInputObservaciones(c.target.value);
												updateField("descripcion", c);
											}}
										/>
									</td>
								</tr>
								<tr>
									<td>Tipo de preparación:</td>
									<td>
										<Select
											placeholder="- Tipo de preparación -"
											options={_categorias.map(({ idCategoria: value, nombreCategoria: label }) => ({
												label,
												value
											}))}
											onChange={({ value }) => {
												updateField("idCategoria", {
													target: { value }
												});
											}}
											value={
												currCat && {
													value: currCat.idCategoria,
													label: currCat.nombreCategoria
												}
											}
										/>
									</td>
								</tr>
								<tr>
									<td>Estado:</td>
									<td>
										<Select
											placeholder="- Estado -"
											options={_estados.map(({ idEstado: value, nombreEstado: label }) => ({
												label,
												value
											}))}
											onChange={({ value: idEstado, label: nombreEstado }) => {
												updateField("estadoReceta", {
													target: {
														value: { idEstado, nombreEstado }
													}
												});
											}}
											value={
												currEstado && {
													value: currEstado.idEstado,
													label: currEstado.nombreEstado
												}
											}
										/>
									</td>
								</tr>
							</tbody>
						</table>
						<div></div>
					</Dialog>
				)}
				<div className="footer-buttons">
					<div className="container container-3">
						<div className="cyg-row">
							<div className="cyg-col-left">
								<p></p>
							</div>
							<div className="cyg-col-right">
								{!someErr && (
									<button
										type="button"
										className="btn btn-primary btn-enviar btn-previa pc"
										onClick={() => setShowDialog(true)}
									>
										<span>Guardar</span>
									</button>
								)}
								{tipoDeVista === "vista-diseno" ? (
									<button
										type="button"
										className="btn btn-primary btn-enviar btn-previa pc"
										onClick={() => setTipoDeVista("vista-previa")}
									>
										<span>Vista</span>
									</button>
								) : (
									<button
										type="button"
										className={"btn btn-primary btn-enviar btn-previa pc"}
										onClick={() => setTipoDeVista("vista-diseno")}
									>
										<span>Editar</span>
									</button>
								)}
								<button
									type="button"
									className={"btn btn-primary btn-enviar btn-previa btn-salir pc"}
									onClick={() => navigate("/")}
								>
									<span>Salir</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{x && contentPopover && (
				<div className="left-popup" style={{ left: x - 210, top: y + window.scrollY }}>
					{contentPopover}
				</div>
			)}
		</>
	);
};

export default RecipeCreateAI;
