import icoTwitterSvg from '../images/svg/ico-twitter.svg'
import icoFacebookSvg from '../images/svg/ico-facebook.svg'
import icoYoutubeSvg from '../images/svg/ico-youtube.svg'

function Footer({ showInfo = true }) {
	return (
		<footer id="footer">
			<div className="container container-2">
				<div className="cyg-row">
					{showInfo && (
						<div className="cyg-col-full d-flex">
							<div className="mod-redes">
								<a href="./#">
									<img className="transition" src={icoTwitterSvg} alt="X" />
								</a>
								<a href="./#">
									<img className="transition" src={icoFacebookSvg} alt="Facebook" />
								</a>
								<a href="./#">
									<img className="transition" src={icoYoutubeSvg} alt="Youtube" />
								</a>
							</div>

							<address>
								<a href="./#" className="ico-tel">
									2333 444 555
								</a>
								<br />
								<a href="mailto:info@ichef.com.uy" className="ico-correo mb-0">
									info@ichef.com.uy
								</a>
							</address>

							<nav>
								<div>
									<a href="./#">Política de privacidad</a>
									<a href="./#" className="mb-0">
										Términos de uso
									</a>
								</div>

								<div>
									<a href="./#">Información legal</a>
									<a href="./#" className="mb-0">
										Aviso legal
									</a>
								</div>
							</nav>
						</div>
					)}
				</div>
			</div>
		</footer>
	)
}

export default Footer
