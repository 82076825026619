import { isEmpty } from 'lodash'

export class FilterProvider {
	constructor(filters, store = null) {
		this.filters = filters
		this.store = store
		this.isEnabledLocalStore = store ? true : false
		this.isEnabledRouteTracking = false
		this.filtersActivated = []
	}

	enableLocalStore(enable = true) {
		this.isEnabledLocalStore = enable
	}

	updateFilter(name, value = true) {
		this.filters[name] = value
		if (this.isEnabledLocalStore) {
			this.store.updateFilters({ ...this.filters })
		}
	}

	deleteFilter(name) {
		delete this.filters[name]
		if (this.isEnabledLocalStore) {
			this.store.updateFilters({ ...this.filters })
		}
	}

	deleteAllFilters() {
		delete this.filters
		this.filters = {}
		if (this.isEnabledLocalStore) {
			this.store.updateFilters({ ...this.filters })
		}
	}

	getFilter(name) {
		return typeof this.filters[name] !== 'undefined' ? this.filters[name] : null
	}

	getFilters() {
		return this.filters
	}

	checkFiltersActivated() {
		const filterKeys = Object.keys(this.filters)
		this.filtersActivated =
			filterKeys.length > 0 ? filterKeys.filter((key) => !isEmpty(this.filters[key])).map((key) => key) : []
	}

	isThereFilterActivated() {
		this.checkFiltersActivated()

		return this.filtersActivated.length > 0
	}
}
