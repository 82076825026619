import { Link } from 'react-router-dom'
import { slugify } from '../providers/service/Utils'
import { useState } from 'react'
import FavoriteButton from './FavoriteButton'
import RecipeMenuButton from './RecipeMenuButton'
import StarRating from './StarRating'
import { useAuthProvider } from '../providers/auth'
import { ReactComponent as IcoFac } from '../images/svg/ico-1-fac.svg'
import { ReactComponent as IcoMed } from '../images/svg/ico-1-med.svg'
import { ReactComponent as IcoDif } from '../images/svg/ico-1-dif.svg'
import EditRecipeButton from './EditRecipeButton'
import { DIFFICULTY_LEVELS, PRIVILEGIOS, RECIPE_STATUS, tienePermisoDe } from '../providers/utils/functions'
import { useTranslation } from "react-i18next";

function formatTime(time) {
	if (!time) return '-- min'
	const hours = Math.floor(time / 3600)
	const minutes = Math.floor((time % 3600) / 60)
	const seconds = Math.floor(time % 60)

	if (hours > 0) {
		if (minutes > 0) return `${hours} h ${minutes} min`
		else return `${hours} h`
	} else if (seconds > 0) return `${minutes} min ${seconds} seg`
	return `${minutes} min`
}

function RecipeItem(props) {
	const { t } = useTranslation();
	const { recipeData, difficultiesData } = props

	//Proveedor de autenticación
	const authProvider = useAuthProvider()
	const favorites = authProvider.getFavorites()
	const isFavourite = favorites.length ? favorites.includes(Number(recipeData.idReceta)) : null

	const isAuthenticated = authProvider.checkAuth()
	const userData = authProvider.getIdentity()

	const urlImagen =
		recipeData.fotosReceta && recipeData.fotosReceta.urlImagen ? recipeData.fotosReceta.urlImagen : null

	const recipeTitleSlug = slugify(recipeData.titulo || '')

	const [mouseEnterTimeoutId, setMouseEnterTimeoutId] = useState(null)
	const [isMouseEnter, setIsMouseEnter] = useState(false)

	const handleHover = (e) => {
		e.preventDefault()
		e.stopPropagation()

		if (e.type === 'mouseenter') {
			if (mouseEnterTimeoutId) {
				clearTimeout(mouseEnterTimeoutId)
				setMouseEnterTimeoutId(null)
			} else {
				setMouseEnterTimeoutId(
					setTimeout(() => {
						setIsMouseEnter(true)
						setMouseEnterTimeoutId(null)
					}, 300)
				)
			}
		} else if (e.type === 'mouseleave') {
			if (mouseEnterTimeoutId) {
				clearTimeout(mouseEnterTimeoutId)
				setMouseEnterTimeoutId(null)
			} else {
				setMouseEnterTimeoutId(
					setTimeout(() => {
						setIsMouseEnter(false)
						setMouseEnterTimeoutId(null)
					}, 700)
				)
			}
		}
	}

	const { EDITAR_PROPIAS, CREAR_VARIANTE, EDITAR_TODAS } = PRIVILEGIOS
	const { PUBLIC } = RECIPE_STATUS

	const status = recipeData && (Number.isInteger(recipeData.estado) ? recipeData.estado : recipeData.estado.idEstado)

	const createdByUser =
		recipeData &&
		((recipeData.chefReferente &&
			userData.usuario &&
			recipeData.chefReferente.trim() === userData.usuario.trim()) ||
			(recipeData.adaptador && userData.usuario && recipeData.adaptador.trim() === userData.usuario.trim()))

	const create_action =
		isAuthenticated &&
		status &&
		[PUBLIC].includes(status) &&
		tienePermisoDe(CREAR_VARIANTE, userData)
	const edit_action =
		isAuthenticated &&
		(tienePermisoDe(EDITAR_TODAS, userData) ||
			(createdByUser &&
				tienePermisoDe(EDITAR_PROPIAS, userData) &&
				status &&
				![PUBLIC].includes(status)))
	
	const difficulty = difficultiesData ? difficultiesData[recipeData.idDificultad] : '-';

	return (
		<div className="cont-receta" onMouseEnter={handleHover} onMouseLeave={handleHover}>
			<div className={`receta gr recipe-transition`}>
				{isMouseEnter && (
					<>
						{isAuthenticated && (
							<FavoriteButton
								idReceta={recipeData.idReceta}
								className={`mas transition ${isFavourite ? 'activo' : ''}`}
								topClassName={`top-1`}
								itsOnPage={false}
							/>
						)}
						{create_action && (
							<EditRecipeButton
								recipeData={recipeData}
								className="variantes transition mb-0 top-2"
								action="variante-receta"
							/>
						)}
						{edit_action && (
							<EditRecipeButton
								recipeData={recipeData}
								className={`editar transition mb-0 top-${create_action ? 3 : 2}`}
								action="editar-receta"
							/>
						)}
						<RecipeMenuButton
							recipeData={recipeData}
							slugTitle={recipeTitleSlug}
							isFavorite={isFavourite}
							index={ (edit_action && create_action) ? 4 : (edit_action || create_action) ? 3 : 2 }
						/>
						<Link to={`/receta/${recipeData.idReceta}/${recipeTitleSlug}`} className="footer">
							<StarRating score={recipeData.puntuacion} iconClass="icon-estrella" />
							<h2>{recipeData.titulo}</h2>
							<h4>{recipeData.descripcion}</h4>
						</Link>
						<Link to={`/receta/${recipeData.idReceta}/${recipeTitleSlug}`} className="mod-iconos">
							<div className="facil">
								<i>
									{recipeData.idDificultad === DIFFICULTY_LEVELS.EASY ? (
										<IcoFac />
									) : recipeData.idDificultad === DIFFICULTY_LEVELS.INTERMEDIATE ? (
										<IcoMed />
									) : recipeData.idDificultad === DIFFICULTY_LEVELS.HARD ? (
										<IcoDif />
									) : (
										<></>
									)}
								</i>
								<span>{t([`difficulty.${difficulty}`, difficulty])}</span>
							</div>
							<div className="min">
								<i className="icon-svg icon-tiempo-chef-blanco"></i>
								<span>{formatTime(recipeData.tiempoChef)}</span>
							</div>
							<div className="tiempo">
								<i className="icon-svg icon-tiempo-blanco"></i>
								<span>{formatTime(recipeData.tiempoPreparacion)}</span>
							</div>
							<div className="prep">
								<i className="icon-svg icon-porcion-blanco"></i>
								<span>
									{recipeData.porciones ? recipeData.porciones : '--'}
									&nbsp; {t("servings")}
								</span>
							</div>
						</Link>
						<Link to={`/receta/${recipeData.idReceta}/${recipeTitleSlug}`} className="bg-degrade"></Link>
						{urlImagen && <img src={`${urlImagen}`} alt={`${recipeData.titulo}`} />}
					</>
				)}
			</div>
			<div className={`receta ch transition`}>
				<Link to={`/receta/${recipeData.idReceta}/${recipeTitleSlug}`} className="footer">
					<StarRating score={recipeData.puntuacion} iconClass="icon-estrella" />
					<h2>{recipeData.titulo}</h2>
					<h4>{recipeData.descripcion}</h4>
				</Link>
				<Link to={`/receta/${recipeData.idReceta}/${recipeTitleSlug}`} className="bg-degrade"></Link>
				<figure className="ratio-100">
					{urlImagen && <img src={`${urlImagen}`} alt={`${recipeData.titulo}`} />}
				</figure>
			</div>
		</div>
	)
}

export default RecipeItem
