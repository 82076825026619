import { useEffect, useState, forwardRef } from 'react'
import { useFilterProvider } from '../../providers/filter'

import './InputAutoComplete.scss'

const InputAutoComplete = forwardRef(
	({ pholder, inputValue, suggestionsList, onSelected, onChange, onFocus, onBlur, onKeyUp, onBack }, ref) => {
		//Proveedor de filtros
		const filterProvider = useFilterProvider()
		const searchFilter = filterProvider.getFilter('search')

		const [suggestions, setSugesstions] = useState([])
		const [isShowSuggestions, showSuggestions] = useState(false)
		const [selectedVal, setSelectedVal] = useState(inputValue ?? '')

		useEffect(() => {
			if (suggestionsList) {
				setSugesstions(suggestionsList)
			}
		}, [suggestionsList])

		useEffect(() => {
			if (searchFilter !== null) {
				setSelectedVal(searchFilter)
			}
		}, [searchFilter])

		const handlerChangeSuggestionsList = (value) => {
			setSugesstions(suggestionsList)
		}

		const handleChangeValue = (value) => {
			showSuggestions(value ? true : false)
			setSelectedVal(value)
			onChange(value)
		}

		const hideSuggestions = (value) => {
			onSelected(value)
			setSelectedVal(value)
			showSuggestions(false)
		}
		const handleClear = () => {
			ref?.current?.focus()
			setSelectedVal('')
		}

		return (
			<div className="input-autocomplete">
				<div className="form-control-auto">
					<i className="btn-back" onClick={onBack} />

					<input
						ref={ref}
						placeholder={pholder}
						type="text"
						value={selectedVal}
						onChange={(e) => {
							const value = e.target.value

							handleChangeValue(value)
							onChange && onChange(value)
						}}
						onKeyUp={(e) => {
							const value = e.target.value

							handlerChangeSuggestionsList(value)
							onKeyUp && onKeyUp(value)
						}}
						onFocus={(e) => {
							const value = e.target.value

							handleChangeValue(value)
							onFocus && onFocus(value)
						}}
						onBlur={(e) => {
							const value = e.target.value

							// showSuggestions(false)

							onBlur && onBlur(value)
						}}
					/>
					{selectedVal && <i className="btn-clear" onClick={handleClear} />}
				</div>

				{isShowSuggestions && (
					<div className="suggestions">
						{suggestions.map((item, idx) => (
							<div
								key={'' + item.id + idx}
								className="suggestion-item"
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()

									hideSuggestions(item.suggestion)
								}}
							>
								<i className="ico-search" />
								<span>{item.suggestion}</span>
							</div>
						))}
					</div>
				)}
			</div>
		)
	}
)

export default InputAutoComplete
