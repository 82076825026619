const Dialog = (props) => {
	const { children, buttons } = props

	return (
		<div className="msg-dialog">
			<div className="msg-content">
				<div>{children}</div>
				<div className="msg-buttons">{buttons}</div>
			</div>
		</div>
	)
}

export default Dialog
