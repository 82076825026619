import axios from 'axios'

export const defaultFetch = (url, options = {}) => {
	return axios.create({
		baseURL: url,
		timeout: options.timeout ? options.timeout : 120000,
		...(options.headers ? { headers: options.headers } : {}),
	})
}

export const defaultDataProvider = (entrypoint, httpClient = defaultFetch) => ({
	getList: async (resource, options = { params: {} }) => {
		try {
			const {
				data: dataJson,
				headers,
				status,
			} = await httpClient(entrypoint, options).get(resource, { params: options.params })

			return {
				headers: headers,
				status: status,
				data: typeof dataJson['datos'] !== 'undefined' ? dataJson['datos'] : dataJson,
				message: typeof dataJson['message'] !== 'undefined' ? dataJson['message'] : '',
				code: typeof dataJson['code'] !== 'undefined' ? dataJson['code'] : '',
				total: parseInt(
					dataJson
						? typeof dataJson['total'] !== 'undefined'
							? dataJson['total']
							: typeof dataJson['datos'] !== 'undefined' && dataJson['datos'].length
							? dataJson['datos'].length
							: 0
						: 0,
					10
				),
			}
		} catch (error) {
			throw error.message
		}
	},

	getOne: async (resource, options = { params: {} }) => {
		try {
			const {
				data: dataJson,
				headers,
				status,
			} = await httpClient(entrypoint, options).get(resource, { params: options.params })

			return {
				headers: headers,
				status: status,
				data: dataJson ? dataJson : null,
			}
		} catch (error) {
			throw error.message
		}
	},

	update: async (resource, options = { data: {} }) => {
		return await httpClient(entrypoint, options).patch(resource, options.data)
	},

	put: async (resource, options = { data: {} }) => {
		return await httpClient(entrypoint, options).put(resource, options.data)
	},

	create: async (resource, options = { data: {} }) => {
		return await httpClient(entrypoint, options).post(resource, options.data)
	},

	delete: async (resource, options = { data: {} }) => {
		return await httpClient(entrypoint, options).delete(resource, { data: options.data })
	},
})
