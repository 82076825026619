import { Swiper, SwiperSlide } from "swiper/react"
import { Tooltip } from "react-tooltip"
import "swiper/css"
import "swiper/css/navigation"

function FiltersPicker({ etiquetas, selectedFilter, setSelectedFilter }) {
	return (
		<section>
			<div className="container-2">
				<div className="cyg-row d-flex">
					<Swiper
						cssMode
						centerInsufficientSlides
						roundLengths
						breakpoints={{
							320: {
								slidesPerView: 3,
								slidesPerGroup: 3,
								spaceBetween: 5,
							},
							370: {
								slidesPerView: 3,
								slidesPerGroup: 3,
								spaceBetween: 5,
							},
							480: {
								slidesPerView: 4,
								slidesPerGroup: 4,
								spaceBetween: 10,
							},
							769: {
								slidesPerView: 6,
								slidesPerGroup: 6,
								spaceBetween: 10,
							},
							900: {
								slidesPerView: 10,
								slidesPerGroup: 10,
								spaceBetween: 10,
							},
							1180: {
								slidesPerView: 15,
								slidesPerGroup: 15,
								spaceBetween: 10,
							},
						}}
						className="ichef--cont-cate"
					>
						{etiquetas.map((label, index) => {
							const isSelectedLabel = selectedFilter.idEtiqueta === label.idEtiqueta
							const iconClass = `cate-0 cate-${label.idEtiqueta}`
							return (
								<SwiperSlide key={index}>
									<a
										href="/#"
										className={`ichef--cate ${iconClass} ${isSelectedLabel ? "activo" : ""}`}
										onClick={(event) => {
											event.preventDefault()
											setSelectedFilter(label)
										}}
										data-tooltip-id="picker-tooltip"
										data-tooltip-content={index}
										data-text={label.nombreEtiqueta}
									>
										<i></i>
									</a>
								</SwiperSlide>
							)
						})}
					</Swiper>
				</div>
			</div>
			<Tooltip
				id="picker-tooltip"
				render={({ activeAnchor }) => (
					<span>
						{activeAnchor?.getAttribute("data-text") || "Sin Nombre"}
					</span>
				)}
			/>
		</section>
	)
}

export default FiltersPicker
