import { useState, useEffect } from 'react'
import { useAuthProvider } from '../../providers/auth'
import { useServiceProvider } from '../../providers/service'
import FolderItem from './FolderItem'
import { useTranslation } from "react-i18next";

function FoldersSidebar() {
	const { t } = useTranslation();
	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	//Proveedor de autenticación
	const authProvider = useAuthProvider()

	//Timeout id
	const [sidebarBackgroundTimeoutId, setSidebarBackgroundTimeoutId] = useState(null)

	//Demora para el cierre del sidebar.
	const sidebarBackgroundDelay = 130000

	const initialFolderList = [
		{
			idCarpeta: "fav",
			nombre: t("favorites"),
			cssClass: 'fav',
			readOnly: true,
		},
		{
			idCarpeta: "priv",
			nombre: t("private"),
			cssClass: 'priv',
			readOnly: true,
		},
		{
			idCarpeta: "later",
			nombre: t("view_later"),
			cssClass: 'fixed',
			readOnly: true,
		}
	]

	const [folderList, setFolderList] = useState(initialFolderList)

	useEffect(() => {
		const folders = authProvider.getFolders()
		setFolderList(initialFolderList.concat(folders))
		// eslint-disable-next-line
	} , [authProvider.getFolderUpdated()]);

	const disableMenuFolder = () => {
		setFolderList(
			folderList.map((folder) => {
				return {
					...folder,
					menuActivated: false,
				}
			})
		)
	}

	return (
		<div>
			{!serviceProvider.isFilterActivated() && (
				<i
					className="btn--carpetas"
					onClick={(e) => {
						e.stopPropagation()
						e.preventDefault()
						serviceProvider.foldersToggle()
					}}
				></i>
			)}

			<div
				className={`sidebar-background ${serviceProvider.isFoldersActivated() ? 'activo' : ''}`}
				onClick={() => {
					serviceProvider.foldersOff()
					disableMenuFolder()

					if (sidebarBackgroundTimeoutId) {
						clearTimeout(sidebarBackgroundTimeoutId)
						setSidebarBackgroundTimeoutId(null)
					}
				}}
				onMouseEnter={() => {
					if (!serviceProvider.isMobileActivated()) {
						if (serviceProvider.isFoldersActivated()) {
							setSidebarBackgroundTimeoutId(
								setTimeout(() => {
									serviceProvider.foldersOff()
									disableMenuFolder()
								}, sidebarBackgroundDelay)
							)
						}
					}
				}}
				onMouseLeave={(e) => {
					if (!serviceProvider.isMobileActivated() && serviceProvider.isFoldersActivated()) {
						if (sidebarBackgroundTimeoutId) {
							clearTimeout(sidebarBackgroundTimeoutId)
							setSidebarBackgroundTimeoutId(null)
						}
					}
				}}
			></div>

			<section id="carpetas" onClick={disableMenuFolder}>
				<h5
					className="tit--nueva"
					onClick={() => {
						serviceProvider.folderFormLightBoxOn()
						serviceProvider.setFolderFormLightBoxData({})
					}}
				>
					{t("new_folder")}
				</h5>
				<div className="lista-carpetas">
					{folderList.length > 0 &&
						folderList
							.filter(itemFolder => !itemFolder.isShared && !itemFolder.isPublic)
							.map((itemFolder, index) => (
								<FolderItem
									key={index}
									itemFolder={itemFolder}
									folderList={folderList}
									setFolderList={setFolderList}
								/>
							))}
					<h4>{t("shared_with_me")}</h4>
					{folderList.length > 0 &&
						folderList
							.filter(itemFolder => itemFolder.isShared)
							.map((itemFolder, index) => (
								<FolderItem
									key={index}
									itemFolder={itemFolder}
									folderList={folderList}
									setFolderList={setFolderList}
								/>
							))}
					<h3>
					<div
							className='item-botonera' onClick={() => {
							serviceProvider.folderFormLightBoxOn()
							serviceProvider.setFolderFormLightBoxData({
								registerCode: true
							})
						}}
					>
						{t("register_by_code")}
					</div>
					</h3>
				</div>
			</section>
		</div>
	)
}

export default FoldersSidebar
